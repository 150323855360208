var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-lines */
import { useMemo } from 'react';
import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty';
import { Table } from '../commons/Table';
import { useTranslation } from 'react-i18next';
import { useAppI18n } from '../../contexts/AppI18nContext';
import { date } from '../../shared/date';
import { convertLogsData, exportLogsAction } from '../../shared/helpers';
import { Col, Row } from 'antd';
import { useAppState } from '../../contexts/AppStateContext';
import { CustomColumnHeader } from '../commons/CustomColumnHeader';
var breakWork = { wordWrap: 'break-word', wordBreak: 'break-word' };
var GridView = function (props) {
    var reloadList = props.reloadList;
    var tLogs = useTranslation('components', { keyPrefix: 'logs' }).t;
    var tCommon = useTranslation('components', { keyPrefix: 'commons' }).t;
    var _a = useAppI18n(), layout = _a.layout, language = _a.language;
    var isMobile = layout.isMobileLayoutOnPC || layout.isMobileScreenSize;
    var _b = useAppState(), listSupplier = _b.listSupplier, tags = _b.tags;
    var renderWithDevice = function () {
        if (!isMobile) {
            return [
                {
                    title: _jsx(CustomColumnHeader, { text: tLogs('list.title.group') }),
                    dataIndex: 'group',
                    width: 300,
                    render: function (_, item) { return (_jsx("div", { "data-testid": "logs-item-group-".concat(item.logId), style: breakWork, children: tLogs("listData.group.".concat(item.data.group)) })); },
                },
                {
                    title: _jsx(CustomColumnHeader, { text: tLogs('list.title.action') }),
                    dataIndex: 'action',
                    width: 300,
                    render: function (_, item) { return (_jsx("div", { "data-testid": "logs-item-action-".concat(item.logId), style: breakWork, children: tLogs("listData.action.".concat(item.action)) })); },
                },
                {
                    title: _jsx(CustomColumnHeader, { text: tLogs('list.title.details') }),
                    dataIndex: 'data',
                    width: 600,
                    render: function (_, item) { return (_jsx("div", { "data-testid": "logs-item-details-".concat(item.logId), style: breakWork, dangerouslySetInnerHTML: {
                            __html: tLogs("logsAction.".concat(exportLogsAction(item.action, item.data)), {
                                data: convertLogsData(item.action, item.data, listSupplier, tags, language),
                            }),
                        } })); },
                },
                {
                    title: _jsx(CustomColumnHeader, { text: tLogs('list.title.created') }),
                    dataIndex: 'created',
                    width: 200,
                    render: function (_, item) { return (_jsx("div", { "data-testid": "logs-item-created-".concat(item.logId), style: breakWork, children: date.format(item.createdAt, tCommon('format.YYYYMMDDHHmmss')) })); },
                },
            ];
        }
        else {
            return [
                {
                    title: tLogs('list.title.action'),
                    dataIndex: 'id',
                    width: '100%',
                    render: function (_, item) { return (_jsxs("div", { style: {
                            fontSize: '16px',
                            background: '#fff',
                            border: '1px solid rgba(0, 0, 0, 0.12)',
                            borderRadius: '4px',
                        }, children: [_jsx(Row, { align: "middle", wrap: false, style: { padding: '10px', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }, children: _jsx(Col, { span: 24, children: _jsxs("div", { "data-testid": "logs-item-group-".concat(item.logId), style: breakWork, children: [_jsxs("b", { style: { display: 'block' }, children: [tLogs('list.title.group'), ":"] }), tLogs("listData.group.".concat(item.data.group))] }) }) }), _jsx(Row, { align: "middle", wrap: false, style: { padding: '10px', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }, children: _jsx(Col, { span: 24, children: _jsxs("div", { "data-testid": "logs-item-action-".concat(item.logId), style: breakWork, children: [_jsxs("b", { style: { display: 'block' }, children: [tLogs('list.title.action'), ":"] }), tLogs("listData.action.".concat(item.action))] }) }) }), _jsx(Row, { align: "middle", wrap: false, style: { padding: '10px', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }, children: _jsx(Col, { span: 24, children: _jsxs("div", { "data-testid": "logs-item-details-".concat(item.logId), style: breakWork, children: [_jsxs("b", { style: { display: 'block' }, children: [tLogs('list.title.details'), ":"] }), _jsx("div", { dangerouslySetInnerHTML: {
                                                    __html: tLogs("logsAction.".concat(exportLogsAction(item.action, item.data)), {
                                                        data: convertLogsData(item.action, item.data, listSupplier, tags, language),
                                                    }),
                                                } })] }) }) }), _jsx(Row, { align: "middle", wrap: false, style: { padding: '10px' }, children: _jsx(Col, { span: 24, children: _jsxs("div", { "data-testid": "logs-item-created-".concat(item.logId), style: breakWork, children: [_jsxs("b", { style: { display: 'block' }, children: [tLogs('list.title.created'), ":"] }), date.format(item.createdAt, tCommon('format.YYYYMMDDHHmmss'))] }) }) })] })); },
                },
            ];
        }
    };
    var columns = useMemo(function () { return renderWithDevice(); }, [renderWithDevice, reloadList, props]);
    var dataListWithKey = useMemo(function () {
        return !_isNil(props.dataList) && props.dataList
            ? props.dataList.map(function (f) { return (__assign(__assign({}, f), { key: f.logId })); })
            : [];
    }, [props.dataList]);
    return (_jsx("div", { "data-testid": "table-contain-list", style: { width: layout.isMobileScreenSize ? 'calc(100% - 20px)' : '100%' }, children: columns !== undefined && (_jsx(Table, { locale: { emptyText: tCommon('message.emptyData') }, columns: columns, dataSource: dataListWithKey, pagination: isMobile ? (_isEmpty(dataListWithKey) ? false : props.pagination) : false, onChange: props.onChange, loading: props.isPageLoading, scroll: !isMobile ? { x: 'max-content' } : undefined, tableLayout: _isEmpty(dataListWithKey) ? 'fixed' : isMobile ? 'fixed' : 'auto' })) }));
};
export { GridView };
