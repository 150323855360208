var validateFileSize = function (fileSize, limit) {
    return fileSize / 1024 / 1024 <= limit;
};
var validateFileExtension = function (fileName, extensions) {
    var _a;
    var lastIndex = fileName === null || fileName === void 0 ? void 0 : fileName.lastIndexOf('.');
    var fileExtension = (_a = fileName === null || fileName === void 0 ? void 0 : fileName.substring(lastIndex)) === null || _a === void 0 ? void 0 : _a.toLowerCase();
    if (!fileName || lastIndex === -1 || !extensions.split(',').find(function (it) { return it === fileExtension; })) {
        return false;
    }
    return true;
};
export { validateFileSize, validateFileExtension };
