var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var DragActions;
(function (DragActions) {
    DragActions["MOVE"] = "MOVE";
    DragActions["SCALE"] = "SCALE";
    DragActions["NO_MOVEMENT"] = "NO_MOVEMENT";
})(DragActions || (DragActions = {}));
export var TextMode;
(function (TextMode) {
    TextMode["INSERT"] = "insert";
    TextMode["COMMAND"] = "command";
})(TextMode || (TextMode = {}));
export var AttachmentTypes;
(function (AttachmentTypes) {
    AttachmentTypes["IMAGE"] = "image";
    AttachmentTypes["CHECKMARK"] = "checkmark";
    AttachmentTypes["DRAWING"] = "drawing";
    AttachmentTypes["HIGHLIGHT"] = "highlight";
    AttachmentTypes["FREEHAND"] = "freehand";
    AttachmentTypes["TEXT"] = "text";
    AttachmentTypes["NUMBER"] = "number";
})(AttachmentTypes || (AttachmentTypes = {}));
export var SectionType;
(function (SectionType) {
    SectionType["CONTAINER_LOADING"] = "container_loading";
    SectionType["BUNDLE"] = "bundle";
})(SectionType || (SectionType = {}));
export var UPLOAD_FEATURE = {
    INSPECTION: 'inspection,',
    SHIPPING: 'shipping',
    DEFECT: 'defect',
};
export var SCREEN_FEATURE = __assign({}, UPLOAD_FEATURE);
export var SupplierRelationship;
(function (SupplierRelationship) {
    SupplierRelationship["INSPECTION"] = "inspection";
    SupplierRelationship["SHIPPING"] = "shipping";
})(SupplierRelationship || (SupplierRelationship = {}));
export var LanguageCommonSetting;
(function (LanguageCommonSetting) {
    LanguageCommonSetting["Japanese"] = "ja";
    LanguageCommonSetting["English"] = "en";
    LanguageCommonSetting["Spanish"] = "es";
    LanguageCommonSetting["Vietnamese"] = "vi";
})(LanguageCommonSetting || (LanguageCommonSetting = {}));
export var StatusCommonSetting;
(function (StatusCommonSetting) {
    StatusCommonSetting["Declined"] = "declined";
    StatusCommonSetting["Open"] = "open";
    StatusCommonSetting["Review"] = "review";
    StatusCommonSetting["Accepted"] = "accepted";
    StatusCommonSetting["ReReview"] = "re_review";
    StatusCommonSetting["ReviewInProcess"] = "review_in_process";
})(StatusCommonSetting || (StatusCommonSetting = {}));
export var DocumentLanguage;
(function (DocumentLanguage) {
    DocumentLanguage["Japanese"] = "ja";
    DocumentLanguage["English"] = "en";
    DocumentLanguage["Spanish"] = "es";
    DocumentLanguage["Vietnamese"] = "vi";
})(DocumentLanguage || (DocumentLanguage = {}));
export var BusinessSection;
(function (BusinessSection) {
    BusinessSection["Manu"] = "manu";
    BusinessSection["Plant"] = "plant";
    BusinessSection["Warehouse"] = "warehouse";
    BusinessSection["CADDiOnly"] = "caddi-only";
    BusinessSection["Empty"] = "";
})(BusinessSection || (BusinessSection = {}));
export var DocumentType;
(function (DocumentType) {
    DocumentType["Upload"] = "upload";
    DocumentType["QaPackage"] = "qa-package";
})(DocumentType || (DocumentType = {}));
export var ReplyPriority;
(function (ReplyPriority) {
    ReplyPriority[ReplyPriority["None"] = 0] = "None";
    ReplyPriority[ReplyPriority["FeedbackIn4H"] = 1] = "FeedbackIn4H";
    ReplyPriority[ReplyPriority["FeedbackIn2D"] = 2] = "FeedbackIn2D";
    ReplyPriority[ReplyPriority["FeedbackIn1W"] = 3] = "FeedbackIn1W";
})(ReplyPriority || (ReplyPriority = {}));
export var InspectionDeliveryType;
(function (InspectionDeliveryType) {
    InspectionDeliveryType["All"] = "All";
    InspectionDeliveryType["Nic_Dd"] = "Nic_Dd";
    InspectionDeliveryType["Others"] = "Others";
})(InspectionDeliveryType || (InspectionDeliveryType = {}));
export var DeliveryDestinationCategory;
(function (DeliveryDestinationCategory) {
    DeliveryDestinationCategory["CaddiSite"] = "CaddiSite";
    DeliveryDestinationCategory["Customer"] = "Customer";
    DeliveryDestinationCategory["SupplyPartner"] = "SupplyPartner";
    DeliveryDestinationCategory["Others"] = "Others";
})(DeliveryDestinationCategory || (DeliveryDestinationCategory = {}));
export var ArrivalLocationIdDD = 'lctn-01H1R8VA8NP1KN46YTTHPHCWTW';
export var IsInspectionByWarehouse;
(function (IsInspectionByWarehouse) {
    IsInspectionByWarehouse[IsInspectionByWarehouse["True"] = 1] = "True";
    IsInspectionByWarehouse[IsInspectionByWarehouse["False"] = 0] = "False";
})(IsInspectionByWarehouse || (IsInspectionByWarehouse = {}));
export var NotificationAccessType;
(function (NotificationAccessType) {
    NotificationAccessType["Public"] = "public";
    NotificationAccessType["Private"] = "private";
})(NotificationAccessType || (NotificationAccessType = {}));
export var NotificationResourceType;
(function (NotificationResourceType) {
    NotificationResourceType["Document"] = "document";
    NotificationResourceType["Inspection"] = "inspection";
    NotificationResourceType["Shipping"] = "shipping";
})(NotificationResourceType || (NotificationResourceType = {}));
