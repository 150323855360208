import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Col, Result, Row, Space } from 'antd';
import _capitalize from 'lodash/capitalize';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';
import { ROUTE_HOME } from '../../shared/urls';
import { getEnv } from '../../shared/helpers';
import { useApp } from '../../contexts/AppContext';
var ServiceUnavailable = function () {
    var t = useTranslation('components', { keyPrefix: 'commons' }).t;
    var isReady = useApp().isReady;
    return ({ isReady: isReady } && (_jsx(Result, { status: 500, title: t('serviceUnavailable.title'), subTitle: _jsx(Row, { gutter: 16, justify: "center", align: "middle", children: _jsx(Col, { span: 8, children: t("serviceUnavailable.msg".concat(_capitalize(getEnv()), "Env")) }) }), extra: _jsx(_Fragment, { children: _jsx(Space, { direction: "vertical", size: "middle", style: { display: 'flex' }, children: _jsx(Row, { justify: "center", align: "middle", children: _jsx(Link, { to: generatePath(ROUTE_HOME), children: _jsx(Button, { type: "primary", children: t('buttons.backToHome') }) }) }) }) }), style: { height: '100vh' } })));
};
export { ServiceUnavailable };
