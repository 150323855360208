var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, createContext, useContext, useEffect, useCallback } from 'react';
import { InspectionDeliveryType, } from '../shared/types';
import { ORDER, ORDER_BY_INSPECTION, PAGINATION_DEFAULT_PAGE, PAGINATION_DEFAULT_SIZE, USER_ACCOUNT_ROLES, } from '../shared/constants';
import { useFetchAllSupplierHooks } from '../hooks/Supplier/useFetchAllSupplierHooks';
import { useUserAccountDetailHooks } from '../hooks/UserAccount/useUserAccountDetailHooks';
import { useFetchDefectSummaryHooks } from '../hooks/Defect/useFetchDefectSummaryHooks';
import { useApp } from './AppContext';
import { ADMIN_ROUTES, NOT_AUTHORIZED_ROUTES } from '../shared/urls';
import _includes from 'lodash/includes';
import { date } from '../shared/date';
import { useFetchTagListHooks } from '../hooks/Tag';
export var useInspectionInitAdvancedSearchFilter = function (user) {
    return {
        deadline: {
            from: date.dayjs().subtract(1, 'months').tz().startOf('day').toISOString(),
            to: date.dayjs().add(1, 'months').tz().endOf('day').toISOString(),
        },
        drawingNumber: undefined,
        placingOrderNumber: undefined,
        deliveryType: (user === null || user === void 0 ? void 0 : user.role) === USER_ACCOUNT_ROLES.SUPPLIER
            ? InspectionDeliveryType.Nic_Dd
            : InspectionDeliveryType.All,
    };
};
export var initialAppState = {
    filterInspection: __assign({ supplierIds: [], customerNames: [], status: undefined, keyword: undefined, order: [ORDER.DESC], orderBy: [ORDER_BY_INSPECTION.CREATED_AT], pagination: {
            page: PAGINATION_DEFAULT_PAGE,
            size: PAGINATION_DEFAULT_SIZE,
            total: 0,
        } }, useInspectionInitAdvancedSearchFilter()),
    setFilterInspection: function () { return ({}); },
    filterShipping: {
        supplierIds: [],
        status: '',
        keyword: '',
        pagination: {
            page: PAGINATION_DEFAULT_PAGE,
            size: PAGINATION_DEFAULT_SIZE,
            total: 0,
        },
    },
    setFilterShipping: function () { return ({}); },
    listSupplier: [],
    userAccount: null,
    drawingInspectionHighlight: '',
    setDrawingInspectionHighlight: function () { return ''; },
    tags: [],
    refreshTags: function () { return ({}); },
    defectSummary: { numberOfOpen: 0 },
    refreshDefectSummary: function () { return ({}); },
    refreshSuppliers: function () { return ({}); },
    defectSearchConditions: {
        supplyPartnerId: undefined,
        drawingNumber: undefined,
        transactionId: undefined,
        customerName: undefined,
        purchaseOrderName: undefined,
        productName: undefined,
        fromDate: undefined,
        toDate: undefined,
    },
    setDefectSearchConditions: function () { return ({}); },
};
var AppStateContext = createContext(initialAppState);
export var useAppState = function () {
    return useContext(AppStateContext);
};
export var AppStateProvider = function (props) {
    var _a = useApp(), isReady = _a.isReady, currentRoute = _a.currentRoute, user = _a.user, isAdminSite = _a.isAdminSite;
    var _b = useState(__assign(__assign({}, initialAppState.filterInspection), useInspectionInitAdvancedSearchFilter(user))), filterInspection = _b[0], setFilterInspection = _b[1];
    var _c = useState(initialAppState.filterShipping), filterShipping = _c[0], setFilterShipping = _c[1];
    var _d = useState(initialAppState.defectSearchConditions), defectSearchConditions = _d[0], setDefectSearchConditions = _d[1];
    var _e = useState(initialAppState.listSupplier), listSupplier = _e[0], setListSupplier = _e[1];
    var _f = useState(initialAppState.userAccount), userAccount = _f[0], setUserAccount = _f[1];
    var _g = useState(initialAppState.drawingInspectionHighlight), drawingInspectionHighlight = _g[0], setDrawingInspectionHighlight = _g[1];
    var listSupplierHandler = useFetchAllSupplierHooks().listSupplierHandler;
    var listUserAccountDetailHandler = useUserAccountDetailHooks().listUserAccountDetailHandler;
    var getDefectSummaryHandler = useFetchDefectSummaryHooks().getDefectSummaryHandler;
    var listTagHandler = useFetchTagListHooks().listTagHandler;
    var _h = useState([]), tags = _h[0], setTags = _h[1];
    var _j = useState({ numberOfOpen: 0 }), defectSummary = _j[0], setDefectSummary = _j[1];
    useEffect(function () {
        // Reset inspection filter condition when change route to admin
        setFilterInspection(__assign(__assign({}, initialAppState.filterInspection), useInspectionInitAdvancedSearchFilter(user)));
        setFilterShipping(initialAppState.filterShipping);
        setListSupplier(initialAppState.listSupplier);
        setDrawingInspectionHighlight(initialAppState.drawingInspectionHighlight);
    }, [isAdminSite]);
    var loadSuppliers = function () { return __awaiter(void 0, void 0, void 0, function () {
        var fromAdminSite;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    fromAdminSite = ADMIN_ROUTES.includes(currentRoute || '');
                    return [4 /*yield*/, listSupplierHandler({
                            query: { isAdmin: fromAdminSite },
                            stopUnauthorizedRedirectPage: true,
                        })
                            .then(function (body) {
                            if (!body)
                                return;
                            body.sort(function (a, b) { return a.supplierName.localeCompare(b.supplierName); });
                            setListSupplier(body);
                        })
                            .catch(function () { return setListSupplier([]); })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (!isReady || _includes(NOT_AUTHORIZED_ROUTES, currentRoute))
            return;
        if (!listSupplierHandler)
            return;
        loadSuppliers();
    }, [isReady, currentRoute, listSupplierHandler]);
    useEffect(function () {
        if (!listUserAccountDetailHandler || (user === null || user === void 0 ? void 0 : user.role) !== USER_ACCOUNT_ROLES.CONTRACTOR)
            return;
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, listUserAccountDetailHandler().then(function (body) {
                            if (!body)
                                return;
                            setUserAccount(body);
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); })();
    }, [listUserAccountDetailHandler, user === null || user === void 0 ? void 0 : user.role]);
    var loadTags = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var fromAdminSite;
        return __generator(this, function (_a) {
            if (!isReady || _includes(NOT_AUTHORIZED_ROUTES, currentRoute))
                return [2 /*return*/];
            if (!listTagHandler)
                return [2 /*return*/];
            fromAdminSite = ADMIN_ROUTES.includes(currentRoute || '');
            listTagHandler({ isAdmin: fromAdminSite, size: 1000 }).then(function (result) {
                if (!result)
                    return;
                setTags(result.data);
            });
            return [2 /*return*/];
        });
    }); }, [listTagHandler]);
    useEffect(function () {
        loadTags();
    }, [isReady, currentRoute, listTagHandler]);
    var loadDefectSummary = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var fromAdminSite;
        return __generator(this, function (_a) {
            if (!isReady || _includes(NOT_AUTHORIZED_ROUTES, currentRoute))
                return [2 /*return*/];
            if (!getDefectSummaryHandler)
                return [2 /*return*/];
            fromAdminSite = ADMIN_ROUTES.includes(currentRoute || '');
            if (fromAdminSite)
                return [2 /*return*/];
            getDefectSummaryHandler().then(function (result) {
                if (!result)
                    return;
                setDefectSummary(result);
            });
            return [2 /*return*/];
        });
    }); }, [getDefectSummaryHandler]);
    useEffect(function () {
        loadDefectSummary();
    }, [isReady, currentRoute, getDefectSummaryHandler]);
    return (_jsx(AppStateContext.Provider, { value: {
            filterInspection: filterInspection,
            setFilterInspection: setFilterInspection,
            filterShipping: filterShipping,
            setFilterShipping: setFilterShipping,
            listSupplier: listSupplier,
            userAccount: userAccount,
            drawingInspectionHighlight: drawingInspectionHighlight,
            setDrawingInspectionHighlight: setDrawingInspectionHighlight,
            tags: tags,
            refreshTags: function () { return loadTags(); },
            defectSummary: defectSummary,
            refreshDefectSummary: function () { return loadDefectSummary(); },
            refreshSuppliers: function () { return loadSuppliers(); },
            defectSearchConditions: defectSearchConditions,
            setDefectSearchConditions: setDefectSearchConditions,
        }, children: props.children }, "".concat(isAdminSite)));
};
