import * as pako from 'pako';
export var compressNotEncoded = function (rawText) {
    var u8arr = pako.deflate(rawText);
    return window.btoa(Array(u8arr.length)
        .fill('')
        .map(function (_, i) { return String.fromCharCode(u8arr[i]); })
        .join(''));
};
export var decompressIfNeed = function (value) {
    try {
        var base64Encoded = decodeURIComponent(value);
        var binaryString = window.atob(base64Encoded);
        var bytes = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return pako.inflate(bytes.buffer, { to: 'string' });
    }
    catch (err) {
        return value;
    }
};
