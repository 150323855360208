var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y;
/* eslint-disable max-lines */
import _pick from 'lodash/pick';
export var MOBILE_MAX_WIDTH = 768;
export var IPAD_MAX_WIDTH = 1180;
export var MOBILE_NORMAL_WIDTH = 414;
export var INSPECTION_IMAGE_FILE_SIZE_MB_MAX = 20;
export var IMAGE_QUANLITY = 0.8;
export var IMAGE_MAX_COMPRESS_SIZE_MB = 10;
export var INSPECTION_PDF_FILE_SIZE_MB_MAX = 40;
export var UPLOAD_IMAGE_FILE_SIZE_MB_MAX = INSPECTION_IMAGE_FILE_SIZE_MB_MAX;
export var UPLOAD_PDF_FILE_SIZE_MB_MAX = INSPECTION_PDF_FILE_SIZE_MB_MAX;
export var UPLOAD_EXCEL_FILE_SIZE_MB_MAX = 100;
export var UPLOAD_WORD_FILE_SIZE_MB_MAX = 100;
export var UPLOAD_CSV_FILE_SIZE_MB_MAX = 100;
export var UPLOAD_ZIP_FILE_SIZE_MB_MAX = 100;
export var COMMENT_COUNT_MAX = 1024;
export var COMMENT_BLOCKQUOTE_CHAR = 50;
export var COMMENT_EDITABLE_WITHIN_SECOND = 30 * 60;
export var PAGINATION_DEFAULT_PAGE = 1;
export var PAGINATION_DEFAULT_SIZE = 10;
export var PAGINATION_CHANGE_SIZE_ITEM = [10, 20, 50, 100];
export var PHOTO_COUNT_MAX = 20;
export var SHIPPING_PHOTO_COUNT_MAX = 20;
export var INSPECTION_OTHER_CHECKLIST_ITEM_MAX = 100;
export var ALLOW_REVERT_STATUS_IN_SECOND = 600;
export var FILTER_BY_SUPPLIERS_MAX_NUMBER = 3;
export var FILTER_BY_CUSTOMER_NAMES_MAX_NUMBER = 3;
export var AUTOMATIC_REVERT_STATUS_IN_SECOND = 300;
export var DEFECT_ATTACHMENT_TYPE = {
    PRODUCT: 'product',
};
export var INSPECTION_PHOTO_TYPE = {
    DRAWING: 'drawing',
    CHECKLIST: 'checklist',
    PRODUCT: 'product',
    PACKING: 'packing',
    OFF_DRAWING: 'off_drawing',
    OTHERS: 'others',
};
export var HIDE_INSPECTION_PHOTO_TYPE = _pick(INSPECTION_PHOTO_TYPE, ['PACKING', 'OFF_DRAWING']);
export var CAMERA_FACING_MODE = {
    USER: 'user',
    ENVIRONMENT: 'environment',
};
export var SCANNER_QR_CODE = {
    TIMEOUT_DIALOG: 5000, // 5 seconds
    CAMERA_REGION_WIDTH: 350,
    CAMERA_REGION_HEIGHT: 350,
    CAMERA_REGION_MOBILE_WIDTH: 250,
    CAMERA_REGION_MOBILE_HEIGHT: 250,
    SPEED_SCAN: 100,
};
export var HTTP_STATUS = {
    INSPECTION_RESULT_CONFLICT: 409,
    INSPECTION_RESULT_HAS_DATA: 422,
    UNPROCESSABLE_CONTENT: 422,
    PERMISSION_DENIED: 403,
    HAS_RELATED_DATA: 422,
    INSPECTION_CHECK_ITEM_CONFLICT: 409,
    INSPECTION_CHECK_ITEM_HAS_LOCKED: 422,
    UNDER_MAINTENANCE: 307,
    INSPECTOR_COMPANY_HAS_BEEN_ASSIGNED: 409,
    RESOURCE_CONFLICT: 409,
    SERVICE_UNAVAILABLE: 503,
};
export var HISTORY_ACTION = {
    INSPECTION_RESULT_CREATED: 'inspection_result_created',
    PHOTO_ADDED: 'photo_added',
    PHOTO_TYPE_CHANGED: 'photo_type_changed',
    PHOTO_DELETED: 'photo_deleted',
    SKU_ADDED: 'sku_added',
    SKU_UPDATED: 'sku_updated',
    SKU_DELETED: 'sku_deleted',
    INSPECTION_RESULT_STATUS_CHANGED: 'inspection_result_status_changed',
};
export var LOGS_GROUP = {
    SUPPLIER: 'supplier',
    USER_ACCOUNT: 'user_account',
    INSPECTION: 'inspection',
    SHIPPING: 'shipping',
    DOCUMENT: 'document',
    INSPECTOR_COMPANY: 'inspector_company',
    QBO_IMPORT_CSV: 'qbo_importing',
    STANDARD_TERMS_SENTENCES: 'standard_terms_sentences',
    TAG: 'tag',
};
export var LOGS_ACTION = {
    STYLE_CHANGED: 'style_changed',
    UPDATE_SUPPLIER_FOR_SP_ACCOUNT: 'update_supplier_for_sp_account',
    INSPECTION_STATUS_CHANGED: 'inspection_status_changed',
    UPDATE_ROLE_FOR_USER_ACCOUNT: 'update_role_for_user_account',
    SHIPPING_STATUS_CHANGE: 'shipping_status_changed',
    SHIPPING_SUPPLIER_CHANGED: 'shipping_supplier_changed',
    INSPECTION_RESULT_SUPPLIER_CHANGED: 'inspection_result_supplier_changed',
    DOCUMENT_ADDED: 'document_added',
    DOCUMENT_STATUS_CHANGED: 'document_status_changed',
    DOCUMENT_SHARED_SUPPLIER_IDS_CHANGED: 'document_shared_supplier_ids_changed',
    DOCUMENT_ACCESS_TYPE_CHANGED: 'document_access_type_changed',
    DOCUMENT_CHANGED: 'document_changed',
    DOCUMENT_LANGUAGE_CHANGED: 'document_language_changed',
    DOCUMENT_BUSINESS_SECTION_CHANGED: 'document_business_section_changed',
    DOCUMENT_CATEGORY_CHANGED: 'document_category_changed',
    UPDATE_SLACK_CHANNEL_FOR_SUPPLIER: 'update_slack_channel_for_supplier',
    ASSIGN_SUPPLIER_FOR_CONTRACTOR: 'assign_supplier_for_contractor',
    UPDATE_ENABLED_KLEIN_ORDER_FOR_SUPPLIER: 'update_enabled_klein_order_for_supplier',
    UPDATE_CADDI_CHANNEL_FOR_SUPPLIER: 'update_caddi_channel_for_supplier',
    UPDATE_AUTO_ACCEPTANCE_FOR_SUPPLIER: 'update_auto_acceptance_for_supplier',
    UPDATE_INSPECTOR_COMPANY: 'update_inspector_company',
    ADD_INSPECTOR_COMPANY: 'add_inspector_company',
    DOCUMENT_ACCESSED: 'document_accessed',
    QBO_NEW_IMPORTING: 'qbo_new_importing',
    QBO_REIMPORTING: 'qbo_reimporting',
    STANDARD_TERMS_SENTENCES_ADDED: 'standard_terms_sentences_added',
    STANDARD_TERMS_SENTENCES_STATUS_CHANGED: 'standard_terms_sentences_status_changed',
    STANDARD_TERMS_SENTENCES_CHANGED: 'standard_terms_sentences_changed',
    STANDARD_TERMS_SENTENCES_METADATA_CHANGED: 'standard_terms_sentences_metadata_changed',
    TAG_ADDED: 'tag_added',
    TAG_UPDATED: 'tag_updated',
    TAG_STATUS_CHANGED: 'tag_status_changed',
};
export var NOTIFICATION_EVENT = {
    DOCUMENT_ADDED: 'document_added',
    DOCUMENT_STATUS_CHANGED: 'document_status_changed',
    DOCUMENT_ACCESS_TYPE_CHANGED: 'document_access_type_changed',
    DOCUMENT_VERSION_CHANGED: 'document_version_changed',
    DOCUMENT_REMOVED_ACCESS_FILE: 'document_removed_access_file',
    DOCUMENT_GRANTED_ACCESS_FILE: 'document_granted_access_file',
    DOCUMENT_CATEGORY_CHANGED: 'document_category_changed',
};
export var LOGS_ACTION_MSG = {
    LINK_TO_SUPPLIER: 'link_to_supplier',
    RE_LINK_TO_SUPPLIER: 're_link_to_supplier',
    UNLINK_SUPPLIER: 'unlink_supplier',
};
export var ORDER = {
    ASC: 'ASC',
    DESC: 'DESC',
};
export var SORT_ORDER_SUPPORT_ANT_DESIGN = {
    ASC: 'ascend',
    DESC: 'descend',
};
export var ORDER_BY = {
    ID: 'id',
    CREATED_AT: 'createdAt',
    UPDATED_AT: 'updatedAt',
    CATEGORY_ID: 'categoryId',
    SUPPLIER_NAME: 'supplierName',
    EMAIL: 'email',
};
export var ORDER_BY_LOGS = {
    LOG_ID: 'logId',
    CREATED_AT: 'createdAt',
};
export var ORDER_BY_INSPECTION = {
    DEADLINE_AT: 'deadlineAt',
    PO_NUMBER: 'placingOrderNumber',
    CREATED_AT: 'createdAt',
    UPDATE_AT: 'updatedAt',
};
export var ORDER_BY_DOCUMENT = {
    DOCUMENT_ID: 'documentId',
    TITLE: 'title',
    STATUS: 'status',
    PLACING_ORDER_DATE: 'placingOrderDate',
    CREATED_AT: 'createdAt',
    UPDATED_AT: 'updatedAt',
};
export var ORDER_BY_NOTIFICATION = {
    ID: 'id',
    CREATED_AT: 'createdAt',
    UPDATED_AT: 'updatedAt',
    NOTIFICATION_ID: 'notificationId',
};
export var LOCAL_STORAGE_KEYWORD = {
    DONT_ASK_AGAIN_DUPLICATE: 'isAllowDontAskAgainForDuplicate',
    DONT_ASK_AGAIN_DUPLICATE_YES: 'yes',
    DONT_ASK_AGAIN_DUPLICATE_NO: 'no',
    INSPECTION_PIN_COLUMN: 'inspectionPin',
    DOCUMENT_FILTER: 'documentFilter',
    DOCUMENT_ADMIN_FILTER: 'documentAdminFilter',
};
export var REPLY_STATUS = {
    NOT_REPLIED: 'not_replied',
    ALREADY_REPLIED: 'already_replied',
};
export var FILE_STATUS = {
    AVAILABLE: 'available',
    DELETED: 'deleted',
};
export var INQUIRY_TYPE = {
    INQUIRY: 'inquiries',
    COMMENT: 'comments',
};
export var COMMENT_STATUS = {
    NEW: 'new',
    EDITED: 'edited',
    DELETED: 'deleted',
    PROCESSING: 'processing',
};
export var DOCUMENT_STATUS = {
    AVAILABLE: 'available',
    DELETED: 'deleted',
};
export var ROW_STATUS = {
    AVAILABLE: 'available',
    DELETED: 'deleted',
};
export var IMPORT_CSV_STATUS = {
    FINISHED: 'finished',
    IMPORTING: 'importing',
};
export var INSPECTOR_COMPANY_STATUS = {
    AVAILABLE: 'available',
    DELETED: 'deleted',
};
export var DEFECT_STATUS = {
    OPEN: 'open',
    IN_REVIEW: 'in_review',
    DECLINED: 'declined',
    RE_REVIEW: 're_review',
    COMPLETED: 'completed',
};
export var PAGE_GROUP_ID_REGEX = /^(WA-[\d-]+)$/i;
export var PAGE_ID_REGEX = /^(RT-[\d-]+)$/i;
export var HERODOTUS_ID_REGEX = /^(WA-[\d-]+)\/(RT-[\d-]+)$/i;
export var INSPECTION_RESULT_LIST = {
    MONTH_AGO: 6,
    PAGINATION_SIZE: {
        MOBILE: 20,
        PC: 100,
    },
};
export var SHIPPING_LIST = {
    MONTH_AGO: 6,
};
export var INSPECTION_STYLE = {
    PAPER: 'paper',
    ON_SCREEN: 'on-screen',
};
export var TAGTYPE = {
    DOCUMENT_CATEGORY: 'document-category',
    INQUIRY_CATEGORY: 'inquiry-category',
};
export var ENABLED_KLEIN_ORDER_VALUES = {
    ON: 1,
    OFF: 0,
};
export var USER_SYSTEM_EMAIL = 'system@caddi.jp';
export var USER_ACCOUNT_ROLES = {
    CADDI: 'caddi',
    CONTRACTOR: 'contractor',
    SUPPLIER: 'supplier',
    ADMIN: 'admin',
    SYSTEM: 'system',
    UNAUTHORIZED: 'unauthorized',
};
export var USABLE_USER_ACCOUNT_ROLES = _pick(USER_ACCOUNT_ROLES, 'CADDI', 'CONTRACTOR', 'SUPPLIER');
export var INSPECTION_STATUS;
(function (INSPECTION_STATUS) {
    INSPECTION_STATUS["Open"] = "open";
    INSPECTION_STATUS["Review"] = "review";
    INSPECTION_STATUS["Accepted"] = "accepted";
    INSPECTION_STATUS["Declined"] = "declined";
    INSPECTION_STATUS["Deleted"] = "deleted";
    INSPECTION_STATUS["ReReview"] = "re_review";
    INSPECTION_STATUS["ReviewInProcess"] = "review_in_process";
})(INSPECTION_STATUS || (INSPECTION_STATUS = {}));
export var INSPECTION_STATUS_ALLOW_FILTER = (_a = {
        all: 'all'
    },
    _a[INSPECTION_STATUS.Open] = INSPECTION_STATUS.Open,
    _a[INSPECTION_STATUS.Review] = INSPECTION_STATUS.Review,
    _a[INSPECTION_STATUS.ReviewInProcess] = INSPECTION_STATUS.ReviewInProcess,
    _a[INSPECTION_STATUS.Declined] = INSPECTION_STATUS.Declined,
    _a[INSPECTION_STATUS.ReReview] = INSPECTION_STATUS.ReReview,
    _a[INSPECTION_STATUS.Accepted] = INSPECTION_STATUS.Accepted,
    _a);
export var PAPER_TRANSITION_STATUS = (_b = {},
    _b[INSPECTION_STATUS.Review] = [INSPECTION_STATUS.ReviewInProcess],
    _b[INSPECTION_STATUS.ReviewInProcess] = [INSPECTION_STATUS.Accepted, INSPECTION_STATUS.Declined],
    _b[INSPECTION_STATUS.Declined] = [INSPECTION_STATUS.ReReview],
    _b[INSPECTION_STATUS.ReReview] = [INSPECTION_STATUS.ReviewInProcess],
    _b);
export var ON_SCREEN_TRANSITION_STATUS = (_c = {},
    _c[INSPECTION_STATUS.Review] = [INSPECTION_STATUS.ReviewInProcess],
    _c[INSPECTION_STATUS.ReviewInProcess] = [INSPECTION_STATUS.Accepted, INSPECTION_STATUS.Declined],
    _c[INSPECTION_STATUS.Declined] = [INSPECTION_STATUS.ReReview],
    _c[INSPECTION_STATUS.ReReview] = [INSPECTION_STATUS.ReviewInProcess],
    _c);
export var INSPECTION_TRANSITION_STATUS = (_d = {},
    _d[USER_ACCOUNT_ROLES.ADMIN] = (_e = {},
        _e[INSPECTION_STYLE.PAPER] = __assign(__assign({}, PAPER_TRANSITION_STATUS), (_f = {}, _f[INSPECTION_STATUS.Accepted] = [INSPECTION_STATUS.Review], _f[INSPECTION_STATUS.Declined] = [INSPECTION_STATUS.ReviewInProcess, INSPECTION_STATUS.ReReview], _f)),
        _e[INSPECTION_STYLE.ON_SCREEN] = __assign(__assign({}, ON_SCREEN_TRANSITION_STATUS), (_g = {}, _g[INSPECTION_STATUS.Accepted] = [INSPECTION_STATUS.Review], _g[INSPECTION_STATUS.Declined] = [INSPECTION_STATUS.ReviewInProcess, INSPECTION_STATUS.ReReview], _g)),
        _e),
    _d[USER_ACCOUNT_ROLES.CADDI] = (_h = {},
        _h[INSPECTION_STYLE.PAPER] = __assign(__assign({}, PAPER_TRANSITION_STATUS), (_j = {}, _j[INSPECTION_STATUS.Accepted] = [INSPECTION_STATUS.ReviewInProcess], _j[INSPECTION_STATUS.Declined] = [INSPECTION_STATUS.ReviewInProcess, INSPECTION_STATUS.ReReview], _j)),
        _h[INSPECTION_STYLE.ON_SCREEN] = __assign(__assign({}, ON_SCREEN_TRANSITION_STATUS), (_k = {}, _k[INSPECTION_STATUS.Accepted] = [INSPECTION_STATUS.ReviewInProcess], _k[INSPECTION_STATUS.Declined] = [INSPECTION_STATUS.ReviewInProcess, INSPECTION_STATUS.ReReview], _k)),
        _h),
    _d[USER_ACCOUNT_ROLES.CONTRACTOR] = (_l = {},
        _l[INSPECTION_STYLE.PAPER] = __assign(__assign({}, PAPER_TRANSITION_STATUS), (_m = {}, _m[INSPECTION_STATUS.Accepted] = [INSPECTION_STATUS.ReviewInProcess], _m[INSPECTION_STATUS.Declined] = [INSPECTION_STATUS.ReviewInProcess, INSPECTION_STATUS.ReReview], _m)),
        _l[INSPECTION_STYLE.ON_SCREEN] = __assign(__assign({}, ON_SCREEN_TRANSITION_STATUS), (_o = {}, _o[INSPECTION_STATUS.Accepted] = [INSPECTION_STATUS.ReviewInProcess], _o[INSPECTION_STATUS.Declined] = [INSPECTION_STATUS.ReviewInProcess, INSPECTION_STATUS.ReReview], _o)),
        _l),
    _d[USER_ACCOUNT_ROLES.SUPPLIER] = (_p = {},
        // SP can only change status from DECLINED -> Re-REVIEW manually
        _p[INSPECTION_STYLE.PAPER] = (_q = {}, _q[INSPECTION_STATUS.Declined] = [INSPECTION_STATUS.ReReview], _q),
        _p[INSPECTION_STYLE.ON_SCREEN] = (_r = {}, _r[INSPECTION_STATUS.Declined] = [INSPECTION_STATUS.ReReview], _r),
        _p),
    _d[USER_ACCOUNT_ROLES.SYSTEM] = (_s = {},
        _s[INSPECTION_STYLE.PAPER] = {},
        _s[INSPECTION_STYLE.ON_SCREEN] = {},
        _s),
    _d[USER_ACCOUNT_ROLES.UNAUTHORIZED] = (_t = {},
        _t[INSPECTION_STYLE.PAPER] = {},
        _t[INSPECTION_STYLE.ON_SCREEN] = {},
        _t),
    _d);
export var ON_SCREEN_STEP_NUMBER = {
    SEARCH_DRAWING: 1,
    CHECK_DRAWING: 2,
    CHECKLIST: 3,
};
export var EXTRACT_TYPE_FOR_SCAN = {
    FULL: 'full',
    PAGE_GROUP_ID: 'page_group_id',
};
export var REGEX_AUTH_GOOGLE_SOCIAL_CONNECTION = /google-oauth2/i;
export var REGEX_EMAIL = /([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/i;
export var REGEX_METION_EMAIL = /@[a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+/im;
export var AUTH_ROLES = __assign(__assign({}, USER_ACCOUNT_ROLES), { ADMIN: 'admin', CADDI: 'caddi', UNAUTHORIZED: 'unauthorized' });
export var ABILITY_ACTION = {
    ACCESS: 'access',
    READ: 'read',
    EDIT: 'edit',
    DELETE: 'delete',
};
export var ABILITY_SUBJECT = {
    SKU: 'SKU',
    STATUS_TRANSACTION: 'StatusTransaction',
    SP_NAME: 'SP_NAME',
    SHIPPING_STATUS_TRANSACTION: 'ShippingStatusTransaction',
    INSPECTION: 'inpsection',
    SHIPPING: 'shipping',
    INSPECTOR_COMPANY: 'inspectorCompany',
    EXPORT_DATA: 'exportData',
    STANDARD_TERMS_SENTENCES_KEYWORD: 'standardTermsSentencesKeyword',
    STANDARD_TERMS_SENTENCES_DETAIL: 'standardTermsSentencesDetail',
    BUSINESS_SECTION: 'BusinessSection',
    DOCUMENT_COMMENT: 'DocumentComment',
    MANAGE_DEFECT: 'ManageDefect',
    TAG: 'Tag',
    ALL: 'all',
};
export var REGEX_SKU_CODE = /^(\d{8})-([a-z]{5})-(\w{9,})$/i;
export var REGEX_DEFAULT = /^(WA-[\d-]+)\/(RT-[\d-]+)|(WA-[\d-]+)|(RT-[\d-]+)|(\d{8})-([a-z]{5})-(\w{9,})$/i;
export var SCANNER_TYPE = {
    QR_CODE: 'qr',
    SKU_CODE: 'sku',
    ALL: 'all',
};
export var DRAWING_CURSOR = {
    CROSSHAIR: 'crosshair',
    WAIT: 'wait',
    DEFAULT: 'default',
};
export var AUTO_SAVE_DRAWING_TIME = 60000; // 1 min
export var FETCH_NEW_NOTIFICATION_TIME = 300000; // 5 min
export var FETCH_RESULT_IMPORT_CSV_TIME = 30000; // 30 seconds
export var PARAMS_API = {
    UPDATE_ACCOUNT_SUPPLIER_ID: 'link',
    RESET_ACCOUNT_APP_METADATA: 'unlink',
    UPDATE_ROLE_USER_ID: 'role',
    CONTRACTOR_ASSIGN_SUPPLIER: 'assigned_supplier',
};
export var REGEX_INPUT_TEXT_DRAWING = /[^0-9+=\-.,:]/g;
export var TEXTBOX_DRAWING_LIMIT = 30;
export var DRAWING_MAX_SCALE = 3;
export var DRAWING_MIN_SCALE = 0.25;
export var DRAWING_SCALE_STEP = 0.5;
export var DRAWING_TEXT_COLOR = '#ad0903';
export var DRAWING_FRONT_FAMILY = 'Times-Roman';
export var DRAWING_HIGHLIGHT_THICKNESS = 10;
export var DRAWING_FREEHAND_THICKNESS = 3;
export var DRAWING_HIGHLIGHT_SAVE_TIME = 300;
export var DRAWING_FREEHAND_SAVE_TIME = 300;
export var NAMESPACE_AUTH0 = 'https://inspection.caddi.jp';
export var AUTH0_METADATA = {
    SUPPLIER_ID: 'supplier_id',
    SUPPLIER_NAME: 'supplier_name',
    ROLE: 'role',
};
export var DISABLED_FEATURES = {
    SP_MANAGEMENT: 'sp_management',
    ON_SCREEN_INTEGRATION: 'on_screen_integration',
    INSPECTION_UI_V2: 'inspection_ui_v2',
    QA_PACKAGE_INTEGRATION: 'qa_package_integration',
};
export var SHIPPING_STATUS = {
    OPEN: 'open',
    REVIEW: 'review',
    DECLINED: 'declined',
    ACCEPTED: 'accepted',
    SHIPPED: 'shipped',
};
export var SHIPPING_SECTION_AND_SEQUENCE = {
    CONTAINER_LOADING: 'container_loading',
    BUNDLE_1: 'bundle_1',
    BUNDLE_2: 'bundle_2',
    BUNDLE_3: 'bundle_3',
    BUNDLE_4: 'bundle_4',
    BUNDLE_5: 'bundle_5',
    BUNDLE_6: 'bundle_6',
    BUNDLE_7: 'bundle_7',
    BUNDLE_8: 'bundle_8',
    BUNDLE_9: 'bundle_9',
    BUNDLE_10: 'bundle_10',
};
export var SHIPPING_PAIR_STATUS = {
    OPEN: {
        REVIEW: SHIPPING_STATUS.REVIEW,
    },
    REVIEW: {
        OPEN: SHIPPING_STATUS.OPEN,
        DECLINED: SHIPPING_STATUS.DECLINED,
        ACCEPTED: SHIPPING_STATUS.ACCEPTED,
    },
    DECLINED: {
        REVIEW: SHIPPING_STATUS.REVIEW,
    },
};
export var SHIPPING_TRANSITION_STATUS = {
    ADMIN: __assign(__assign({}, SHIPPING_PAIR_STATUS), { 
        // Admin can change ACCEPTED -> REVIEW manually
        ACCEPTED: {
            REVIEW: SHIPPING_STATUS.REVIEW,
        } }),
    CADDI: __assign({}, SHIPPING_PAIR_STATUS),
    CONTRACTOR: __assign({}, SHIPPING_PAIR_STATUS),
    SP: __assign(__assign({}, _pick(SHIPPING_PAIR_STATUS, ['OPEN', 'DECLINED'])), { REVIEW: {
            OPEN: SHIPPING_STATUS.OPEN,
        } }),
};
export var SHIPPING_HISTORY_ACTION = {
    PHOTO_ADDED: 'photo_added',
    PHOTO_TYPE_CHANGED: 'photo_type_changed',
    PHOTO_DELETED: 'photo_deleted',
    SHIPPING_CREATED: 'shipping_created',
    SHIPPING_STATUS_CHANGED: 'shipping_status_changed',
};
export var SHIPPING_MIN_BUNDLE = 11;
export var SHIPPING_MAX_BUNDLE = 89;
export var DEFECT_TRANSITION_STATUS = (_u = {},
    _u[USER_ACCOUNT_ROLES.ADMIN] = (_v = {},
        _v[DEFECT_STATUS.IN_REVIEW] = [DEFECT_STATUS.DECLINED, DEFECT_STATUS.COMPLETED],
        _v[DEFECT_STATUS.DECLINED] = [DEFECT_STATUS.RE_REVIEW],
        _v[DEFECT_STATUS.COMPLETED] = [DEFECT_STATUS.RE_REVIEW],
        _v[DEFECT_STATUS.RE_REVIEW] = [DEFECT_STATUS.DECLINED, DEFECT_STATUS.COMPLETED],
        _v),
    _u[USER_ACCOUNT_ROLES.CADDI] = (_w = {},
        _w[DEFECT_STATUS.IN_REVIEW] = [DEFECT_STATUS.DECLINED, DEFECT_STATUS.COMPLETED],
        _w[DEFECT_STATUS.DECLINED] = [DEFECT_STATUS.RE_REVIEW],
        _w[DEFECT_STATUS.COMPLETED] = [DEFECT_STATUS.RE_REVIEW],
        _w[DEFECT_STATUS.RE_REVIEW] = [DEFECT_STATUS.DECLINED, DEFECT_STATUS.COMPLETED],
        _w),
    _u[USER_ACCOUNT_ROLES.CONTRACTOR] = (_x = {},
        _x[DEFECT_STATUS.IN_REVIEW] = [DEFECT_STATUS.DECLINED, DEFECT_STATUS.COMPLETED],
        _x[DEFECT_STATUS.DECLINED] = [DEFECT_STATUS.RE_REVIEW],
        _x[DEFECT_STATUS.COMPLETED] = [DEFECT_STATUS.RE_REVIEW],
        _x[DEFECT_STATUS.RE_REVIEW] = [DEFECT_STATUS.DECLINED, DEFECT_STATUS.COMPLETED],
        _x),
    _u[USER_ACCOUNT_ROLES.SUPPLIER] = (_y = {},
        // SP can only change status from DECLINED -> Re-REVIEW manually
        _y[DEFECT_STATUS.DECLINED] = [DEFECT_STATUS.RE_REVIEW],
        _y),
    _u);
export var FILTER_DATA = {
    ALL: 'all',
};
export var COLOR;
(function (COLOR) {
    COLOR["RED"] = "red";
    COLOR["ORANGE"] = "orange";
    COLOR["YELLOW"] = "yellow";
    COLOR["OLIVE"] = "olive";
    COLOR["GREEN"] = "green";
    COLOR["TEAL"] = "teal";
    COLOR["BLUE"] = "blue";
    COLOR["VIOLOET"] = "violet";
    COLOR["PURPLE"] = "purple";
    COLOR["BROWN"] = "brown";
    COLOR["GREY"] = "grey";
    COLOR["BLACK"] = "black";
})(COLOR || (COLOR = {}));
export var PIN_COLUMN;
(function (PIN_COLUMN) {
    PIN_COLUMN["LEFT"] = "left";
    PIN_COLUMN["RIGHT"] = "right";
})(PIN_COLUMN || (PIN_COLUMN = {}));
export var DOCUMENT_ACCESS = {
    PUBLIC: 'public',
    PRIVATE: 'private',
};
export var DOCUMENT_COMMENT_THREAD = {
    INTERNAL: 'internal',
    SUPPLIER: 'supplier',
};
export var FIELDS_IMPORT_CSV_QBO = ['category', 'sku'];
export var SUPPLIER_NOTIFIER_EMAIL_CODE = 'send-to-sp-email';
