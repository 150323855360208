var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { List, Popover, Skeleton, Badge } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import colors from '../../shared/colors';
import { useAppI18n } from '../../contexts/AppI18nContext';
import NotificationItemComponent from './NotificationItem';
import { useFetchListNotification } from '../../api/';
import { FETCH_NEW_NOTIFICATION_TIME, ORDER, ORDER_BY_NOTIFICATION, PAGINATION_DEFAULT_PAGE, PAGINATION_DEFAULT_SIZE, } from '../../shared/constants';
import { useNotificationSummaryHooks } from '../../hooks/Notification/fetchNotificationSummaryHooks';
import { useUpdateNotificationHooks } from '../../hooks/Notification/useUpdateNotificationHooks';
import { useTranslation } from 'react-i18next';
var NotificationIconWrapper = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  height: 100%;\n  cursor: pointer;\n  margin-right: 20px;\n"], ["\n  display: flex;\n  align-items: center;\n  height: 100%;\n  cursor: pointer;\n  margin-right: 20px;\n"])));
var ScrollableDiv = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 300px;\n  width: 300px;\n  overflow: auto;\n"], ["\n  height: 300px;\n  width: 300px;\n  overflow: auto;\n"])));
var BoxNotification = function () {
    var layout = useAppI18n().layout;
    var isMobile = layout.isMobileLayoutOnPC || layout.isMobileScreenSize || layout.isMobileLayout;
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    var _c = useState(0), currPage = _c[0], setCurrPage = _c[1];
    var _d = useState([]), data = _d[0], setData = _d[1];
    var _e = useState(0), notificationSummary = _e[0], setNotificationSummary = _e[1];
    var _f = useState(0), totalNotification = _f[0], setTotalNotification = _f[1];
    var fetchListNotification = useFetchListNotification();
    var notificationSummaryHookHandler = useNotificationSummaryHooks().notificationSummaryHookHandler;
    var updateNotificationHookHandler = useUpdateNotificationHooks().updateNotificationHookHandler;
    var t = useTranslation('components', { keyPrefix: 'notification' }).t;
    var getNotiSummary = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!notificationSummaryHookHandler)
                        return [2 /*return*/];
                    return [4 /*yield*/, notificationSummaryHookHandler()];
                case 1:
                    res = _b.sent();
                    setNotificationSummary((_a = res === null || res === void 0 ? void 0 : res.unRead) !== null && _a !== void 0 ? _a : 0);
                    return [2 /*return*/];
            }
        });
    }); }, [notificationSummaryHookHandler]);
    var handleOpenChange = function (newOpen) {
        setOpen(newOpen);
        if (!newOpen) {
            setData([]);
            setCurrPage(0);
        }
    };
    useEffect(function () {
        void getNotiSummary();
        var timer = setInterval(getNotiSummary, FETCH_NEW_NOTIFICATION_TIME);
        return function () { return clearInterval(timer); };
    }, [getNotiSummary]);
    var fetchListData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var paginatedParams, res, responseBody, unReadList;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!fetchListNotification || loading || currPage === 0)
                        return [2 /*return*/];
                    setLoading(true);
                    paginatedParams = {
                        page: currPage,
                        size: PAGINATION_DEFAULT_SIZE,
                        orderBy: ORDER_BY_NOTIFICATION.NOTIFICATION_ID,
                        order: ORDER.DESC,
                    };
                    return [4 /*yield*/, fetchListNotification({ query: paginatedParams })];
                case 1:
                    res = _a.sent();
                    if (!res || !res.isSuccess)
                        return [2 /*return*/, setLoading(false)];
                    responseBody = res.body.data;
                    unReadList = responseBody.filter(function (i) { return i.isRead === false; });
                    if (!(unReadList.length > 0)) return [3 /*break*/, 3];
                    return [4 /*yield*/, markNotificationAsRead(unReadList.map(function (i) { return i.notificationId; }))];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3:
                    setData(function (currentData) { return __spreadArray(__spreadArray([], currentData, true), responseBody, true); });
                    setTotalNotification(res.body.pagination.total);
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (currPage <= 0)
            return;
        void fetchListData();
    }, [currPage]);
    useEffect(function () {
        if (!open)
            return;
        setCurrPage(PAGINATION_DEFAULT_PAGE);
    }, [fetchListNotification, open]);
    var markNotificationAsRead = useCallback(function (notificationIds) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!updateNotificationHookHandler)
                        return [2 /*return*/];
                    return [4 /*yield*/, updateNotificationHookHandler({
                            params: [],
                            body: { notificationIds: notificationIds },
                        })
                            .then(function () {
                            setData(function (values) {
                                return values.map(function (value) {
                                    return notificationIds.includes(value.notificationId) ? __assign(__assign({}, value), { isRead: true }) : value;
                                });
                            });
                            void getNotiSummary();
                            return;
                        })
                            .catch(function () {
                            return;
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [updateNotificationHookHandler]);
    var renderContent = function () {
        return (_jsx(ScrollableDiv, { id: "scrollableDiv", children: open ? (_jsx(InfiniteScroll, { dataLength: data.length, next: function () { return setCurrPage(currPage + 1); }, hasMore: data.length < totalNotification, loader: loading && _jsx(Skeleton, { avatar: true, paragraph: { rows: 1 }, active: true }), scrollableTarget: "scrollableDiv", children: _jsx(List, { loading: loading, itemLayout: "vertical", dataSource: data, locale: { emptyText: t('message.noData') }, renderItem: function (item) { return _jsx(NotificationItemComponent, { item: item }); } }) })) : (_jsx(Skeleton, {})) }));
    };
    return (_jsx(NotificationIconWrapper, { children: _jsx(Badge, { count: notificationSummary, children: _jsx(Popover, { placement: !isMobile ? 'bottomRight' : 'bottom', content: renderContent(), title: t('header'), trigger: "click", open: open, onOpenChange: handleOpenChange, children: _jsx(BellOutlined, { style: { fontSize: 24, color: colors.white } }) }) }) }));
};
export { BoxNotification };
var templateObject_1, templateObject_2;
