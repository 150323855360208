import commonEn from './en/common.json';
import commonJa from './ja/common.json';
import commonVi from './vi/common.json';
import commonEs from './es/common.json';
import componentsEn from './en/components';
import componentsJa from './ja/components';
import componentsVi from './vi/components';
import componentsEs from './es/components';
import sharedEn from './en/shared';
import sharedJa from './ja/shared';
import sharedVi from './vi/shared';
import sharedEs from './es/shared';
export var resources = {
    ja: {
        common: commonJa,
        components: componentsJa,
        shared: sharedJa,
    },
    en: {
        common: commonEn,
        components: componentsEn,
        shared: sharedEn,
    },
    vi: {
        common: commonVi,
        components: componentsVi,
        shared: sharedVi,
    },
    es: {
        common: commonEs,
        components: componentsEs,
        shared: sharedEs,
    },
};
