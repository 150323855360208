import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import _get from 'lodash/get';
import { Button, Result } from 'antd';
import { generatePath, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
var PageNotFound = function (props) {
    var t = useTranslation('components', {
        keyPrefix: 'commons.pageNotFound',
    }).t;
    var title = _get(props, 'title', t('title'));
    var message = _get(props, 'subTitle', t('msg'));
    var backText = _get(props, 'backText', t('backText'));
    var backUrl = _get(props, 'backUrl', t('backUrl'));
    return (_jsx(Result, { status: 404, title: title, subTitle: message, extra: _jsx(_Fragment, { children: _jsx(Link, { to: generatePath(backUrl), style: { textDecoration: 'underline' }, children: _jsx(Button, { type: "primary", style: { borderColor: 'none' }, children: backText }) }) }), style: { height: '100vh' } }));
};
export { PageNotFound };
