var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable max-lines */
/* eslint max-lines: [error, 224] */
import styled from 'styled-components';
import { Header } from '../commons';
import { useTranslation } from 'react-i18next';
import '../../assets/Pagination.css';
import { ButtonImportCSV } from './ButtonImportCSV';
import { CustomPageHeader } from '../commons/CustomPageHeader';
import { useAppI18n } from '../../contexts/AppI18nContext';
import { GridView } from './GridView';
import { PaginationCustom } from '../commons/PaginationCustom';
import _pickBy from 'lodash/pickBy';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FETCH_RESULT_IMPORT_CSV_TIME, IMPORT_CSV_STATUS, ORDER, ORDER_BY, PAGINATION_DEFAULT_PAGE, PAGINATION_DEFAULT_SIZE, } from '../../shared/constants';
import { useListImportCSVHooks } from '../../hooks/ImportCSV';
import { PlusOutlined } from '@ant-design/icons';
import { FormUpload } from './FormUpload';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: ", ";\n  padding-top: 20px;\n  .ant-table-pagination {\n    display: flex;\n  }\n"], ["\n  height: ", ";\n  padding-top: 20px;\n  .ant-table-pagination {\n    display: flex;\n  }\n"])), function (props) { return (props.$isMobile ? 'unset' : 'calc(100vh - 100px)'); });
var AdminImportCSV = function () {
    var t = useTranslation('components', {
        keyPrefix: 'importCSV',
    }).t;
    var tCommon = useTranslation('components', { keyPrefix: 'commons' }).t;
    var layout = useAppI18n().layout;
    var isMobile = layout.isMobileLayoutOnPC || layout.isMobileScreenSize;
    var defaultBody = {
        query: {
            page: PAGINATION_DEFAULT_PAGE,
            size: PAGINATION_DEFAULT_SIZE,
            isAdmin: true,
            orderBy: ORDER_BY.UPDATED_AT,
            order: ORDER.DESC,
        },
    };
    var _a = useState(__assign({}, defaultBody)), queryList = _a[0], setQueryList = _a[1];
    var queryRef = useRef(__assign({}, defaultBody));
    var setQueryToCall = function (params) {
        if (params.page)
            queryRef.current.query.page = params.page;
        if (params.size)
            queryRef.current.query.size = params.size;
        setQueryList(function (state) { return (__assign(__assign({}, state), queryRef.current)); });
    };
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    var handleTableChange = useCallback(function (pagination) {
        setQueryToCall({
            page: pagination.current,
            size: pagination.pageSize,
        });
    }, []);
    var _c = useState({
        data: [],
        pagination: { page: PAGINATION_DEFAULT_PAGE, size: PAGINATION_DEFAULT_SIZE, total: 0 },
    }), importCSVData = _c[0], setImportCSVData = _c[1];
    var listImportCSVHookHandler = useListImportCSVHooks().listImportCSVHookHandler;
    var getImportCSVList = function () {
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            var body;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, listImportCSVHookHandler({
                            query: __assign({}, _pickBy(__assign({}, queryRef.current.query), function (value) { return value !== undefined; })),
                            onProgressing: function () {
                                setLoading(true);
                            },
                            onSuccessed: function () {
                                setLoading(false);
                            },
                        })];
                    case 1:
                        body = _a.sent();
                        if (!body)
                            return [2 /*return*/];
                        setImportCSVData(body);
                        return [2 /*return*/];
                }
            });
        }); })();
    };
    useEffect(function () {
        getImportCSVList();
    }, [queryList, listImportCSVHookHandler]);
    useEffect(function () {
        var index = importCSVData.data.findIndex(function (e) { return e.status === IMPORT_CSV_STATUS.IMPORTING; });
        if (index === -1) {
            return;
        }
        else {
            var timer_1 = setTimeout(getImportCSVList, FETCH_RESULT_IMPORT_CSV_TIME);
            return function () { return clearTimeout(timer_1); };
        }
    }, [importCSVData]);
    // Get/Set page option from customize pagination
    var getSizeChange = function (size) {
        setPaginationOption(PAGINATION_DEFAULT_PAGE, size);
    };
    var getNextPage = function (page) {
        var _a;
        setPaginationOption(page, (_a = importCSVData.pagination) === null || _a === void 0 ? void 0 : _a.size);
    };
    var getPreviousPage = function (page) {
        var _a;
        setPaginationOption(page, (_a = importCSVData.pagination) === null || _a === void 0 ? void 0 : _a.size);
    };
    var setPaginationOption = function (page, size) {
        setQueryToCall({
            page: page,
            size: size,
        });
    };
    // End
    // Open / handle form
    var _d = useState(false), isOpenForm = _d[0], setIsOpenForm = _d[1];
    //
    return (_jsxs(_Fragment, { children: [_jsx(Header, { children: _jsx(CustomPageHeader, { text: t('header'), adminText: t('adminHeader'), isAdminSide: true, children: _jsx(ButtonImportCSV, { label: tCommon('buttons.importCSV'), icon: _jsx(PlusOutlined, {}), onClickHandle: function () { return setIsOpenForm(true); } }) }) }), _jsx(Container, { "$isMobile": isMobile, children: _jsxs("div", { "data-testid": "table_wrapper", children: [_jsx(GridView, { editable: true, dataList: importCSVData.data, pagination: {
                                position: ['bottomCenter'],
                                current: importCSVData.pagination.page || PAGINATION_DEFAULT_PAGE,
                                pageSize: importCSVData.pagination.size || PAGINATION_DEFAULT_SIZE,
                                total: importCSVData.pagination.total || 0,
                                showSizeChanger: false,
                            }, onChange: handleTableChange, loading: loading, onSuccessed: function () {
                                getImportCSVList();
                            } }), !isMobile && (_jsx(PaginationCustom, { pagination: importCSVData.pagination, sizeChange: getSizeChange, nextPage: getNextPage, previousPage: getPreviousPage }))] }) }), _jsx(FormUpload, { isOpenForm: isOpenForm, onProgressing: function () { }, onSuccessed: function (doNothing) {
                    setIsOpenForm(false);
                    if (!doNothing) {
                        getImportCSVList();
                    }
                } })] }));
};
export default AdminImportCSV;
var templateObject_1;
