import { useState } from 'react';
import { useAppI18n } from '../../contexts/AppI18nContext';
var userInterfaceOptions = [
    {
        value: 'PC/Tablet',
        label: 'PCまたはタブレット/PC or Tablet/Máy bàn hoặc máy tính bảng',
    },
    {
        value: 'Mobile',
        label: 'モバイル/Mobile/Điện thoại',
    },
];
export var useSelectUserInterface = function () {
    var layout = useAppI18n().layout;
    var _a = useState(layout.userInterface), userInterface = _a[0], setUserInterface = _a[1];
    var userInterfaceChangeHandler = function (value) {
        setUserInterface(value);
    };
    return {
        currentUserInterface: layout.userInterface,
        userInterface: userInterface,
        userInterfaceChangeHandler: userInterfaceChangeHandler,
        userInterfaceOptions: userInterfaceOptions,
    };
};
