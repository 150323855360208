import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useAuth0 } from '@auth0/auth0-react';
import { ROUTE_HOME, ROUTE_LOGOUT } from '../../../shared/urls';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
var Logout = function () {
    var _a = useAuth0(), isLoading = _a.isLoading, isAuthenticated = _a.isAuthenticated, logout = _a.logout;
    var clientId = process.env.AUTH0_CLIENT_ID;
    var navigate = useNavigate();
    // https://auth0.com/docs/libraries/auth0-react
    useEffect(function () {
        if (isLoading)
            return;
        if (!isAuthenticated) {
            // If already logged out, redirect to home
            navigate(ROUTE_HOME, { replace: true });
        }
        else {
            // If has not logged out yet, force logout and redirect to home
            // https://github.com/auth0/auth0-react/blob/master/MIGRATION_GUIDE.md
            logout({
                clientId: clientId,
                logoutParams: {
                    returnTo: window.location.origin + ROUTE_LOGOUT,
                },
            });
        }
    }, [isLoading, isAuthenticated]);
    return _jsx(_Fragment, {});
};
export { Logout };
