var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/* eslint-disable max-lines */
import _toLower from 'lodash/toLower';
import _toUpper from 'lodash/toUpper';
import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _split from 'lodash/split';
import _includes from 'lodash/includes';
import _find from 'lodash/find';
import _omit from 'lodash/omit';
import _sumBy from 'lodash/sumBy';
import _toNumber from 'lodash/toNumber';
import { INSPECTION_STATUS, INSPECTION_STYLE, PAGE_GROUP_ID_REGEX, LOGS_ACTION, LOGS_ACTION_MSG, INSPECTION_STATUS_ALLOW_FILTER, REGEX_AUTH_GOOGLE_SOCIAL_CONNECTION, NOTIFICATION_EVENT, ALLOW_REVERT_STATUS_IN_SECOND, } from './constants';
import { AttachmentTypes, DeliveryDestinationCategory, InspectionDeliveryType, ArrivalLocationIdDD, IsInspectionByWarehouse, } from './types';
import md5 from 'md5';
import _filter from 'lodash/filter';
import pdfIcon from '../components/icons/pdf-icon.svg';
import { DOCUMENT_TYPES } from './fileUtils';
import excelIcon from '../components/icons/excel-icon.svg';
import wordIcon from '../components/icons/word-icon.svg';
import zipIcon from '../components/icons/zip-icon.svg';
import dayjs from 'dayjs';
import QueryString from 'qs';
export var isPageGroupId = function (code) {
    return PAGE_GROUP_ID_REGEX.test(code);
};
export var getFileType = function (fileName) {
    fileName = _toLower(fileName);
    return fileName.split('.').pop();
};
export var isPdf = function (fileName) {
    if (getFileType(fileName) === 'pdf')
        return true;
    return false;
};
export var getDocumentFileIcon = function (fileName) {
    var fileType = getFileType(fileName);
    switch (fileType) {
        case DOCUMENT_TYPES.XLS:
        case DOCUMENT_TYPES.XLSX:
            return excelIcon;
        case DOCUMENT_TYPES.DOC:
        case DOCUMENT_TYPES.DOCX:
            return wordIcon;
        case DOCUMENT_TYPES.ZIP:
            return zipIcon;
        default:
            return pdfIcon;
    }
};
export var checkImageExist = function (url, withRetry, callback) {
    if (withRetry === void 0) { withRetry = 0; }
    if (!url) {
        callback(false);
    }
    var http = new XMLHttpRequest();
    var exists = false;
    try {
        http.open('HEAD', url, false);
        http.send();
        exists = http.status === 200;
    }
    catch (err) {
        exists = false;
    }
    finally {
        if (exists) {
            callback(true);
        }
        else {
            if (withRetry) {
                new Promise(function () { return setTimeout(function () { return checkImageExist(url, withRetry - 1, callback); }, 1000); });
            }
            else {
                callback(false);
            }
        }
    }
};
export var isCaddiRoleByUserId = function (userId) {
    return REGEX_AUTH_GOOGLE_SOCIAL_CONNECTION.test(userId);
};
export var replaceStatusWhiteSpace = function (status) {
    return _toUpper(status.replace(' ', '_'));
};
export var getStatusKey = function (status, object) {
    if (object === void 0) { object = INSPECTION_STATUS; }
    return _get(objectFlip(object), _toLower(status), status);
};
export var objectFlip = function (obj) {
    return Object.assign.apply(Object, __spreadArray([{}], Object.entries(obj).map(function (_a) {
        var _b;
        var a = _a[0], b = _a[1];
        return (_b = {}, _b[b] = a, _b);
    }), false));
};
export var isOnScreenStyle = function (obj) {
    return _get(obj, 'style') === INSPECTION_STYLE.ON_SCREEN;
};
export var getPhotoIdFromFileName = function (fileName) {
    var splittedName = fileName.split('.');
    splittedName.pop();
    return splittedName.join('.');
};
export var validateRegex = function (text, regExp) {
    var re = new RegExp(regExp, 'i');
    return re.test(text);
};
export function ggID() {
    return new Date().getTime();
}
export var getMovePosition = function (x, y, dragX, dragY, width, height, pageWidth, pageHeight) {
    var newPositionTop = y + dragY;
    var newPositionLeft = x + dragX;
    var newPositionRight = newPositionLeft + width;
    var newPositionBottom = newPositionTop + height;
    var top = newPositionTop < 0 ? 0 : newPositionBottom > pageHeight ? pageHeight - height : newPositionTop;
    var left = newPositionLeft < 0 ? 0 : newPositionRight > pageWidth ? pageWidth - width : newPositionLeft;
    return {
        top: top,
        left: left,
    };
};
export var normalize = function (value) { return parseFloat((value / 255).toFixed(1)); };
export var getSupplierInfo = function (arrayObject, supplierId) {
    if (_isNil(arrayObject) || _isNil(supplierId))
        return undefined;
    return _find(arrayObject, { supplierId: supplierId });
};
export var getListSuppliers = function (arrayObject, supplierIds) {
    if (_isNil(arrayObject) || _isNil(supplierIds))
        return undefined;
    var supplierItems = _filter(arrayObject, function (o) { return _includes(supplierIds, o.supplierId); });
    return !_isEmpty(supplierItems) ? supplierItems : undefined;
};
export var checkDisableFeatures = function (value) {
    var _a;
    var disableFeatures = (_a = _split(process.env.DISABLED_FEATURES, ',')) !== null && _a !== void 0 ? _a : [];
    return _includes(disableFeatures, value);
};
export var getCaddiDocumentSlackChannel = function () {
    var _a;
    return (_a = process.env.CADDI_DOCUMENT_SLACK_CHANNEL) !== null && _a !== void 0 ? _a : '';
};
export var hashUserLockedBy = function (email, userAgent) {
    if (email)
        return "".concat(email, "-").concat(md5(userAgent !== null && userAgent !== void 0 ? userAgent : '').slice(0, 12));
    return '';
};
export var getDefectTranslation = function (columnName, language, item) {
    var defaultValue = _get(item, columnName);
    if (_isEmpty(item.metadata) || item.metadata.translation === undefined)
        return defaultValue;
    var transItem = item.metadata.translation.find(function (i) { return i.columnName === columnName && i.language === language; });
    if (transItem) {
        return transItem.value;
    }
    return defaultValue;
};
export var exportLogsAction = function (action, data) {
    var actionConvert = action;
    switch (action) {
        case LOGS_ACTION.UPDATE_SUPPLIER_FOR_SP_ACCOUNT:
            if (_isNil(data.before.supplierId) || _isEmpty(data.before.supplierId))
                actionConvert = LOGS_ACTION_MSG.LINK_TO_SUPPLIER;
            else if (_isNil(data.after.supplierId) || _isEmpty(data.after.supplierId))
                actionConvert = LOGS_ACTION_MSG.UNLINK_SUPPLIER;
            else
                actionConvert = LOGS_ACTION_MSG.RE_LINK_TO_SUPPLIER;
            break;
    }
    return actionConvert;
};
export var convertLogsData = function (action, data, listSupplier, listTag, language) {
    var _a, _b, _c, _d, _e, _f;
    switch (action) {
        case LOGS_ACTION.ASSIGN_SUPPLIER_FOR_CONTRACTOR:
            if (!_isNil(data.before.assignedSupplier) || !_isEmpty(data.before.assignedSupplier)) {
                data.before.assignedSupplierName =
                    (_a = extractSupplierNameFromList(listSupplier, data.before.assignedSupplier).join(', ')) !== null && _a !== void 0 ? _a : '';
            }
            else {
                data.before.assignedSupplierName = '';
            }
            if (!_isNil(data.after.assignedSupplier) || !_isEmpty(data.after.assignedSupplier)) {
                data.after.assignedSupplierName =
                    (_b = extractSupplierNameFromList(listSupplier, data.after.assignedSupplier).join(', ')) !== null && _b !== void 0 ? _b : '';
            }
            else {
                data.after.assignedSupplierName = '';
            }
            break;
        case LOGS_ACTION.DOCUMENT_SHARED_SUPPLIER_IDS_CHANGED:
            if (!_isNil(data.before.sharedSupplierIds) || !_isEmpty(data.before.sharedSupplierIds)) {
                data.before.assignedSupplierName =
                    (_c = extractSupplierNameFromList(listSupplier, data.before.sharedSupplierIds).join(', ')) !== null && _c !== void 0 ? _c : '';
            }
            else {
                data.before.assignedSupplierName = '';
            }
            if (!_isNil(data.after.sharedSupplierIds) || !_isEmpty(data.after.sharedSupplierIds)) {
                data.after.assignedSupplierName =
                    (_d = extractSupplierNameFromList(listSupplier, data.after.sharedSupplierIds).join(', ')) !== null && _d !== void 0 ? _d : '';
            }
            else {
                data.after.assignedSupplierName = '';
            }
            break;
        case LOGS_ACTION.DOCUMENT_CATEGORY_CHANGED:
            _set(data.before, 'metadata.categoryLabel', (_e = extractTagFromList(listTag, _get(data.before, 'metadata.category', []), language).join(', ')) !== null && _e !== void 0 ? _e : '');
            _set(data.after, 'metadata.categoryLabel', (_f = extractTagFromList(listTag, _get(data.after, 'metadata.category', []), language).join(', ')) !== null && _f !== void 0 ? _f : '');
            break;
    }
    return data;
};
export var convertMetaDataBeforeSave = function (allPageAttachments) {
    return allPageAttachments.map(function (attachments) {
        return attachments
            .filter(function (attachment) {
            if (attachment.type === AttachmentTypes.TEXT) {
                return !!attachment.text;
            }
            return true;
        })
            .map(function (attachment) {
            return attachment.type === AttachmentTypes.CHECKMARK
                ? _omit(attachment, ['file', 'img', 'index'])
                : attachment;
        });
    });
};
export var isDDNICTransctionType = function (inspection) {
    var deliveryDestinationCategory = _get(inspection, 'transactionMetadata.deliveryDestinationCategory');
    var isInspectionByWarehouse = _get(inspection, 'transactionMetadata.isInspectionByWarehouse');
    var arrivalLocationId = _get(inspection, 'transactionMetadata.arrivalLocationId');
    return (deliveryDestinationCategory === DeliveryDestinationCategory.Customer ||
        isInspectionByWarehouse === IsInspectionByWarehouse.False ||
        arrivalLocationId === ArrivalLocationIdDD);
};
export var getInspectionDeliveryType = function (inspection) {
    if (isDDNICTransctionType(inspection)) {
        return InspectionDeliveryType.Nic_Dd;
    }
    else {
        return InspectionDeliveryType.Others;
    }
};
export var countStatus = function (value, data) {
    if (value === INSPECTION_STATUS_ALLOW_FILTER.all)
        return _sumBy(data, function (o) {
            return _toNumber(o.count);
        });
    var findSummary = _find(data, { status: value });
    if (_isNil(findSummary))
        return 0;
    return findSummary.count;
};
// https://www.w3.org/TR/trace-context/#traceparent-header
export var generateTraceParent = function () {
    var version = '00';
    var traceId = generateRandomHex(16);
    var id = generateRandomHex(8);
    var flags = '01';
    return "".concat(version, "-").concat(traceId, "-").concat(id, "-").concat(flags);
};
export var generateRandomHex = function (size) {
    return __spreadArray([], Array(size), true).map(function () { return Math.floor(Math.random() * 16).toString(16); }).join('');
};
export var isSiteAdmin = function (pathName) {
    return new RegExp(/(^\/admin)/, 'i').test(pathName);
};
export var getAuthenticateType = function (connectionType) {
    var getConnection = _split(connectionType, '|');
    return getConnection.length > 1 ? getConnection[0] : 'undefined';
};
export var isEmptyId = function (id) {
    return ['0', '', 0, null, undefined].includes(id);
};
export var ignoreWindowWarning = function () {
    window.addEventListener('error', function (e) {
        if (e.type === 'error') {
            var resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div');
            var resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
            if (resizeObserverErr) {
                resizeObserverErr.setAttribute('style', 'display: none');
            }
            if (resizeObserverErrDiv) {
                resizeObserverErrDiv.setAttribute('style', 'display: none');
            }
        }
    });
};
export var parseJSON = function (value) {
    try {
        var parsedValue = JSON.parse(value);
        return parsedValue;
    }
    catch (e) {
        return null;
    }
};
export var getFirstFieldSort = function (sorter, fieldDefault) {
    var cloneSorter = getFirstElementFromArray(sorter);
    var orderBy = fieldDefault;
    if (cloneSorter.field !== undefined) {
        orderBy = getFirstElementFromArray(cloneSorter.field);
    }
    return orderBy;
};
export var getFirstElementFromArray = function (records) {
    return records === undefined ? undefined : Array.isArray(records) ? records[0] : records;
};
export var extractSupplierNameFromList = function (listSupplier, supplierIds) {
    if (_isEmpty(supplierIds))
        return [];
    return listSupplier
        .filter(function (item) {
        return (Array.isArray(supplierIds) ? supplierIds : [supplierIds]).includes(item.supplierId);
    })
        .map(function (o) { return o.supplierName; });
};
export var extractSlackChannelOfSPFromList = function (listSupplier, supplierId) {
    if (_isEmpty(supplierId))
        return '';
    var suppliers = listSupplier.filter(function (i) { return i.supplierId === supplierId; });
    return suppliers.length === 0 ? '' : suppliers[0].slackChannel;
};
export var extractInquiryChannelOfSPFromList = function (listSupplier, supplierId) {
    if (_isEmpty(supplierId))
        return '';
    var suppliers = listSupplier.filter(function (i) { return i.supplierId === supplierId; });
    return suppliers.length === 0 ? '' : suppliers[0].inquiryChannel;
};
export var extractTagFromList = function (listTags, tagIds, language) {
    if (_isEmpty(tagIds))
        return [];
    return listTags
        .filter(function (tag) { return (Array.isArray(tagIds) ? tagIds : [tagIds]).includes(tag.tagId); })
        .map(function (tag) { var _a; return (_a = tag.translate[language]) !== null && _a !== void 0 ? _a : ''; });
};
export var convertNotificationData = function (item, listSupplier, listTag, language) {
    var _a, _b, _c;
    var targetSupplierNames = undefined;
    switch (item.metadata.event) {
        case NOTIFICATION_EVENT.DOCUMENT_GRANTED_ACCESS_FILE:
        case NOTIFICATION_EVENT.DOCUMENT_REMOVED_ACCESS_FILE:
            if (!_isNil(item.targetSupplierIds) && !_isEmpty(item.targetSupplierIds)) {
                targetSupplierNames =
                    (_a = extractSupplierNameFromList(listSupplier, item.targetSupplierIds).join(', ')) !== null && _a !== void 0 ? _a : '';
            }
            else {
                targetSupplierNames = '';
            }
            break;
        case NOTIFICATION_EVENT.DOCUMENT_CATEGORY_CHANGED:
            _set(item.metadata.data, 'before.metadata.categoryLabel', (_b = extractTagFromList(listTag, _get(item.metadata.data, 'before.metadata.category', []), language).join(', ')) !== null && _b !== void 0 ? _b : '');
            _set(item.metadata.data, 'after.metadata.categoryLabel', (_c = extractTagFromList(listTag, _get(item.metadata.data, 'after.metadata.category', []), language).join(', ')) !== null && _c !== void 0 ? _c : '');
            break;
        default:
            break;
    }
    return __assign(__assign({}, item.metadata.data), { targetSupplierNames: targetSupplierNames });
};
export var getInspectionStatusColor = function (status) {
    var color = '#ED6C02';
    switch (status) {
        case INSPECTION_STATUS.Review:
            color = '#08979C';
            break;
        case INSPECTION_STATUS.ReReview:
            color = '#79544C';
            break;
        case INSPECTION_STATUS.ReviewInProcess:
            color = '#DA0063';
            break;
        case INSPECTION_STATUS.Open:
            color = '#ED6C02';
            break;
        case INSPECTION_STATUS.Accepted:
            color = '#0000FF';
            break;
        case INSPECTION_STATUS.Declined:
            color = '#000080';
            break;
    }
    return color;
};
export var getDrawingStatus = function (inspectionDropDowns) {
    var status = INSPECTION_STATUS.Open;
    if (inspectionDropDowns.length === 1)
        status = inspectionDropDowns[0].status;
    else if (inspectionDropDowns.length > 1) {
        // for case drawing have more than one page
        var statusArr = inspectionDropDowns.map(function (item) { return item.status; });
        var isContainDeclined = _includes(statusArr, INSPECTION_STATUS.Declined);
        var isContainReviewInProcess = _includes(statusArr, INSPECTION_STATUS.ReviewInProcess);
        var isContainReReview = _includes(statusArr, INSPECTION_STATUS.ReReview);
        var isContainAccepted = _includes(statusArr, INSPECTION_STATUS.Accepted);
        var isContainReview = _includes(statusArr, INSPECTION_STATUS.Review);
        if (isContainDeclined)
            status = INSPECTION_STATUS.Declined;
        else if (isContainAccepted)
            status = INSPECTION_STATUS.Accepted;
        else if (isContainReviewInProcess) {
            status = INSPECTION_STATUS.ReviewInProcess;
        }
        else if (isContainReReview) {
            status = INSPECTION_STATUS.ReReview;
        }
        else if (isContainReview) {
            status = INSPECTION_STATUS.Review;
        }
    }
    return status;
};
export var checkAllowChangeStatusToReviewInProcess = function (startAllowRevertStatusAt, isAdmin) {
    if (isAdmin)
        return true;
    // check allow change status to Review
    if (startAllowRevertStatusAt) {
        var timeCheck = dayjs().subtract(ALLOW_REVERT_STATUS_IN_SECOND, 'seconds').tz();
        var startAllowRevertStatusTime = dayjs(startAllowRevertStatusAt).tz();
        if (timeCheck <= startAllowRevertStatusTime)
            return true;
    }
    return false;
};
export var getRemainTimeToRevertStatus = function (startAllowRevertStatusAt) {
    if (startAllowRevertStatusAt === '')
        return 0;
    var currentTimeStamp = dayjs().tz().unix();
    var endTime = dayjs(startAllowRevertStatusAt)
        .add(ALLOW_REVERT_STATUS_IN_SECOND, 'seconds')
        .tz()
        .unix();
    var result = endTime - currentTimeStamp;
    return result > 0 ? result : 0;
};
export var removeEmptySearchParams = function (object, isIncludeEmpty) {
    if (isIncludeEmpty === void 0) { isIncludeEmpty = false; }
    var removedObject = Object.entries(object).reduce(function (a, _a) {
        var k = _a[0], v = _a[1];
        return v === undefined || v === null || (isIncludeEmpty && v === '') ? a : ((a[k] = v), a);
    }, {});
    return removedObject;
};
export var toURLSearchParams = function (object) {
    var queryString = QueryString.stringify(object, { arrayFormat: 'comma' });
    return new URLSearchParams(queryString);
};
export var extractURLSearchParams = function (searchParams) {
    var allParams = QueryString.parse(decodeURIComponent(searchParams.toString()), {
        comma: true,
    });
    return allParams;
};
export var isInquiryCommentParent = function (item) {
    if (!item)
        return true; // for case create new inquiry
    return item.inquiryCommentParentId === '' ? true : false;
};
export var getEnv = function () {
    if (process.env.ENVIRONMENT === 'birdie-prod') {
        return 'prod';
    }
    else if (process.env.ENVIRONMENT === 'birdie-stg') {
        return 'stg';
    }
    else {
        return 'dev';
    }
};
