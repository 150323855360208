var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useContext, useRef } from 'react';
import { UploadQueueProgress } from '../components/commons/UploadQueue/UploadQueueProgress';
import { notification } from 'antd';
var BackgroundUploadContext = createContext({
    addQueue: function (_handler) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            _handler();
            return [2 /*return*/, true];
        });
    }); },
    getProgress: function () { return ({ processed: 0, total: 0, processing: 0 }); },
});
export function useBackgroundUpload() {
    var context = useContext(BackgroundUploadContext);
    return context;
}
var MAX_RETRY_ATTEMPT = 3;
var MAX_CONCURRENT_HANDLE = 5;
export function BackgroundUploadProvider(props) {
    var _this = this;
    var queues = useRef([]);
    var progress = useRef({ processed: 0, total: 0, processing: 0 });
    var _a = notification.useNotification(), api = _a[0], contextHolder = _a[1];
    var openQueueProgress = function (options) {
        api.open({
            key: 'background-upload-notification',
            message: (_jsx(BackgroundUploadContext.Consumer, { children: function (_a) {
                    var getProgress = _a.getProgress;
                    var _b = getProgress(), processed = _b.processed, total = _b.total;
                    return _jsx(UploadQueueProgress, { total: total, processed: processed });
                } })),
            closeIcon: false,
            placement: 'bottomRight',
            duration: (options === null || options === void 0 ? void 0 : options.duration) || 0,
            onClose: function () { return cleanProgress(); },
        });
    };
    var nextJob = function () { return __awaiter(_this, void 0, void 0, function () {
        var queue;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (progress.current.processing >= MAX_CONCURRENT_HANDLE)
                        return [2 /*return*/];
                    queue = queues.current.shift();
                    if (queue === undefined) {
                        openQueueProgress({ duration: 2 });
                        return [2 /*return*/]; // All queues run
                    }
                    progress.current.processing++;
                    openQueueProgress({ duration: 0 });
                    return [4 /*yield*/, queue
                            .handler()
                            .then(function (result) {
                            progress.current.processed++;
                            return result;
                        })
                            .catch(function () {
                            queue.retryAttempt++;
                            if (queue.retryAttempt <= MAX_RETRY_ATTEMPT) {
                                queues.current.push(queue);
                            }
                        })
                            .finally(function () {
                            progress.current.processing--;
                            nextJob();
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var getProgress = function () {
        return progress.current;
    };
    var cleanProgress = function () {
        if (queues.current.length === 0 &&
            progress.current.total === progress.current.processed &&
            progress.current.processing === 0) {
            progress.current.total = 0;
            progress.current.processed = 0;
        }
    };
    var addQueue = function (handler) {
        queues.current.push({ handler: handler, isProcessing: false, isFinished: false, retryAttempt: 0 });
        progress.current.total++;
        openQueueProgress();
        nextJob(); // Start job
        return true;
    };
    var providerProps = {
        addQueue: addQueue,
        getProgress: getProgress,
    };
    return (_jsxs(BackgroundUploadContext.Provider, { value: providerProps, children: [props.children, contextHolder] }));
}
