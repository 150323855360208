var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Badge as BadgeAnt, Space } from 'antd';
import { IMPORT_CSV_STATUS } from '../../../shared/constants';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
var Badge = styled(BadgeAnt)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  sup {\n    font-size: 11px !important;\n  }\n"], ["\n  sup {\n    font-size: 11px !important;\n  }\n"])));
var Status = function (props) {
    var item = props.item;
    var tStatus = useTranslation('components', {
        keyPrefix: 'importCSV.status',
    }).t;
    var color = '#ED6C02';
    switch (item.status) {
        case IMPORT_CSV_STATUS.FINISHED:
            color = '#3cb371';
            break;
        case IMPORT_CSV_STATUS.IMPORTING:
            color = '#787878';
            break;
    }
    return (_jsx(Space, { onClick: function (e) {
            e.stopPropagation();
        }, children: _jsx(Badge, { "data-testid": "btn-status", count: tStatus(item.status), color: color, style: {
                padding: '10px 15px',
                fontSize: '11px !important',
                borderRadius: '25px',
                display: 'inline',
                textTransform: 'capitalize',
            } }) }));
};
export { Status };
var templateObject_1;
