import commons from './commons.json';
import settings from './settings.json';
import inspectionResult from './inspectionResult.json';
import inspectionResultDetail from './inspectionResultDetail.json';
import camera from './camera.json';
import onScreenInspection from './onScreenInspection.json';
import onScreenCheckList from './onScreenCheckList.json';
import userAccount from './userAccount.json';
import supplierStyle from './supplierStyle.json';
import logs from './logs.json';
import shipping from './shipping.json';
import shippingDetail from './shippingDetail.json';
import document from './document.json';
import inspectorCompany from './inspectorCompany.json';
import createManualOnscreenInspection from './createManualOnscreenInspection.json';
import standardTermsSentences from './standardTermsSentences.json';
import notification from './notification.json';
import importCSV from './importCSV.json';
import exportData from './exportData.json';
import comment from './comment.json';
import tag from './tag.json';
import inquiry from './inquiry.json';
import defect from './defect.json';
export default {
    commons: commons,
    settings: settings,
    camera: camera,
    inspectionResult: inspectionResult,
    inspectionResultDetail: inspectionResultDetail,
    onScreenInspection: onScreenInspection,
    onScreenCheckList: onScreenCheckList,
    userAccount: userAccount,
    supplierStyle: supplierStyle,
    logs: logs,
    shipping: shipping,
    shippingDetail: shippingDetail,
    document: document,
    inspectorCompany: inspectorCompany,
    createManualOnscreenInspection: createManualOnscreenInspection,
    standardTermsSentences: standardTermsSentences,
    notification: notification,
    importCSV: importCSV,
    exportData: exportData,
    comment: comment,
    tag: tag,
    inquiry: inquiry,
    defect: defect,
};
