var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable max-lines */
import { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SettingOutlined, LogoutOutlined, AppstoreAddOutlined, UserOutlined, TeamOutlined, HistoryOutlined, MenuOutlined, ApartmentOutlined, SendOutlined, FilePdfOutlined, GroupOutlined, ExceptionOutlined, AuditOutlined, ContactsOutlined, ImportOutlined, BugOutlined, } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import colors from '../../shared/colors';
import { useAppI18n } from '../../contexts/AppI18nContext';
import { SettingModal } from './SettingModal';
import { ABILITY_ACTION, DISABLED_FEATURES, PAGINATION_DEFAULT_PAGE, USER_ACCOUNT_ROLES, } from '../../shared/constants';
import { ROUTE_LOGOUT, ROUTE_LOGS_LIST, ROUTE_USER_ACCOUNT_LIST, ROUTE_SUPPLIER_LIST, ROUTE_ADMIN_INSPECTION_RESULT_LIST, ROUTE_DEFECT_LIST, ROUTE_SHIPPING_LIST, ROUTE_ADMIN_SHIPPING_LIST, ROUTE_DOCUMENT_LIST, ROUTE_ADMIN_DOCUMENT_LIST, ROUTE_ADMIN_INSPECTOR_COMPANY_LIST, ROUTE_ADMIN_CREATE_MANUAL_ONSCREEN_INSPECTION, ROUTE_ADMIN_INQUIRY, ROUTE_ADMIN_MANAGER_TERMS_SENTENCES, ROUTE_ADMIN_IMPORT_CSV, ROUTE_ADMIN_EXPORT_DATA, } from '../../shared/urls';
import { checkDisableFeatures } from '../../shared/helpers';
import { Dropdown, Menu as OriginMenu, Row, Space, Badge } from 'antd';
import { useApp } from '../../contexts/AppContext';
import { useAppState, useInspectionInitAdvancedSearchFilter } from '../../contexts/AppStateContext';
import { BoxNotification } from './BoxNotification';
import _find from 'lodash/find';
import _includes from 'lodash/includes';
var Menu = styled(OriginMenu)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: white;\n  line-height: 38px;\n  border-bottom: 0;\n  background: none;\n  width: ", ";\n\n  .ant-menu-item-selected,\n  .ant-menu-item:hover,\n  .ant-menu-submenu-active,\n  .ant-menu-submenu-open,\n  .ant-menu-submenu-title,\n  .ant-menu-submenu-title:hover,\n  .ant-menu-item {\n    color: white !important;\n  }\n  .ant-menu-item {\n    padding-left: 16px;\n    padding-right: 16px;\n  }\n  .defectManagement .ant-badge {\n    font-size: 14px;\n    color: white;\n  }\n  .defectManagement .ant-badge .ant-badge-count {\n    height: 15px;\n    line-height: 15px;\n    top: -10px;\n    font-size: 12px;\n  }\n"], ["\n  color: white;\n  line-height: 38px;\n  border-bottom: 0;\n  background: none;\n  width: ", ";\n\n  .ant-menu-item-selected,\n  .ant-menu-item:hover,\n  .ant-menu-submenu-active,\n  .ant-menu-submenu-open,\n  .ant-menu-submenu-title,\n  .ant-menu-submenu-title:hover,\n  .ant-menu-item {\n    color: white !important;\n  }\n  .ant-menu-item {\n    padding-left: 16px;\n    padding-right: 16px;\n  }\n  .defectManagement .ant-badge {\n    font-size: 14px;\n    color: white;\n  }\n  .defectManagement .ant-badge .ant-badge-count {\n    height: 15px;\n    line-height: 15px;\n    top: -10px;\n    font-size: 12px;\n  }\n"])), function (props) { return (props.$isMobile ? '10%' : '100%'); });
var LogoText = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-left: 15px;\n  padding-right: 20px;\n  font-size: 20px;\n  line-height: 40px;\n  color: ", ";\n"], ["\n  padding-left: 15px;\n  padding-right: 20px;\n  font-size: 20px;\n  line-height: 40px;\n  color: ", ";\n"])), function (props) { return props.color; });
var SettingIconWrapper = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  height: 100%;\n  cursor: pointer;\n"], ["\n  display: flex;\n  align-items: center;\n  height: 100%;\n  cursor: pointer;\n"])));
var Menubar = function (props) {
    var _a = props.isShowMenuBar, isShowMenuBar = _a === void 0 ? true : _a, _b = props.isShowMenuItem, isShowMenuItem = _b === void 0 ? true : _b;
    var location = useLocation();
    var _c = useApp(), isAppReady = _c.isReady, user = _c.user;
    var navigate = useNavigate();
    var _d = useAppI18n(), isI18nReady = _d.isI18nReady, layout = _d.layout;
    var _e = useState(false), isOpenSetting = _e[0], setIsOpenSetting = _e[1];
    var _f = useState([]), listSupplierNames = _f[0], setListSupplierNames = _f[1];
    var t = useTranslation('components', { keyPrefix: 'commons' }).t;
    var canDisplay = isAppReady && isI18nReady;
    var isMobile = layout.isMobileLayoutOnPC || layout.isMobileScreenSize;
    var isLayoutSiteAdmin = layout.isLayoutSiteAdmin;
    var _g = useAppState(), filterInspection = _g.filterInspection, setFilterInspection = _g.setFilterInspection, filterShipping = _g.filterShipping, setFilterShipping = _g.setFilterShipping, listSupplier = _g.listSupplier, userAccount = _g.userAccount, defectSummary = _g.defectSummary;
    useEffect(function () {
        if (!isAppReady || listSupplier.length === 0)
            return;
        if ((user === null || user === void 0 ? void 0 : user.role) === USER_ACCOUNT_ROLES.SUPPLIER) {
            var supplierRelatedInfo = _find(listSupplier, {
                supplierId: user === null || user === void 0 ? void 0 : user.supplierId,
            });
            if (supplierRelatedInfo)
                setListSupplierNames([supplierRelatedInfo === null || supplierRelatedInfo === void 0 ? void 0 : supplierRelatedInfo.supplierName]);
        }
        else if ((user === null || user === void 0 ? void 0 : user.role) === USER_ACCOUNT_ROLES.CONTRACTOR) {
            if ((userAccount === null || userAccount === void 0 ? void 0 : userAccount.assignedSupplier) && (userAccount === null || userAccount === void 0 ? void 0 : userAccount.assignedSupplier.length) > 0) {
                var assignedSupplier = listSupplier.filter(function (i) {
                    return _includes(userAccount === null || userAccount === void 0 ? void 0 : userAccount.assignedSupplier, i.supplierId);
                });
                if (assignedSupplier.length) {
                    setListSupplierNames(assignedSupplier.map(function (i) { return i.supplierName; }));
                }
            }
        }
    }, [isAppReady, listSupplier]);
    var items = [];
    if (isLayoutSiteAdmin) {
        if (user && user.ability.can(ABILITY_ACTION.ACCESS, ROUTE_ADMIN_INSPECTION_RESULT_LIST)) {
            items.push({
                label: (_jsxs(Link, { to: ROUTE_ADMIN_INSPECTION_RESULT_LIST, onClick: function () {
                        setFilterInspection(function (prev) { return (__assign(__assign(__assign({}, prev), { supplierIds: [], pagination: __assign(__assign({}, filterInspection.pagination), { page: PAGINATION_DEFAULT_PAGE }) }), useInspectionInitAdvancedSearchFilter(user))); });
                    }, children: [_jsx(AppstoreAddOutlined, {}), " ", t('menubar.adminInspection')] })),
                key: ROUTE_ADMIN_INSPECTION_RESULT_LIST,
            });
        }
        if (user && user.ability.can(ABILITY_ACTION.ACCESS, ROUTE_ADMIN_SHIPPING_LIST)) {
            items.push({
                label: (_jsxs(Link, { to: ROUTE_ADMIN_SHIPPING_LIST, children: [_jsx(SendOutlined, {}), " ", t('menubar.adminShippingManagement')] })),
                key: ROUTE_ADMIN_SHIPPING_LIST,
            });
        }
        items.push({
            label: (_jsxs(Link, { to: ROUTE_ADMIN_DOCUMENT_LIST, children: [_jsx(FilePdfOutlined, {}), " ", t('menubar.adminDocumentManagement')] })),
            key: ROUTE_ADMIN_DOCUMENT_LIST,
        });
        if (!checkDisableFeatures(DISABLED_FEATURES.SP_MANAGEMENT)) {
            if (user && user.ability.can(ABILITY_ACTION.ACCESS, ROUTE_USER_ACCOUNT_LIST)) {
                items.push({
                    label: (_jsxs(Link, { to: ROUTE_USER_ACCOUNT_LIST, children: [_jsx(UserOutlined, {}), " ", t('menubar.userAccount')] })),
                    key: ROUTE_USER_ACCOUNT_LIST,
                });
            }
        }
        if (!checkDisableFeatures(DISABLED_FEATURES.SP_MANAGEMENT)) {
            if (user && user.ability.can(ABILITY_ACTION.ACCESS, ROUTE_SUPPLIER_LIST)) {
                items.push({
                    label: (_jsxs(Link, { to: ROUTE_SUPPLIER_LIST, children: [_jsx(TeamOutlined, {}), " ", t('menubar.supplierStyle')] })),
                    key: ROUTE_SUPPLIER_LIST,
                });
            }
        }
        if (user && user.ability.can(ABILITY_ACTION.ACCESS, ROUTE_ADMIN_INSPECTOR_COMPANY_LIST)) {
            items.push({
                label: (_jsxs(Link, { to: ROUTE_ADMIN_INSPECTOR_COMPANY_LIST, children: [_jsx(GroupOutlined, {}), " ", t('menubar.adminInspectorCompanyManagement')] })),
                key: ROUTE_ADMIN_INSPECTOR_COMPANY_LIST,
            });
        }
        if (user && user.ability.can(ABILITY_ACTION.ACCESS, ROUTE_ADMIN_IMPORT_CSV)) {
            items.push({
                label: (_jsxs(Link, { to: ROUTE_ADMIN_IMPORT_CSV, children: [_jsx(ImportOutlined, {}), " ", t('menubar.adminImportCSV')] })),
                key: ROUTE_ADMIN_IMPORT_CSV,
            });
        }
        if (user && user.ability.can(ABILITY_ACTION.ACCESS, ROUTE_ADMIN_EXPORT_DATA)) {
            items.push({
                label: (_jsxs(Link, { to: ROUTE_ADMIN_EXPORT_DATA, children: [_jsx(ImportOutlined, {}), " ", t('menubar.adminExportedFile')] })),
                key: ROUTE_ADMIN_EXPORT_DATA,
            });
        }
        if (user && user.ability.can(ABILITY_ACTION.ACCESS, ROUTE_ADMIN_MANAGER_TERMS_SENTENCES)) {
            items.push({
                label: (_jsxs(Link, { to: ROUTE_ADMIN_MANAGER_TERMS_SENTENCES, children: [_jsx(ExceptionOutlined, {}), " ", t('menubar.AdminStandardTermsSentences')] })),
                key: ROUTE_ADMIN_MANAGER_TERMS_SENTENCES,
            });
        }
        if (user &&
            user.ability.can(ABILITY_ACTION.ACCESS, ROUTE_ADMIN_CREATE_MANUAL_ONSCREEN_INSPECTION)) {
            items.push({
                label: (_jsxs(Link, { to: ROUTE_ADMIN_CREATE_MANUAL_ONSCREEN_INSPECTION, children: [_jsx(ExceptionOutlined, {}), " ", t('menubar.adminCreateManualOnscreenInspection')] })),
                key: ROUTE_ADMIN_CREATE_MANUAL_ONSCREEN_INSPECTION,
            });
        }
        if (user && user.ability.can(ABILITY_ACTION.ACCESS, ROUTE_ADMIN_INQUIRY)) {
            items.push({
                label: (_jsxs(Link, { to: ROUTE_ADMIN_INQUIRY, children: [_jsx(ExceptionOutlined, {}), " ", t('menubar.adminInquiry')] })),
                key: ROUTE_ADMIN_INQUIRY,
            });
        }
        if (user && user.ability.can(ABILITY_ACTION.ACCESS, ROUTE_LOGS_LIST)) {
            items.push({
                label: (_jsxs(Link, { to: ROUTE_LOGS_LIST, children: [_jsx(HistoryOutlined, {}), " ", t('menubar.adminLogs')] })),
                key: ROUTE_LOGS_LIST,
            });
        }
    }
    else {
        items = [
            {
                label: t('menubar.inspection'),
                key: '/',
                icon: _jsx(AppstoreAddOutlined, {}),
                onClick: function () {
                    setFilterInspection(function (prev) { return (__assign(__assign(__assign({}, prev), { supplierIds: [], pagination: __assign(__assign({}, filterInspection.pagination), { page: PAGINATION_DEFAULT_PAGE }) }), useInspectionInitAdvancedSearchFilter(user))); });
                    navigate('/');
                },
            },
            {
                label: t('menubar.shippingManagement'),
                key: ROUTE_SHIPPING_LIST,
                icon: _jsx(ApartmentOutlined, {}),
                onClick: function () {
                    setFilterShipping({
                        supplierIds: [],
                        status: '',
                        keyword: '',
                        pagination: __assign(__assign({}, filterShipping.pagination), { page: PAGINATION_DEFAULT_PAGE }),
                    });
                    navigate(ROUTE_SHIPPING_LIST);
                },
            },
            {
                label: (_jsx(Link, { to: ROUTE_DEFECT_LIST, children: _jsx(Space, { className: "defectManagement", children: isMobile ? (_jsxs(_Fragment, { children: [_jsx(BugOutlined, { style: { marginRight: '2px' } }), t('menubar.defectManagement'), _jsx(Badge, { overflowCount: 999, count: defectSummary.numberOfOpen })] })) : (_jsxs(Badge, { overflowCount: 999, count: defectSummary.numberOfOpen, children: [_jsx(BugOutlined, { style: { marginRight: '10px' } }), t('menubar.defectManagement')] })) }) })),
                key: ROUTE_DEFECT_LIST,
            },
            {
                label: t('menubar.documentManagement'),
                key: ROUTE_DOCUMENT_LIST,
                icon: _jsx(FilePdfOutlined, {}),
                onClick: function () { return navigate(ROUTE_DOCUMENT_LIST); },
            },
        ];
    }
    var userInformation = useMemo(function () {
        if (user === undefined)
            return [];
        return [
            {
                key: '1',
                label: (_jsxs(Space, { children: [_jsx(ContactsOutlined, {}), user === null || user === void 0 ? void 0 : user.email] })),
            },
            {
                key: '2',
                label: (_jsxs(_Fragment, { children: [_jsxs(Space, { children: [_jsx(AuditOutlined, {}), t("userRoles.".concat(user === null || user === void 0 ? void 0 : user.role))] }), listSupplierNames.length > 0 ? (_jsx("ul", { className: "user-supplier-names", children: listSupplierNames.map(function (i) {
                                return _jsx("li", { children: i }, i);
                            }) })) : (_jsx(_Fragment, {}))] })),
            },
            {
                key: '3',
                label: (_jsxs(Space, { onClick: function () { return navigate(ROUTE_LOGOUT); }, style: { width: '100%' }, children: [_jsx(LogoutOutlined, {}), t('menubar.logout')] })),
            },
        ];
    }, [user, listSupplierNames]);
    if (!isShowMenuItem)
        items = [];
    return canDisplay && isShowMenuBar ? (_jsxs(_Fragment, { children: [_jsxs(Row, { wrap: false, align: "middle", style: { width: isMobile ? 'unset' : '100%' }, children: [_jsx(LogoText, { color: 'white', children: _jsx(Link, { to: "/", style: { color: 'white' }, title: "Birdie", children: "Birdie" }) }), _jsx(Menu, { mode: "horizontal", selectedKeys: [location.pathname], items: items, overflowedIndicator: _jsx(MenuOutlined, {}), "$isMobile": isMobile })] }), _jsxs(Row, { wrap: false, justify: "end", align: "middle", style: { marginLeft: 'auto', marginRight: 20, width: '100px' }, children: [!isLayoutSiteAdmin && _jsx(BoxNotification, {}), _jsx(Dropdown, { menu: { items: userInformation }, placement: "bottomLeft", children: _jsx(UserOutlined, { style: { fontSize: 24, color: colors.white, marginRight: '20px', cursor: 'pointer' } }) }), _jsx(SettingIconWrapper, { children: _jsx(SettingOutlined, { style: { fontSize: 24, color: colors.white }, onClick: function () { return setIsOpenSetting(true); } }) })] }), _jsx(SettingModal, { isOpenSetting: isOpenSetting, setIsOpenSetting: setIsOpenSetting })] })) : (_jsx(_Fragment, {}));
};
export { Menubar };
var templateObject_1, templateObject_2, templateObject_3;
