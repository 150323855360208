import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'antd';
import { UndoOutlined } from '@ant-design/icons';
import { useAppI18n } from '../../contexts/AppI18nContext';
var ButtonEdit = function (props) {
    var dataTestId = props.dataTestId;
    var tCommon = useTranslation('components', { keyPrefix: 'commons' }).t;
    var layout = useAppI18n().layout;
    var isMobile = layout.isMobileLayoutOnPC || layout.isMobileScreenSize || layout.isMobileLayout;
    return (_jsx(_Fragment, { children: isMobile ? (_jsxs(Row, { style: { cursor: 'pointer' }, "data-testid": dataTestId, onClick: function () { return props.onClick(); }, children: [_jsx(Col, { span: 6, children: _jsx(UndoOutlined, {}) }), _jsx(Col, { span: 18, children: tCommon('buttons.reImportCSV') })] })) : (_jsxs(Button, { "data-testid": dataTestId, onClick: function () { return props.onClick(); }, type: "primary", style: { textTransform: 'uppercase' }, children: [_jsx(UndoOutlined, {}), " ", tCommon('buttons.reImportCSV')] })) }));
};
export { ButtonEdit };
