var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint max-lines: [error, 400] */
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Modal, Space, Drawer } from 'antd';
import Webcam from 'react-webcam';
import { CAMERA_FACING_MODE } from '../../shared/constants';
import { UPLOAD_FEATURE, } from '../../shared/types';
import styled from 'styled-components';
import { CameraOutlined, SyncOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import arrowLeft from '../icons/left-arrow-border.svg';
import { dataURLtoFile } from '../../shared/fileUtils';
import { useFileHandler, useDefectAttachmentFileHandler } from '../../hooks/upload';
import { useAppI18n } from '../../contexts/AppI18nContext';
import { useBackgroundUpload } from '../../contexts/BackgroundUploadContext';
var warning = Modal.warning;
var DrawerStyle = styled(Drawer)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .annotationLayer {\n    width: auto !important;\n    height: auto !important;\n  }\n  .ant-drawer-header {\n    position: fixed;\n    z-index: 9999;\n    width: 100%;\n  }\n"], ["\n  .annotationLayer {\n    width: auto !important;\n    height: auto !important;\n  }\n  .ant-drawer-header {\n    position: fixed;\n    z-index: 9999;\n    width: 100%;\n  }\n"])));
var SwitchAndCaptureWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  left: 50%;\n  bottom: 20%;\n"], ["\n  position: absolute;\n  left: 50%;\n  bottom: 20%;\n"])));
var VideoContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  top: 0;\n  bottom: 0;\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n\n  video,\n  img {\n    /* Make video to at least 100% wide and tall */\n    min-width: 100%;\n    min-height: 100%;\n\n    /* Setting width & height to auto prevents the browser from stretching or squishing the video */\n    width: auto;\n    height: auto;\n\n    /* Center the video */\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n  }\n"], ["\n  top: 0;\n  bottom: 0;\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n\n  video,\n  img {\n    /* Make video to at least 100% wide and tall */\n    min-width: 100%;\n    min-height: 100%;\n\n    /* Setting width & height to auto prevents the browser from stretching or squishing the video */\n    width: auto;\n    height: auto;\n\n    /* Center the video */\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n  }\n"])));
function Camera(props) {
    var _this = this;
    var show = props.show, handleClose = props.handleClose, uploadFeature = props.uploadFeature;
    var layout = useAppI18n().layout;
    var isMobile = layout.isMobileLayoutOnPC || layout.isMobileScreenSize || layout.isMobileLayout;
    var handleUploadFile = useFileHandler().handleUploadFile;
    var handleUploadDefectAttachmentFile = useDefectAttachmentFileHandler().handleUploadDefectAttachmentFile;
    var t = useTranslation('components', {
        keyPrefix: 'camera',
    }).t;
    var webcamRef = useRef(null);
    var _a = useState(CAMERA_FACING_MODE.ENVIRONMENT), facingMode = _a[0], setFacingMode = _a[1];
    var _b = useState(''), imgSrc = _b[0], setImgSrc = _b[1];
    var _c = useState(false), cameraHasError = _c[0], setCameraHasError = _c[1];
    var _d = useState(false), isProcessing = _d[0], setIsProcessing = _d[1];
    var addQueue = useBackgroundUpload().addQueue;
    useEffect(function () {
        return function () {
            handleStopCamera();
        };
    }, [show]);
    var videoConstraints = {
        width: 1280,
        height: 720,
        facingMode: facingMode,
    };
    var handleCapture = useCallback(function () {
        var imageSrc = webcamRef.current.getScreenshot();
        setImgSrc(imageSrc);
        handleStopCamera();
    }, [webcamRef]);
    var handleSwitch = useCallback(function () {
        setFacingMode(function (prevState) {
            return prevState === CAMERA_FACING_MODE.USER
                ? CAMERA_FACING_MODE.ENVIRONMENT
                : CAMERA_FACING_MODE.USER;
        });
    }, []);
    var handleStopCamera = function () {
        var _a, _b;
        var stream = (_b = (_a = webcamRef.current) === null || _a === void 0 ? void 0 : _a.video) === null || _b === void 0 ? void 0 : _b.srcObject;
        if (stream) {
            var tracks = stream.getTracks();
            tracks.forEach(function (track) { return track.stop(); });
            webcamRef.current.video.srcObject = null;
        }
    };
    var handleStartCamera = function () { return __awaiter(_this, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!webcamRef.current) return [3 /*break*/, 2];
                    _a = webcamRef.current.video;
                    return [4 /*yield*/, navigator.mediaDevices
                            .getUserMedia({
                            video: __assign({}, videoConstraints),
                            audio: false,
                        })
                            .catch(function (err) {
                            setCameraHasError(true);
                            handleClose();
                            var errorMapType = {
                                NotFoundError: 'noDevice',
                                DevicesNotFoundError: 'noDevice',
                                NotAllowedError: 'permissionDenied',
                                PermissionDeniedError: 'permissionDenied',
                            };
                            var errorType = _get(errorMapType, err.name, 'permissionDenied');
                            showModalWarning({
                                title: t("messages.".concat(errorType, ".title")),
                                content: t("messages.".concat(errorType, ".content")),
                            });
                        })];
                case 1:
                    _a.srcObject = _b.sent();
                    _b.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var saveFile = function () { return __awaiter(_this, void 0, void 0, function () {
        var fileName, fileChange, handle;
        var _this = this;
        return __generator(this, function (_a) {
            if (!imgSrc)
                return [2 /*return*/, false];
            // prevent multiple click at the same time.
            if (isProcessing === true) {
                return [2 /*return*/, false];
            }
            setIsProcessing(true);
            fileName = 'screenshot-' + Date.now() + '.png';
            fileChange = dataURLtoFile(imgSrc, fileName);
            if (props.onProgressing)
                props.onProgressing();
            if (fileChange) {
                handle = function () { return __awaiter(_this, void 0, void 0, function () {
                    var result;
                    var _a;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0: return [4 /*yield*/, (uploadFeature === UPLOAD_FEATURE.SHIPPING
                                    ? handleUploadFile(props.photoType, props.belongsId, [fileChange], uploadFeature, (_a = props.sequence) !== null && _a !== void 0 ? _a : 1)
                                    : uploadFeature === UPLOAD_FEATURE.DEFECT
                                        ? handleUploadDefectAttachmentFile(props.belongsId, [fileChange])
                                        : handleUploadFile(props.photoType, props.belongsId, [fileChange], uploadFeature)).then(function (photoItems) {
                                    if (props.onSuccessed)
                                        props.onSuccessed(photoItems);
                                    if (props.setHasUploadSuccess)
                                        props.setHasUploadSuccess(true);
                                    return photoItems.length > 0;
                                })];
                            case 1:
                                result = _b.sent();
                                return [2 /*return*/, result];
                        }
                    });
                }); };
                addQueue(handle);
                setIsProcessing(false);
                setImgSrc('');
                return [2 /*return*/, true];
            }
            setIsProcessing(false);
            return [2 /*return*/, false];
        });
    }); };
    var handleAddAnother = function () {
        (function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, saveFile()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); })();
    };
    var handleSaveAndQuit = function () {
        (function () { return __awaiter(_this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, saveFile()];
                    case 1:
                        result = _a.sent();
                        if (result) {
                            handleCloseDrawer();
                        }
                        return [2 /*return*/];
                }
            });
        }); })();
    };
    var handleCloseDrawer = function () {
        setImgSrc('');
        setCameraHasError(false);
        handleStopCamera();
        handleClose();
    };
    var showModalWarning = function (_a) {
        var title = _a.title, content = _a.content;
        warning({
            title: title,
            centered: true,
            content: _jsx(_Fragment, { children: content }),
        });
    };
    var renderActionButton = function () {
        return (_jsxs(_Fragment, { children: [_jsx(Button, { onClick: function () { return setImgSrc(''); }, disabled: !imgSrc || isProcessing, "data-testid": "btn-discard", children: t('buttons.discard') }, "discard"), _jsx(Button, { type: "dashed", onClick: handleAddAnother, disabled: !imgSrc || isProcessing, "data-testid": "btn-addAnother", style: { marginLeft: '10px' }, children: t('buttons.addAnother') }, "other"), _jsx(Button, { type: "primary", onClick: handleSaveAndQuit, disabled: !imgSrc || isProcessing, "data-testid": "btn-saveAndQuit", style: { marginLeft: '10px' }, children: t('buttons.saveAndQuit') }, "submit")] }));
    };
    return !cameraHasError ? (_jsx(DrawerStyle, { open: show, onClose: handleCloseDrawer, width: "100%", style: { height: 'calc(100vh + 100px)' }, closeIcon: _jsx("span", { children: _jsx("img", { "data-testid": "closeDrawer", src: arrowLeft, alt: "", style: { width: '40px' } }) }), styles: { header: { border: 'none' }, body: { paddingTop: isMobile ? '90px' : '120px' } }, extra: renderActionButton(), afterOpenChange: function () {
            if (show)
                handleStartCamera();
        }, children: _jsxs(Space, { direction: "vertical", size: "middle", style: { display: 'flex' }, children: [_jsx(VideoContainer, { children: show &&
                        (imgSrc ? (_jsx("img", { alt: "", style: { width: '100%' }, src: imgSrc, "data-testid": "previewImage" })) : (_jsx(Webcam, { audio: false, height: videoConstraints.height, ref: webcamRef, screenshotFormat: "image/jpeg", width: videoConstraints.width, videoConstraints: videoConstraints, "data-testid": "camera-container" }))) }), _jsx(SwitchAndCaptureWrapper, { children: _jsxs("div", { style: { position: 'relative' }, children: [_jsx(Button, { style: { position: 'absolute', bottom: '0%', left: '-180%' }, shape: "circle", disabled: !imgSrc ? false : true, onClick: handleSwitch, icon: _jsx(SyncOutlined, {}), "data-testid": "btn-switch" }), _jsx(Button, { shape: "circle", size: "large", type: "primary", disabled: !imgSrc ? false : true, onClick: handleCapture, icon: _jsx(CameraOutlined, {}), "data-testid": "btn-capture" })] }) })] }) })) : (_jsx(_Fragment, {}));
}
export default Camera;
var templateObject_1, templateObject_2, templateObject_3;
