import { useState } from 'react';
import { useAppI18n } from '../../contexts/AppI18nContext';
var timezoneOptions = [
    {
        value: 'Asia/Tokyo',
        label: '(GMT+09:00) 日本基準時/Japan Standard Time',
    },
    {
        value: 'Asia/Ho_Chi_Minh',
        label: '(GMT+07:00) Indochina Time - Ho Chi Minh City',
    },
    {
        value: 'America/Mexico_City',
        label: '(GMT-06:00) CST - Mexico City',
    },
];
export var useSelectTimezone = function () {
    var currentTimezone = useAppI18n().timezone;
    var _a = useState(currentTimezone), timezone = _a[0], setTimezone = _a[1];
    var timezoneChangeHandler = function (value) {
        setTimezone(value);
    };
    return {
        currentTimezone: currentTimezone,
        timezone: timezone,
        timezoneChangeHandler: timezoneChangeHandler,
        timezoneOptions: timezoneOptions,
    };
};
