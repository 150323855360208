import { jsx as _jsx } from "react/jsx-runtime";
import { pdfBase64toBlob } from '../../shared/fileUtils';
import { Worker, Viewer } from '@react-pdf-viewer/core';
// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
var PDFViewer = function (props) {
    var src = props.src, _a = props.showToolbar, showToolbar = _a === void 0 ? true : _a, _b = props.showNavPanes, showNavPanes = _b === void 0 ? true : _b, _c = props.showScrollbar, showScrollbar = _c === void 0 ? true : _c, _d = props.page, page = _d === void 0 ? 1 : _d, _e = props.defaultScale, defaultScale = _e === void 0 ? 1 : _e;
    var isBase64Data = /application\/pdf;base64/.test(src);
    var url = '';
    if (isBase64Data) {
        var blob = pdfBase64toBlob(src);
        url = URL.createObjectURL(blob);
    }
    // Create new plugin instance
    var defaultLayoutPluginInstance = defaultLayoutPlugin({});
    var pdfQueryParamString = new URLSearchParams({
        toolbar: "".concat(showToolbar ? 1 : 0),
        navpanes: "".concat(showNavPanes ? 1 : 0),
        scrollbar: "".concat(showScrollbar ? 1 : 0),
        page: "".concat(page),
        view: "FitH",
    }).toString();
    return (_jsx("object", { id: src, data: isBase64Data ? "".concat(url) : "".concat(src, "#").concat(pdfQueryParamString), width: '100%', height: '100%', type: "application/pdf", children: _jsx(Worker, { workerUrl: "https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js", children: _jsx(Viewer, { fileUrl: isBase64Data ? url : src, defaultScale: defaultScale, plugins: showToolbar ? [defaultLayoutPluginInstance] : [] }, "pdf_viewer_".concat(defaultScale)) }) }, src));
};
export { PDFViewer };
