var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { useCallback, useEffect, useRef, useState } from 'react';
import { GridView } from './GridView';
import { PAGINATION_DEFAULT_PAGE, PAGINATION_DEFAULT_SIZE } from '../../shared/constants';
import { PaginationCustom } from '../commons/PaginationCustom';
import { useFetchListAdminLogsHooks } from '../../hooks/Logs';
import { useAppI18n } from '../../contexts/AppI18nContext';
var TableWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var LogsList = function (props) {
    var _a = props.keywordSearch, keywordSearch = _a === void 0 ? '' : _a, _b = props.searchGroup, searchGroup = _b === void 0 ? '' : _b, _c = props.searchAction, searchAction = _c === void 0 ? '' : _c;
    var layout = useAppI18n().layout;
    var isMobile = layout.isMobileLayoutOnPC || layout.isMobileScreenSize || layout.isMobileLayout;
    var defaultBody = {
        query: {
            page: PAGINATION_DEFAULT_PAGE,
            size: PAGINATION_DEFAULT_SIZE,
            keyword: keywordSearch,
            group: searchGroup,
            action: searchAction,
        },
    };
    var _d = useState(__assign({}, defaultBody)), queryList = _d[0], setQueryList = _d[1];
    var queryRef = useRef(__assign({}, defaultBody));
    var setQueryToCall = function (pagination) {
        if (pagination.page)
            queryRef.current.query.page = pagination.page;
        if (pagination.size)
            queryRef.current.query.size = pagination.size;
        if (pagination.keyword !== undefined && queryRef.current.query.keyword !== pagination.keyword) {
            queryRef.current.query.page = PAGINATION_DEFAULT_PAGE;
            queryRef.current.query.keyword = pagination.keyword;
        }
        if (pagination.group !== undefined) {
            queryRef.current.query.group = pagination.group;
        }
        if (pagination.action !== undefined) {
            queryRef.current.query.action = pagination.action;
        }
        setQueryList(function (state) { return (__assign(__assign({}, state), queryRef.current)); });
    };
    var handleTableChange = useCallback(function (pagination) {
        setQueryToCall({
            page: pagination.current,
            size: pagination.pageSize,
        });
    }, []);
    var _e = useState({
        data: [],
        pagination: { page: PAGINATION_DEFAULT_PAGE, size: PAGINATION_DEFAULT_SIZE, total: 0 },
    }), listData = _e[0], setListData = _e[1];
    var listLogsHandler = useFetchListAdminLogsHooks().listLogsHandler;
    useEffect(function () {
        setQueryToCall({
            keyword: keywordSearch,
            group: searchGroup,
            action: searchAction,
        });
    }, [keywordSearch, searchGroup, searchAction]);
    var getDataList = function () {
        if (props.onLoading)
            props.onLoading(); // Loading page
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            var body;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, listLogsHandler({ query: __assign({}, queryRef.current.query) })];
                    case 1:
                        body = _a.sent();
                        if (props.onProgressed)
                            props.onProgressed(); // Load everything has completed
                        if (!body)
                            return [2 /*return*/];
                        setListData(body);
                        return [2 /*return*/];
                }
            });
        }); })();
    };
    useEffect(function () {
        getDataList();
    }, [queryList]);
    // Get/Set page option from customize pagination
    var getSizeChange = function (size) {
        setPaginationOption(PAGINATION_DEFAULT_PAGE, size);
    };
    var getNextPage = function (page) {
        var _a;
        setPaginationOption(page, (_a = listData.pagination) === null || _a === void 0 ? void 0 : _a.size);
    };
    var getPreviousPage = function (page) {
        var _a;
        setPaginationOption(page, (_a = listData.pagination) === null || _a === void 0 ? void 0 : _a.size);
    };
    var setPaginationOption = function (page, size) {
        setQueryToCall({
            page: page,
            size: size,
        });
    };
    // End
    return (_jsxs(TableWrapper, { "data-testid": "table_wrapper", children: [_jsx(GridView, { dataList: listData.data, pagination: {
                    position: ['bottomCenter'],
                    current: listData.pagination.page || PAGINATION_DEFAULT_PAGE,
                    pageSize: listData.pagination.size || PAGINATION_DEFAULT_SIZE,
                    total: listData.pagination.total || 0,
                    showSizeChanger: false,
                }, onChange: handleTableChange, reloadList: getDataList, isPageLoading: props.isPageLoading }), !isMobile && (_jsx(PaginationCustom, { pagination: listData.pagination, sizeChange: getSizeChange, nextPage: getNextPage, previousPage: getPreviousPage }))] }));
};
var templateObject_1;
