var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTranslation } from 'react-i18next';
import { Button, Modal, Space, Form, Upload, Row, Col, Image } from 'antd';
import { useEffect, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { IMPORT_CSV_MIME_TYPES, IMPORT_CSV_TYPES } from '../../shared/fileUtils';
import { FIELDS_IMPORT_CSV_QBO, UPLOAD_CSV_FILE_SIZE_MB_MAX } from '../../shared/constants';
import { validateFileSize } from '../../shared/validateFiles';
import { showCommonSuccessNotification } from '../../shared/logs';
import { useImportCSVFileHandler } from '../../hooks/upload';
import { useCreateImportCSVHooks, useUpdateImportCSVHooks } from '../../hooks/ImportCSV';
import csvIcon from '../icons/csv-icon.svg';
import { usePapaParse } from 'react-papaparse';
var FormUpload = function (props) {
    var isOpenForm = props.isOpenForm, _a = props.item, item = _a === void 0 ? undefined : _a;
    var t = useTranslation('components', { keyPrefix: 'importCSV' }).t;
    var tCommon = useTranslation('components', { keyPrefix: 'commons' }).t;
    var _b = useState(null), fileUploadSelected = _b[0], setFileUploadSelected = _b[1];
    var _c = useState([]), fileList = _c[0], setFileList = _c[1];
    var form = Form.useForm()[0];
    var _d = useState(false), isSubmiting = _d[0], setIsSubmiting = _d[1];
    var _e = useState(undefined), errorUploadFile = _e[0], setErrorUploadFile = _e[1];
    var _f = useState(true), hasValidateSuccess = _f[0], setHasValidateSuccess = _f[1];
    var _g = useImportCSVFileHandler(), handleUploadImportCSVFile = _g.handleUploadImportCSVFile, handleReUploadImportCSVFile = _g.handleReUploadImportCSVFile, getImportCSVUploadInfo = _g.getImportCSVUploadInfo;
    var createImportCSVHookHandler = useCreateImportCSVHooks().createImportCSVHookHandler;
    var updateImportCSVHookHandler = useUpdateImportCSVHooks().updateImportCSVHookHandler;
    var processImportCSVHandler = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsSubmiting(true);
                    if (!item) return [3 /*break*/, 1];
                    // Update DB
                    (function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!fileUploadSelected) {
                                        setIsSubmiting(false);
                                        if (props.onSuccessed)
                                            props.onSuccessed(true);
                                        return [2 /*return*/];
                                    }
                                    // Re-upload file
                                    return [4 /*yield*/, handleReUploadImportCSVFile(item.qboImportingId, fileUploadSelected, function (uploadUrl) {
                                            (function () { return __awaiter(void 0, void 0, void 0, function () {
                                                var _a;
                                                return __generator(this, function (_b) {
                                                    switch (_b.label) {
                                                        case 0: return [4 /*yield*/, updateImportCSVHookHandler({
                                                                params: [item.qboImportingId],
                                                                body: {
                                                                    originalFileName: (_a = fileUploadSelected === null || fileUploadSelected === void 0 ? void 0 : fileUploadSelected.name) !== null && _a !== void 0 ? _a : undefined,
                                                                    filePath: uploadUrl.filePath || uploadUrl.fileName,
                                                                },
                                                                onProgressing: function () {
                                                                    if (props.onProgressing)
                                                                        props.onProgressing();
                                                                },
                                                                onSuccessed: function () {
                                                                    if (props.onSuccessed)
                                                                        props.onSuccessed();
                                                                    showCommonSuccessNotification({ message: tCommon('message.updateSuccessMsg') });
                                                                    resetAllHandler();
                                                                    form.resetFields();
                                                                },
                                                            })];
                                                        case 1:
                                                            _b.sent();
                                                            return [2 /*return*/];
                                                    }
                                                });
                                            }); })();
                                        })];
                                case 1:
                                    // Re-upload file
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); })();
                    return [3 /*break*/, 3];
                case 1:
                    if (!fileUploadSelected) {
                        setIsSubmiting(false);
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, handleUploadImportCSVFile([fileUploadSelected], function () { }, function (uploadUrl) {
                            // Begin insert DB
                            (function () { return __awaiter(void 0, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, createImportCSVHookHandler({
                                                body: {
                                                    originalFileName: uploadUrl.originalFileName,
                                                    filePath: uploadUrl.filePath || uploadUrl.fileName,
                                                },
                                                onProgressing: function () {
                                                    if (props.onProgressing)
                                                        props.onProgressing();
                                                },
                                                onSuccessed: function () {
                                                    if (props.onSuccessed)
                                                        props.onSuccessed();
                                                    resetAllHandler();
                                                    form.resetFields();
                                                },
                                            })];
                                        case 1:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); })();
                        })];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var resetAllHandler = function () {
        setHasValidateSuccess(true);
        setIsSubmiting(false);
        setFileUploadSelected(null);
        setFileList([]);
        setErrorUploadFile(undefined);
    };
    var handleOnChangeUpload = function (info) {
        setErrorUploadFile(undefined);
        setIsSubmiting(false);
        var fileUpload = info.file;
        if (info.file.status === 'removed') {
            resetAllHandler();
        }
        else {
            var isValid = false;
            var isSupportFileType = true;
            var maxFileSizeMb = 0;
            switch (fileUpload.type) {
                case IMPORT_CSV_MIME_TYPES.CSV:
                    maxFileSizeMb = UPLOAD_CSV_FILE_SIZE_MB_MAX;
                    break;
                default:
                    isSupportFileType = false;
                    break;
            }
            if (isSupportFileType)
                isValid = validateFileSize(fileUpload.size, maxFileSizeMb);
            if (!isValid || !isSupportFileType) {
                setFileList([{ uid: fileUpload.uid, name: fileUpload.name, status: 'error' }]);
                if (!isSupportFileType) {
                    setErrorUploadFile("".concat(fileUpload.name, "::") + tCommon('uploadFile.fileTypeNotSupport'));
                }
                else if (!isValid) {
                    setErrorUploadFile("".concat(fileUpload.name, "::") +
                        tCommon('uploadFile.exceed-message', { maxSize: maxFileSizeMb }));
                }
            }
            else {
                validateCSVFile(fileUpload);
                setFileUploadSelected(fileUpload);
                setFileList([__assign(__assign({}, fileUpload), { name: fileUpload.name })]);
            }
        }
        return;
    };
    useEffect(function () {
        if (!isOpenForm)
            return;
        if (!item || item === undefined) {
            return;
        }
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getImportCSVUploadInfo({
                            originalFileName: item.originalFileName,
                        }).then(function (fileInfo) {
                            setFileList([
                                {
                                    uid: '1',
                                    name: fileInfo ? fileInfo.originalFileName : item.originalFileName,
                                },
                            ]);
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); })();
    }, [isOpenForm, item]);
    var validateCSVFile = function (file) {
        var reader = new FileReader();
        reader.onload = function (e) {
            var _a, _b;
            if (!e.target || e.target === undefined)
                return;
            var csvContent = ((_b = (_a = e.target) === null || _a === void 0 ? void 0 : _a.result) === null || _b === void 0 ? void 0 : _b.toString()) || '';
            var readString = usePapaParse().readString;
            readString(csvContent, {
                worker: true,
                header: true,
                skipEmptyLines: true,
                complete: function (results) {
                    var _a;
                    if (results.data.length === 0 ||
                        results.meta.fields === undefined ||
                        results.meta.fields.length === 0) {
                        setErrorUploadFile("".concat(file.name, "::") + tCommon('uploadFile.fileEmptyData'));
                        setHasValidateSuccess(false);
                    }
                    else {
                        var csvFields_1 = ((_a = results.meta.fields) !== null && _a !== void 0 ? _a : []).map(function (field) { return field.toLowerCase().trim(); });
                        var isValid = FIELDS_IMPORT_CSV_QBO.every(function (key) {
                            return csvFields_1.includes(key.toLowerCase());
                        });
                        if (!isValid) {
                            setErrorUploadFile("".concat(file.name, "::") + tCommon('uploadFile.fileWrongFormat'));
                            setHasValidateSuccess(false);
                        }
                        else {
                            setHasValidateSuccess(true);
                        }
                    }
                },
            });
        };
        reader.readAsText(file);
    };
    return (_jsx(Modal, { "data-testid": 'test-modal-form-upload', title: item && item.qboImportingId ? t('modal.header.edit') : t('modal.header.create'), centered: true, open: isOpenForm, width: 380, closable: false, maskClosable: false, footer: false, forceRender: true, children: _jsx(Space.Compact, { style: { width: '100%' }, children: _jsxs(Form, { form: form, style: { width: '100%' }, onFinish: function () {
                    processImportCSVHandler();
                }, children: [_jsx("div", { style: { marginTop: '10px', marginBottom: '5px' }, children: _jsx(Upload, { listType: "picture", maxCount: 1, name: "importCSVFile", accept: ".".concat(Object.values(IMPORT_CSV_TYPES).join(', .')), onChange: function (info) {
                                handleOnChangeUpload(info);
                            }, "data-testid": "chooseFile", fileList: fileList, iconRender: function (file) {
                                return _jsx(Image, { preview: false, src: csvIcon });
                            }, showUploadList: { showRemoveIcon: item ? false : true }, beforeUpload: function (_) {
                                // Prevent upload
                                return false;
                            }, children: _jsx(Button, { name: "btnUploadFile", icon: _jsx(UploadOutlined, {}), children: tCommon('buttons.upload') }) }) }), _jsxs("div", { style: { marginBottom: '30px' }, children: [_jsx("i", { style: { color: '#cccccc', fontSize: '80%' }, children: t('form.noteUpload', { maxCSVSize: UPLOAD_CSV_FILE_SIZE_MB_MAX }) }), errorUploadFile !== undefined && (_jsx("div", { style: { color: '#ff4d4f' }, children: errorUploadFile }))] }), _jsxs(Row, { children: [_jsx(Col, { span: 12, style: { textAlign: 'left' }, children: _jsx(Button, { type: "default", onClick: function () {
                                        resetAllHandler();
                                        form.resetFields();
                                        if (props.onSuccessed)
                                            props.onSuccessed();
                                    }, children: tCommon('buttons.cancel') }) }), _jsx(Col, { span: 12, style: { textAlign: 'right' }, children: _jsx(Button, { type: "primary", loading: isSubmiting, "data-testid": "btn-submit-form-importCSV", onClick: function () {
                                        if (!hasValidateSuccess)
                                            return;
                                        form.submit();
                                        if ((!item || item === undefined) && !fileUploadSelected) {
                                            setErrorUploadFile(t('form.validation.fileRequired'));
                                        }
                                    }, children: tCommon('buttons.ok') }) })] })] }) }) }));
};
export { FormUpload };
