var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { putOriginalFile } from '../../api';
import { sendError, showCommonErrorNotification } from '../../shared/logs';
import { useFetchDefectUploadUrl, } from '../../api/defect/useFetchDefectUploadUrl';
import { useFetchDefectReUploadUrl } from '../../api/defect/useFetchDefectReUploadUrl';
import { usePostDefectAttachmentData, } from '../../api/defect/usePostDefectAttachmentData';
import { useCallback } from 'react';
import { compressImage } from '../../shared/fileUtils';
export var useDefectAttachmentFileHandler = function () {
    var dispatchDefectAttachmentUploadUrl = useFetchDefectUploadUrl();
    var dispatchDefectAttachmentReUploadUrl = useFetchDefectReUploadUrl();
    var dispatchCreateDefectAttachment = usePostDefectAttachmentData();
    var handleUploadDefectAttachmentFile = function (defectSyncId, fileList) { return __awaiter(void 0, void 0, void 0, function () {
        var responseData, uploadProcesses, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    responseData = [];
                    if (fileList.length === 0)
                        return [2 /*return*/, []];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    uploadProcesses = fileList.map(function (file) { return __awaiter(void 0, void 0, void 0, function () {
                        var uploadUrl, compressedFile, savePhotoObject, savedPhoto;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, getDefectAttachmentUploadInfo({
                                        originalFileName: file.name,
                                        defectSyncId: defectSyncId,
                                    })];
                                case 1:
                                    uploadUrl = _a.sent();
                                    if (uploadUrl === undefined) {
                                        return [2 /*return*/, false];
                                    }
                                    return [4 /*yield*/, compressImage(file)];
                                case 2:
                                    compressedFile = _a.sent();
                                    return [4 /*yield*/, putOriginalFile({ file: compressedFile, uploadUrl: uploadUrl.signedUrl })];
                                case 3:
                                    _a.sent();
                                    savePhotoObject = {
                                        defectSyncId: defectSyncId,
                                        fileName: uploadUrl.fileName,
                                        originalFileName: uploadUrl.originalFileName,
                                    };
                                    return [4 /*yield*/, savePhotoData(savePhotoObject)];
                                case 4:
                                    savedPhoto = _a.sent();
                                    if (savedPhoto !== undefined && savedPhoto.isSuccess && savedPhoto.data !== null) {
                                        responseData.push(savedPhoto.data);
                                    }
                                    return [2 /*return*/];
                            }
                        });
                    }); });
                    return [4 /*yield*/, Promise.all(uploadProcesses)];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    showCommonErrorNotification(e_1);
                    sendError(e_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/, responseData];
            }
        });
    }); };
    var savePhotoData = useCallback(function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var result, message, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!dispatchCreateDefectAttachment)
                        return [2 /*return*/];
                    return [4 /*yield*/, dispatchCreateDefectAttachment({
                            body: params,
                            stopSendingErr: true,
                            stopShowingErrNotification: true,
                        })];
                case 1:
                    result = _a.sent();
                    if (!result.isSuccess) {
                        message = "".concat(params.originalFileName, "::") + result.err.message;
                        showCommonErrorNotification({ message: message });
                    }
                    data = result.isSuccess ? result.body : null;
                    return [2 /*return*/, { isSuccess: result.isSuccess, data: data }];
            }
        });
    }); }, [dispatchCreateDefectAttachment]);
    var getDefectAttachmentUploadInfo = useCallback(function (defectUploadUrlArgs) { return __awaiter(void 0, void 0, void 0, function () {
        var result, message;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!dispatchDefectAttachmentUploadUrl)
                        return [2 /*return*/];
                    return [4 /*yield*/, dispatchDefectAttachmentUploadUrl({
                            query: defectUploadUrlArgs,
                        })];
                case 1:
                    result = _a.sent();
                    if (!result.isSuccess) {
                        message = "".concat(defectUploadUrlArgs.originalFileName, "::") + result.err.message;
                        showCommonErrorNotification({ message: message });
                        return [2 /*return*/];
                    }
                    return [2 /*return*/, result.body];
            }
        });
    }); }, [dispatchDefectAttachmentUploadUrl]);
    var handleReUploadDefectAttachmentFile = function (defectAttachmentId, file) { return __awaiter(void 0, void 0, void 0, function () {
        var newSignedUrl, uploadUrl, compressedFile, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    newSignedUrl = false;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, , 6]);
                    return [4 /*yield*/, getDefectAttachmentReUploadInfo(defectAttachmentId)];
                case 2:
                    uploadUrl = _a.sent();
                    if (uploadUrl === undefined) {
                        return [2 /*return*/, newSignedUrl];
                    }
                    return [4 /*yield*/, compressImage(file)];
                case 3:
                    compressedFile = _a.sent();
                    return [4 /*yield*/, putOriginalFile({ file: compressedFile, uploadUrl: uploadUrl.signedUrl })];
                case 4:
                    _a.sent();
                    newSignedUrl = true;
                    return [3 /*break*/, 6];
                case 5:
                    e_2 = _a.sent();
                    showCommonErrorNotification(e_2);
                    sendError(e_2);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/, newSignedUrl];
            }
        });
    }); };
    var getDefectAttachmentReUploadInfo = useCallback(function (defectAttachmentId) { return __awaiter(void 0, void 0, void 0, function () {
        var result, message;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!dispatchDefectAttachmentReUploadUrl)
                        return [2 /*return*/];
                    return [4 /*yield*/, dispatchDefectAttachmentReUploadUrl({
                            params: [defectAttachmentId, 'reupload-url'],
                        })];
                case 1:
                    result = _a.sent();
                    if (!result.isSuccess) {
                        message = result.err.message;
                        showCommonErrorNotification({ message: message });
                        return [2 /*return*/];
                    }
                    return [2 /*return*/, result.body];
            }
        });
    }); }, [dispatchDefectAttachmentReUploadUrl]);
    return {
        handleUploadDefectAttachmentFile: handleUploadDefectAttachmentFile,
        handleReUploadDefectAttachmentFile: handleReUploadDefectAttachmentFile,
        getDefectAttachmentUploadInfo: getDefectAttachmentUploadInfo,
    };
};
