var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from 'styled-components';
import { Menubar } from './Menubar';
import colors from '../../shared/colors';
import { useAppI18n } from '../../contexts/AppI18nContext';
import { ABILITY_ACTION, MOBILE_NORMAL_WIDTH } from '../../shared/constants';
import { useNavigate } from 'react-router-dom';
import { ROUTE_NOT_AUTHORIZED, ROUTE_UNDER_MAINTENANCE, ROUTE_DOCUMENT_PERMALINK, ROUTE_SERVICE_UNAVAILABLE, } from '../../shared/urls';
import { useEffect, useState } from 'react';
import { Layout as LayoutAntd, Col, Row } from 'antd';
import { MenuSidebar } from './MenuSidebar';
import { useApp } from '../../contexts/AppContext';
import { isSiteAdmin } from '../../shared/helpers';
var Header = LayoutAntd.Header, Content = LayoutAntd.Content;
var StyledHeader = styled(Header)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: sticky;\n  top: 0;\n  z-index: 1;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  padding: 0;\n"], ["\n  position: sticky;\n  top: 0;\n  z-index: 1;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  padding: 0;\n"])));
var Main = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  box-sizing: border-box;\n  background: ", ";\n"], ["\n  box-sizing: border-box;\n  background: ", ";\n"])), colors.white);
var Layout = function (_a) {
    var children = _a.children;
    var _b = useApp(), user = _b.user, currentRoute = _b.currentRoute;
    var navigate = useNavigate();
    var _c = useState(false), isShowMenuBar = _c[0], setIsShowMenuBar = _c[1];
    var layout = useAppI18n().layout;
    var isMobile = layout.isMobileLayoutOnPC || layout.isMobileScreenSize || layout.isMobileLayout;
    var isLayoutSiteAdmin = isSiteAdmin(currentRoute || '');
    // Check and Redirect to page not authorized
    useEffect(function () {
        if (!user)
            return;
        if (!currentRoute || user.ability.cannot(ABILITY_ACTION.ACCESS, currentRoute)) {
            navigate(ROUTE_NOT_AUTHORIZED, { replace: true });
        }
        var isNeedMenuBar = ![
            ROUTE_UNDER_MAINTENANCE,
            ROUTE_SERVICE_UNAVAILABLE,
            ROUTE_NOT_AUTHORIZED,
            ROUTE_DOCUMENT_PERMALINK,
        ].includes(currentRoute || '');
        setIsShowMenuBar(isNeedMenuBar);
    }, [user, currentRoute]);
    return (_jsx(LayoutAntd, { style: isMobile ? { maxWidth: "".concat(MOBILE_NORMAL_WIDTH, "px"), margin: '0 auto' } : {}, children: isLayoutSiteAdmin ? (_jsxs(_Fragment, { children: [_jsx(StyledHeader, { children: _jsx(Menubar, { isShowMenuBar: true, isShowMenuItem: isMobile }) }), _jsxs(Row, { children: [_jsx(Col, { span: 4, style: { backgroundColor: '#111e34', display: isMobile ? 'none' : undefined }, children: _jsx(MenuSidebar, {}) }), _jsx(Col, { span: isMobile ? 24 : 20, children: _jsx(Main, { children: children }) })] })] })) : (_jsxs(_Fragment, { children: [_jsx(StyledHeader, { children: _jsx(Menubar, { isShowMenuBar: isShowMenuBar }) }), _jsx(Content, { className: "site-layout", children: children })] })) }));
};
var TwoColumnContentWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: ", ";\n  width: 100%;\n\n  > *:nth-child(1) {\n    width: 100%;\n  }\n  > *:nth-child(2) {\n    width: 300px;\n    flex-shrink: 0;\n  }\n"], ["\n  display: flex;\n  flex-direction: ", ";\n  width: 100%;\n\n  > *:nth-child(1) {\n    width: 100%;\n  }\n  > *:nth-child(2) {\n    width: 300px;\n    flex-shrink: 0;\n  }\n"])), function (props) { var _a; return (_a = props.direction) !== null && _a !== void 0 ? _a : 'row'; });
var TwoColumnContent = function (_a) {
    var direction = _a.direction, children = _a.children;
    return _jsx(TwoColumnContentWrapper, { direction: direction, children: children });
};
export { Layout, TwoColumnContent };
var templateObject_1, templateObject_2, templateObject_3;
