var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Col, Dropdown, Row, Input } from 'antd';
import _toNumber from 'lodash/toNumber';
import { useTranslation } from 'react-i18next';
import icoArrowPrevious from '../icons/arrow-previous.svg';
import icoArrowNext from '../icons/arrow-next.svg';
import icoArrowDown from '../icons/arrow-dropdown.svg';
import { PAGINATION_CHANGE_SIZE_ITEM } from '../../shared/constants';
import styled from 'styled-components';
var RowStyle = styled(Row)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 12px;\n  margin-bottom: 20px;\n  background: white;\n  @media (max-width: 1024px) {\n    margin-bottom: 90px;\n  }\n"], ["\n  font-size: 12px;\n  margin-bottom: 20px;\n  background: white;\n  @media (max-width: 1024px) {\n    margin-bottom: 90px;\n  }\n"])));
var RowMobileStyle = styled(Row)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 10px;\n\n  .ant-pagination-simple {\n    justify-content: center;\n  }\n\n  .ant-pagination-simple-pager {\n    width: 160px;\n    text-align: center;\n    padding-top: 5px;\n  }\n"], ["\n  padding: 10px;\n\n  .ant-pagination-simple {\n    justify-content: center;\n  }\n\n  .ant-pagination-simple-pager {\n    width: 160px;\n    text-align: center;\n    padding-top: 5px;\n  }\n"])));
var PaginationCustom = function (props) {
    var _a;
    var pagination = props.pagination, _b = props.isMobile, isMobile = _b === void 0 ? false : _b;
    var t = useTranslation('components', { keyPrefix: 'commons' }).t;
    var nextPage = 2;
    var allowNext = true;
    var previousPage = 1;
    var allowPrevious = true;
    if (pagination.size * pagination.page >= pagination.total) {
        allowNext = false;
        allowPrevious = false;
        if (pagination.page > 1) {
            allowPrevious = true;
        }
    }
    else if (pagination.page <= 1) {
        allowPrevious = false;
    }
    if (pagination.page > 1) {
        previousPage = pagination.page - 1;
    }
    if (pagination.size * pagination.page < pagination.total) {
        nextPage = pagination.page + 1;
    }
    var paginationChangeSizeConfig = ((_a = props.paginationChangeSizeConfig) === null || _a === void 0 ? void 0 : _a.length)
        ? props.paginationChangeSizeConfig
        : PAGINATION_CHANGE_SIZE_ITEM;
    var dropdownItems = [];
    for (var index = 0; index < paginationChangeSizeConfig.length; index++) {
        dropdownItems.push({
            label: paginationChangeSizeConfig[index],
            key: "".concat(paginationChangeSizeConfig[index]),
        });
    }
    var changeSizeItems = dropdownItems;
    var handleMenuClick = function (e) {
        props.sizeChange(_toNumber(e.key) || paginationChangeSizeConfig[0]);
    };
    var menuProps = {
        items: changeSizeItems,
        onClick: handleMenuClick,
        selectedKeys: ["".concat(pagination.size)],
    };
    var setNextPage = function () {
        props.nextPage(nextPage);
    };
    var setPreviousPage = function () {
        props.previousPage(previousPage);
    };
    var pageFrom = (pagination.page - 1) * pagination.size + 1;
    var pageTo = pagination.page * pagination.size;
    var renderMobileMode = function () { return (_jsx(_Fragment, { children: _jsx(RowMobileStyle, { children: _jsx(Col, { span: 24, children: _jsxs("ul", { className: "ant-pagination ant-pagination-simple ant-table-pagination ant-table-pagination-center", children: [_jsx("li", { title: "Previous Page", className: "ant-pagination-prev", children: _jsx(Button, { type: "text", shape: "circle", style: {
                                    boxShadow: '0 0 0 rgb(0 0 0 / 2%)',
                                    width: '35px',
                                    height: '35px',
                                    lineHeight: '0',
                                    opacity: allowPrevious ? '1' : '0.3',
                                    cursor: allowPrevious ? 'pointer' : 'default',
                                }, disabled: !allowPrevious, onClick: setPreviousPage, children: _jsx("img", { src: icoArrowPrevious, alt: 'previous', style: { cursor: 'pointer' } }) }) }), _jsxs("li", { className: "ant-pagination-simple-pager", title: "".concat(pagination.page, "/").concat(pagination.total), children: [_jsx(Input, { size: "small", value: pagination.page, style: { width: '40px' }, onChange: function (e) {
                                        props.nextPage(e.target.value);
                                    } }), _jsx("span", { className: "ant-pagination-slash", children: " / " }), pagination.total] }), _jsx("li", { title: "Next Page", className: "ant-pagination-next", children: _jsx(Button, { type: "text", shape: "circle", style: {
                                    boxShadow: '0 0 0 rgb(0 0 0 / 2%)',
                                    width: '35px',
                                    height: '35px',
                                    lineHeight: '0',
                                    opacity: allowNext ? '1' : '0.3',
                                    cursor: allowNext ? 'pointer' : 'default',
                                }, disabled: !allowNext, onClick: setNextPage, children: _jsx("img", { src: icoArrowNext, alt: 'next' }) }) })] }) }) }) })); };
    var renderPCMode = function () { return (_jsx(RowStyle, { align: "middle", justify: "end", wrap: false, children: _jsxs(Col, { children: [_jsxs("div", { style: { display: 'inline-block' }, children: [_jsx("span", { children: t('pagination.pageSize') }), ' ', _jsx(Dropdown, { menu: menuProps, placement: "bottomLeft", trigger: ['click'], children: _jsxs("span", { style: { cursor: 'pointer' }, children: [pagination.size, " ", _jsx("img", { src: icoArrowDown, alt: "" })] }) })] }), _jsxs("div", { style: { display: 'inline-block', paddingLeft: '30px', paddingRight: '30px' }, children: [pageFrom, "-", pageTo, " of ", pagination.total] }), _jsx(Button, { type: "text", shape: "circle", style: {
                        boxShadow: '0 0 0 rgb(0 0 0 / 2%)',
                        width: '35px',
                        height: '35px',
                        lineHeight: '0',
                        opacity: allowPrevious ? '1' : '0.3',
                        cursor: allowPrevious ? 'pointer' : 'default',
                    }, disabled: !allowPrevious, onClick: setPreviousPage, children: _jsx("img", { src: icoArrowPrevious, alt: 'previous', style: { cursor: 'pointer' } }) }), _jsx(Button, { type: "text", shape: "circle", style: {
                        boxShadow: '0 0 0 rgb(0 0 0 / 2%)',
                        width: '35px',
                        height: '35px',
                        lineHeight: '0',
                        opacity: allowNext ? '1' : '0.3',
                        cursor: allowNext ? 'pointer' : 'default',
                    }, disabled: !allowNext, onClick: setNextPage, children: _jsx("img", { src: icoArrowNext, alt: 'next' }) })] }) })); };
    return isMobile ? renderMobileMode() : renderPCMode();
};
export { PaginationCustom };
var templateObject_1, templateObject_2;
