import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { List, Badge, Typography } from 'antd';
import { date } from '../../shared/date';
import { NotificationResourceType, } from '../../shared/types';
import { useTranslation } from 'react-i18next';
import { ROUTE_DOCUMENT_PERMALINK } from '../../shared/urls';
import { generatePath } from 'react-router-dom';
import { useAppState } from '../../contexts/AppStateContext';
import { convertNotificationData } from '../../shared/helpers';
import { Link } from 'react-router-dom';
import { useAppI18n } from '../../contexts/AppI18nContext';
var NotificationItem = function (_a) {
    var item = _a.item;
    var _b = useAppState(), listSupplier = _b.listSupplier, tags = _b.tags;
    var language = useAppI18n().language;
    var tNoti = useTranslation('components', { keyPrefix: 'notification' }).t;
    var tCommon = useTranslation('components', { keyPrefix: 'commons' }).t;
    var renderNotificationTitle = function () {
        if (item.metadata.resourceType !== NotificationResourceType.Document)
            return _jsx(_Fragment, {});
        var entity = item.metadata.data.after;
        var openUrl = generatePath(ROUTE_DOCUMENT_PERMALINK, { documentId: entity.documentId });
        return (_jsx(Link, { to: openUrl, target: "_blank", rel: "noreferrer", children: _jsxs("h5", { children: ["[", tNoti("resourceType.".concat(item.metadata.resourceType)), "] ", entity.title] }) }));
    };
    var renderNotificationDescription = function () {
        if (item.metadata.resourceType !== NotificationResourceType.Document)
            return _jsx(_Fragment, {});
        return (_jsx(Typography.Text, { type: "secondary", children: tNoti("eventAction.".concat(item.metadata.event), {
                data: convertNotificationData(item, listSupplier, tags, language),
            }) }));
    };
    return (_jsx(List.Item, { children: _jsx(List.Item.Meta, { title: renderNotificationTitle(), description: _jsxs(_Fragment, { children: [_jsx("div", { children: renderNotificationDescription() }), _jsx(Badge, { dot: !item.isRead, offset: [175, 5], children: _jsx("small", { children: date.format(item.createdAt, tCommon('format.YYYYMMDDHHmm')) }) })] }) }) }, 'Email'));
};
export default NotificationItem;
