var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint max-lines: [error, 224] */
import styled from 'styled-components';
import { Header } from '../commons';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Col, Row } from 'antd';
import { SearchLogs } from './SearchLogs';
import { LogsList } from './LogsList';
import { ABILITY_ACTION } from '../../shared/constants';
import '../InspectionResult/Pagination.css';
import { PageNotFound } from '../commons/PageNotFound';
import { useApp } from '../../contexts/AppContext';
import { ROUTE_LOGS_LIST } from '../../shared/urls';
import { CustomPageHeader } from '../commons/CustomPageHeader';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-top: 20px;\n  height: calc(100vh - 100px);\n  .ant-table-pagination {\n    display: flex;\n  }\n"], ["\n  padding-top: 20px;\n  height: calc(100vh - 100px);\n  .ant-table-pagination {\n    display: flex;\n  }\n"])));
var Logs = function () {
    var _a = useState(''), keywordSearch = _a[0], setKeywordSearch = _a[1];
    var _b = useState(''), searchGroup = _b[0], setSearchGroup = _b[1];
    var _c = useState(''), searchAction = _c[0], setSearchAction = _c[1];
    var user = useApp().user;
    var t = useTranslation('components', { keyPrefix: 'logs' }).t;
    var _d = useState(false), isPageLoading = _d[0], setIsPageLoading = _d[1];
    var onSearchingHandler = function (keyword, group, action) {
        setKeywordSearch(keyword);
        setSearchGroup(group);
        setSearchAction(action);
    };
    var onProgressedHandler = function () {
        setIsPageLoading(false);
    };
    var onLoadingHandler = function () {
        setIsPageLoading(true);
    };
    return (_jsx(_Fragment, { children: !user || !user.ability.can(ABILITY_ACTION.ACCESS, ROUTE_LOGS_LIST) ? (_jsx(PageNotFound, {})) : (_jsxs(_Fragment, { children: [_jsx(Header, { children: _jsx(CustomPageHeader, { columnQuantity: 1, adminText: t('header'), isAdminSide: true }) }), _jsxs(Container, { children: [_jsx(Row, { style: { marginBottom: '20px' }, children: _jsx(Col, { span: 24, children: _jsx(SearchLogs, { isPageLoading: isPageLoading, onSearching: onSearchingHandler }) }) }), _jsx(LogsList, { onLoading: onLoadingHandler, onProgressed: onProgressedHandler, keywordSearch: keywordSearch, searchGroup: searchGroup, searchAction: searchAction, isPageLoading: isPageLoading })] })] })) }));
};
export default Logs;
var templateObject_1;
