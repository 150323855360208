var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { showCommonErrorNotification } from './logs';
import { IMAGE_MAX_COMPRESS_SIZE_MB, UPLOAD_IMAGE_FILE_SIZE_MB_MAX, UPLOAD_PDF_FILE_SIZE_MB_MAX, UPLOAD_EXCEL_FILE_SIZE_MB_MAX, UPLOAD_WORD_FILE_SIZE_MB_MAX, UPLOAD_ZIP_FILE_SIZE_MB_MAX, } from './constants';
import { validateFileSize } from './validateFiles';
import { compressAccurately } from 'image-conversion';
import pdfIcon from '../components/icons/pdf-icon.svg';
import jpegIcon from '../components/icons/jpeg.svg';
import pngIcon from '../components/icons/png.svg';
import jpgIcon from '../components/icons/jpg.svg';
import excelIcon from '../components/icons/excel-icon.svg';
import wordIcon from '../components/icons/word-icon.svg';
import zipIcon from '../components/icons/zip-icon.svg';
import _toLower from 'lodash/toLower';
export var getBase64 = function (file) { return __awaiter(void 0, void 0, void 0, function () {
    var e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                if (file === null)
                    return [2 /*return*/, Promise.resolve('')];
                return [4 /*yield*/, new Promise(function (resolve, reject) {
                        var reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = function () { return resolve(reader.result); };
                        reader.onerror = function (error) { return reject(error); };
                    })];
            case 1: return [2 /*return*/, _a.sent()];
            case 2:
                e_1 = _a.sent();
                showCommonErrorNotification({ message: e_1.message });
                return [2 /*return*/, Promise.resolve('')];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var pdfBase64toBlob = function (data) {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    var base64WithoutPrefix = data.substring('data:application/pdf;base64,'.length);
    var bytes = atob(base64WithoutPrefix);
    var length = bytes.length;
    var out = new Uint8Array(length);
    while (length--) {
        out[length] = bytes.charCodeAt(length);
    }
    return new Blob([out], { type: 'application/pdf' });
};
export var dataURLtoFile = function (dataUrl, fileName, contentType) {
    if (contentType === void 0) { contentType = INSPECTION_PHOTO_MIME_TYPES.PNG; }
    var arr = dataUrl.split(',');
    var bstr = window.atob(arr[1]);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: contentType });
};
export var compressImage = function (file) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (file.type === INSPECTION_PHOTO_MIME_TYPES.PDF)
                    return [2 /*return*/, file];
                if (validateFileSize(file.size, IMAGE_MAX_COMPRESS_SIZE_MB))
                    return [2 /*return*/, file];
                return [4 /*yield*/, compressAccurately(file, {
                        size: IMAGE_MAX_COMPRESS_SIZE_MB * 1024, // in kb
                    })
                        .then(function (blob) { return new File([blob], file.name, { type: file.type }); })
                        .catch(function () { return file; })];
            case 1: 
            // Compress file to specific size
            return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getFileTypeFromFileName = function (fileName) {
    fileName = _toLower(fileName);
    return fileName.split('.').pop();
};
export var getMaxFileSizeMbByFileType = function (type) {
    var maxFileSizeMb = UPLOAD_IMAGE_FILE_SIZE_MB_MAX;
    switch (type) {
        case FILE_MIME_TYPES.PDF:
            maxFileSizeMb = UPLOAD_PDF_FILE_SIZE_MB_MAX;
            break;
        case FILE_MIME_TYPES.XLS:
        case FILE_MIME_TYPES.XLSX:
            maxFileSizeMb = UPLOAD_EXCEL_FILE_SIZE_MB_MAX;
            break;
        case FILE_MIME_TYPES.DOC:
        case FILE_MIME_TYPES.DOCX:
            maxFileSizeMb = UPLOAD_WORD_FILE_SIZE_MB_MAX;
            break;
        case FILE_MIME_TYPES.ZIP:
            maxFileSizeMb = UPLOAD_ZIP_FILE_SIZE_MB_MAX;
            break;
    }
    return maxFileSizeMb;
};
export var getFileIconByFileType = function (fileName) {
    var type = getFileTypeFromFileName(fileName);
    switch (type) {
        case FILE_TYPES.XLS:
        case FILE_TYPES.XLSX:
            return excelIcon;
        case FILE_TYPES.DOC:
        case FILE_TYPES.DOCX:
            return wordIcon;
        case FILE_TYPES.ZIP:
            return zipIcon;
        case FILE_TYPES.JPEG:
            return jpegIcon;
        case FILE_TYPES.PNG:
            return pngIcon;
        case FILE_TYPES.JPG:
            return jpgIcon;
        case FILE_TYPES.PDF:
            return pdfIcon;
        default:
            return jpegIcon;
    }
};
export var isImage = function (fileName) {
    var type = getFileTypeFromFileName(fileName);
    var isImage = false;
    switch (type) {
        case FILE_TYPES.JPEG:
        case FILE_TYPES.PNG:
        case FILE_TYPES.JPG:
            return true;
    }
    return isImage;
};
export var blobToBase64 = function (blob) {
    var reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(function (resolve) {
        reader.onloadend = function () {
            resolve(reader.result);
        };
    });
};
export var INSPECTION_PHOTO_MIME_TYPES = {
    PNG: 'image/png',
    JPG: 'image/jpg',
    JPEG: 'image/jpeg',
    PDF: 'application/pdf',
};
export var DOCUMENT_MIME_TYPES = {
    PDF: 'application/pdf',
    XLS: 'application/vnd.ms-excel',
    XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    DOC: 'application/msword',
    DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
};
export var FILE_MIME_TYPES = {
    PDF: 'application/pdf',
    XLS: 'application/vnd.ms-excel',
    XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    DOC: 'application/msword',
    DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    PNG: 'image/png',
    JPG: 'image/jpg',
    JPEG: 'image/jpeg',
    ZIP: 'application/zip',
};
export var FILE_TYPES = {
    PNG: 'png',
    JPG: 'jpg',
    JPEG: 'jpeg',
    PDF: 'pdf',
    XLS: 'xls',
    XLSX: 'xlsx',
    DOC: 'doc',
    DOCX: 'docx',
    ZIP: 'zip',
};
export var DOCUMENT_TYPES = {
    PDF: 'pdf',
    XLS: 'xls',
    XLSX: 'xlsx',
    DOC: 'doc',
    DOCX: 'docx',
    ZIP: 'zip',
};
export var IMPORT_CSV_MIME_TYPES = {
    CSV: 'text/csv',
};
export var IMPORT_CSV_TYPES = {
    CSV: 'csv',
};
export var INSPECTION_FILE_ACCEPT = '.jpg, .jpeg, .png, .pdf';
export var DOCUMENT_FILE_ACCEPT = '.pdf, .xls, .xlsx, .doc, .docx';
export var DEFECT_ATTACHMENT_FILE_ACCEPT = '.pdf, .xls, .xlsx, .doc, .docx, .jpg, .jpeg, .png, .zip';
export var COMMENT_ATTACHMENT_FILE_ACCEPT = '.pdf, .xls, .xlsx, .doc, .docx, .jpg, .jpeg, .png';
export var UPLOAD_FILE_ACCEPT = INSPECTION_FILE_ACCEPT;
export var UPLOAD_PHOTO_MIME_TYPES = INSPECTION_PHOTO_MIME_TYPES;
export var MAX_UPLOAD_COUNT = 50;
export var MAX_PREVIEW_UPLOAD_COUNT = 10;
