import { useAuth0 } from '@auth0/auth0-react';
import { localLanguageStorage, localTimezoneStorage, localUserInterfaceStorage, } from '../../i18n/storage';
export var useSave = function (_a) {
    var currentLanguage = _a.currentLanguage, currentTimezone = _a.currentTimezone, currentUserInterface = _a.currentUserInterface, language = _a.language, timezone = _a.timezone, userInterface = _a.userInterface;
    var user = useAuth0().user;
    var saveHandler = function () {
        if (!(user === null || user === void 0 ? void 0 : user.email))
            return;
        if (language !== undefined && language !== currentLanguage) {
            localLanguageStorage.set(user.email, language);
        }
        if (timezone !== undefined && timezone !== currentTimezone) {
            localTimezoneStorage.set(user.email, timezone);
        }
        if (userInterface !== undefined && userInterface !== currentUserInterface) {
            localUserInterfaceStorage.set(user.email, userInterface);
        }
        window.location.reload();
    };
    var canSave = currentLanguage !== language ||
        currentTimezone !== timezone ||
        currentUserInterface !== userInterface;
    return {
        saveHandler: saveHandler,
        canSave: canSave,
    };
};
