export var ExportedFileStatus;
(function (ExportedFileStatus) {
    ExportedFileStatus["Exporting"] = "exporting";
    ExportedFileStatus["Finished"] = "finished";
    ExportedFileStatus["Failed"] = "failed";
})(ExportedFileStatus || (ExportedFileStatus = {}));
export var ExportedBy;
(function (ExportedBy) {
    ExportedBy["All"] = "all";
    ExportedBy["Me"] = "me";
})(ExportedBy || (ExportedBy = {}));
// Shipping, Supplier will be supported in future
export var ExportedFileType;
(function (ExportedFileType) {
    ExportedFileType["Inspection"] = "inspection";
})(ExportedFileType || (ExportedFileType = {}));
