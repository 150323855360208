var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
import i18n, { t } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { DEFAULT_LANGUAGE, DEFAULT_NAMESPACE } from '../constants';
import { resources } from '../resources';
import { SHIPPING_SECTION_AND_SEQUENCE } from '../../shared/constants';
import { SectionType } from '../../shared/types';
import { ROUTE_ADMIN_DOCUMENT_LIST } from '../../shared/urls';
import { generatePath } from 'react-router-dom';
export var options = {
    defaultNS: DEFAULT_NAMESPACE,
    lng: DEFAULT_LANGUAGE,
    ns: Object.keys(resources[DEFAULT_LANGUAGE]),
    fallbackLng: false,
    interpolation: {
        escapeValue: false,
    },
};
/**
 * References https://react.i18next.com/getting-started#basic-sample
 */
i18n.use(initReactI18next).init(__assign(__assign({}, options), { resources: resources }));
(_a = i18n.services.formatter) === null || _a === void 0 ? void 0 : _a.add('uppercase', function (value) { return (value ? String(value).toUpperCase() : value); });
(_b = i18n.services.formatter) === null || _b === void 0 ? void 0 : _b.add('lowercase', function (value) { return (value ? String(value).toLowerCase() : value); });
(_c = i18n.services.formatter) === null || _c === void 0 ? void 0 : _c.add('capitalize', function (value) {
    return value ? "".concat(String(value).substring(0, 1).toUpperCase()).concat(String(value).substring(1)) : value;
});
(_d = i18n.services.formatter) === null || _d === void 0 ? void 0 : _d.add('photoType', function (value) {
    return value ? t("common:photoType.".concat(value)) : value;
});
(_e = i18n.services.formatter) === null || _e === void 0 ? void 0 : _e.add('inspectionStyle', function (value) {
    return value ? t("components:supplierStyle.style.".concat(value)) : value;
});
(_f = i18n.services.formatter) === null || _f === void 0 ? void 0 : _f.add('shippingSectionType', function (value) {
    return value.indexOf(SHIPPING_SECTION_AND_SEQUENCE.CONTAINER_LOADING) !== -1
        ? t("common:shippingSectionType.".concat(value))
        : "".concat(t("common:shippingSectionType.".concat(SectionType.BUNDLE)), " ").concat(parseInt(value.split('_')[1]));
});
(_g = i18n.services.formatter) === null || _g === void 0 ? void 0 : _g.add('shippingStatus', function (value) {
    return value ? t("components:shipping.status.".concat(value.toUpperCase())) : value;
});
(_h = i18n.services.formatter) === null || _h === void 0 ? void 0 : _h.add('inspectionStatus', function (value) {
    return value ? t("components:inspectionResult.InspectionResult.status.".concat(value)) : value;
});
(_j = i18n.services.formatter) === null || _j === void 0 ? void 0 : _j.add('roleName', function (value) {
    return value ? t("components:commons.userRoles.".concat(value)) : "".concat(value);
});
(_k = i18n.services.formatter) === null || _k === void 0 ? void 0 : _k.add('nullFormatter', function (value) { return value || 'null'; }); // Case undefined and empty string transform to null
(_l = i18n.services.formatter) === null || _l === void 0 ? void 0 : _l.add('escapeHtml', function (value) {
    return value
        ? value
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#39;')
        : 'null';
});
(_m = i18n.services.formatter) === null || _m === void 0 ? void 0 : _m.add('enabledKleinOrderStatus', function (value) {
    return value
        ? t("components:supplierStyle.list.title.ON")
        : t("components:supplierStyle.list.title.OFF");
});
(_o = i18n.services.formatter) === null || _o === void 0 ? void 0 : _o.add('documentAccess', function (value) {
    return value ? t("components:document.access.".concat(value)) : value;
});
(_p = i18n.services.formatter) === null || _p === void 0 ? void 0 : _p.add('documentLanguage', function (values) {
    if (values === undefined || values === null)
        return '';
    if (values.length === 0)
        return '';
    else {
        var strArr = values.map(function (value) { return t("components:commons.language.value.".concat(value)); });
        return strArr.join();
    }
});
(_q = i18n.services.formatter) === null || _q === void 0 ? void 0 : _q.add('documentBusinessSection', function (values) {
    if (values.length === 0)
        return '';
    else {
        var strArr = values.map(function (value) {
            return t("components:commons.businessSection.value.".concat(value));
        });
        return strArr.join();
    }
});
(_r = i18n.services.formatter) === null || _r === void 0 ? void 0 : _r.add('documentAdminLink', function (documentId) {
    return generatePath(ROUTE_ADMIN_DOCUMENT_LIST) + "?search=".concat(documentId);
});
(_s = i18n.services.formatter) === null || _s === void 0 ? void 0 : _s.add('stringify', function (object) {
    return JSON.stringify(object, undefined, 0);
});
export default i18n;
