var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable max-lines */
import { useState } from 'react';
import { Col, Input, Row, Space, Button, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { showCommonErrorNotification } from '../../shared/logs';
import icoSearch from '../icons/ico-search.svg';
import { useAppI18n } from '../../contexts/AppI18nContext';
import { LOGS_ACTION, LOGS_GROUP, IPAD_MAX_WIDTH } from '../../shared/constants';
import { InfoCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
var SpaceStyle = styled(Space)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: ", ";\n  padding-left: 10px;\n  padding-right: 10px;\n\n  @media (max-width: ", "px) {\n    .ant-select-clear svg {\n      width: 20px;\n      height: 20px;\n      z-index: 9999;\n      margin-top: -4px;\n    }\n  }\n"], ["\n  width: ", ";\n  padding-left: 10px;\n  padding-right: 10px;\n\n  @media (max-width: ", "px) {\n    .ant-select-clear svg {\n      width: 20px;\n      height: 20px;\n      z-index: 9999;\n      margin-top: -4px;\n    }\n  }\n"])), function (props) { return props.width; }, IPAD_MAX_WIDTH);
export var SearchLogs = function (props) {
    var t = useTranslation('components', { keyPrefix: 'inspectionResult.InspectionResult' }).t;
    var tLogs = useTranslation('components', { keyPrefix: 'logs' }).t;
    var _a = useState(''), keywordSearch = _a[0], setKeywordSearch = _a[1];
    var _b = useState(''), searchGroup = _b[0], setSearchGroup = _b[1];
    var _c = useState(''), searchAction = _c[0], setSearchAction = _c[1];
    var layout = useAppI18n().layout;
    var isMobile = layout.isMobileLayoutOnPC || layout.isMobileScreenSize;
    var isiPad = layout.isiPadScreenSize;
    var handleSubmitSearch = function () {
        if (keywordSearch.length <= 0) {
            showCommonErrorNotification({
                message: tLogs('error.validate.keywordRequired'),
            });
            return;
        }
        if (props.onSearching)
            props.onSearching(keywordSearch, searchGroup, searchAction);
    };
    var handleAllowForceSearch = function () {
        setKeywordSearch('');
        setSearchGroup(searchGroup);
        setSearchAction(searchAction);
        if (props.onSearching)
            props.onSearching('', searchGroup, searchAction);
    };
    return (_jsx(_Fragment, { children: _jsx(SpaceStyle, { width: layout.isMobileScreenSize ? 'calc(100% - 20px)' : '100%', direction: "vertical", children: _jsx(Row, { align: "middle", wrap: false, children: _jsx(Col, { span: 24, children: _jsxs("div", { style: {
                            position: 'relative',
                            border: '1px solid #d9d9d9',
                            borderRadius: '5px',
                            padding: '15px 20px',
                            background: 'white',
                            display: 'inline-block',
                            width: isMobile || isiPad ? '100%' : '75%',
                        }, children: [_jsx("h4", { style: {
                                    position: 'absolute',
                                    display: 'inline',
                                    color: '#d9d9d9',
                                    fontSize: '90%',
                                    background: 'white',
                                    padding: '0 5px',
                                    left: '10px',
                                    top: '-10px',
                                }, children: tLogs('search.title') }), _jsxs(Space.Compact, { style: { width: '100%' }, children: [!isMobile && (_jsxs(_Fragment, { children: [_jsx(Select, { placeholder: tLogs('search.placeholderGroup'), onChange: function (value) {
                                                    setSearchGroup(value);
                                                    if (props.onSearching)
                                                        props.onSearching(keywordSearch, value, searchAction);
                                                }, options: Object.values(LOGS_GROUP).map(function (value) {
                                                    return { value: value, label: tLogs("listData.group.".concat(value)) };
                                                }), style: { minWidth: '200px' }, allowClear: true }), _jsx(Select, { placeholder: tLogs('search.placeholderAction'), onChange: function (value) {
                                                    setSearchAction(value);
                                                    if (props.onSearching)
                                                        props.onSearching(keywordSearch, searchGroup, value);
                                                }, options: Object.values(LOGS_ACTION).map(function (value) {
                                                    return { value: value, label: tLogs("listData.action.".concat(value)) };
                                                }), style: { minWidth: '360px' }, allowClear: true })] })), _jsx(Input, { onChange: function (e) {
                                            setKeywordSearch(e.target.value);
                                            if (e.target.value.length <= 0) {
                                                handleAllowForceSearch();
                                            }
                                        }, onPressEnter: handleSubmitSearch, placeholder: tLogs('search.placeholder'), allowClear: true, defaultValue: keywordSearch, value: keywordSearch, "data-testid": "txt-keyword-search-logs" }), _jsx(Button, { loading: props.isPageLoading, onClick: handleSubmitSearch, icon: _jsx("img", { src: icoSearch, width: 14, alt: t('search.topicSearch') }), "data-testid": "btn-search-logs" })] }), _jsx(Row, { wrap: false, justify: "end", style: { marginTop: '10px', color: '#999' }, children: _jsx(Col, { children: _jsxs("i", { children: [_jsx(InfoCircleOutlined, { style: { marginRight: '5px' } }), tLogs('search.noteHowToSearchFulltext')] }) }) }), isMobile && (_jsxs(_Fragment, { children: [_jsx(Space.Compact, { style: { width: '100%', marginTop: '10px' }, children: _jsx(Select, { placeholder: tLogs('search.placeholderGroup'), onChange: function (value) {
                                                setSearchGroup(value);
                                                if (props.onSearching)
                                                    props.onSearching(keywordSearch, value, searchAction);
                                            }, options: Object.values(LOGS_GROUP).map(function (value) {
                                                return { value: value, label: tLogs("listData.group.".concat(value)) };
                                            }), allowClear: true, style: { width: '100%' } }) }), _jsx(Space.Compact, { style: { width: '100%', marginTop: '10px' }, children: _jsx(Select, { placeholder: tLogs('search.placeholderAction'), onChange: function (value) {
                                                setSearchAction(value);
                                                if (props.onSearching)
                                                    props.onSearching(keywordSearch, searchGroup, value);
                                            }, options: Object.values(LOGS_ACTION).map(function (value) {
                                                return { value: value, label: tLogs("listData.action.".concat(value)) };
                                            }), allowClear: true, style: { width: '100%' } }) })] }))] }) }) }) }) }));
};
var templateObject_1;
