import { AbilityBuilder, PureAbility } from '@casl/ability';
import { AUTH0_METADATA, ABILITY_ACTION, AUTH_ROLES, DISABLED_FEATURES, NAMESPACE_AUTH0, REGEX_AUTH_GOOGLE_SOCIAL_CONNECTION, USER_ACCOUNT_ROLES, ABILITY_SUBJECT, INSPECTION_TRANSITION_STATUS, SHIPPING_TRANSITION_STATUS, DOCUMENT_COMMENT_THREAD, } from '../shared/constants';
import { COMMON_ROUTES, ADMIN_ROUTES, LIMITED_SUPER_ADMIN_ROUTES, } from '../shared/urls';
import { checkDisableFeatures } from '../shared/helpers';
import _clone from 'lodash/clone';
import _uniq from 'lodash/uniq';
export var fieldMatcher = function (fields) { return function (field) { return fields.includes(field); }; };
var rolePermissions = {
    supplier: function (user, _a) {
        var can = _a.can, cannot = _a.cannot;
        COMMON_ROUTES.map(function (route) { return can(ABILITY_ACTION.ACCESS, route); });
        cannot(ABILITY_ACTION.READ, ABILITY_SUBJECT.INSPECTION, [
            'sku',
            'transactionId',
            'projectName',
        ]);
        can(ABILITY_ACTION.READ, ABILITY_SUBJECT.INSPECTOR_COMPANY);
        can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.INSPECTOR_COMPANY);
        can([ABILITY_ACTION.READ, ABILITY_ACTION.EDIT], ABILITY_SUBJECT.DOCUMENT_COMMENT);
        cannot([ABILITY_ACTION.READ, ABILITY_ACTION.EDIT], ABILITY_SUBJECT.DOCUMENT_COMMENT, [
            DOCUMENT_COMMENT_THREAD.INTERNAL,
        ]);
        bindCanEditStatus(can, AUTH_ROLES.SUPPLIER);
        bindCanEditShippingStatus(can, AUTH_ROLES.SUPPLIER);
    },
    contractor: function (user, _a) {
        var can = _a.can, cannot = _a.cannot;
        COMMON_ROUTES.map(function (route) { return can(ABILITY_ACTION.ACCESS, route); });
        can(ABILITY_ACTION.READ, ABILITY_SUBJECT.INSPECTION);
        cannot(ABILITY_ACTION.READ, ABILITY_SUBJECT.INSPECTION, ['sku']);
        // can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.SKU);
        can(ABILITY_ACTION.READ, ABILITY_SUBJECT.SP_NAME);
        can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.SP_NAME);
        can(ABILITY_ACTION.READ, ABILITY_SUBJECT.INSPECTOR_COMPANY);
        can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.INSPECTOR_COMPANY);
        can([ABILITY_ACTION.READ, ABILITY_ACTION.EDIT], ABILITY_SUBJECT.DOCUMENT_COMMENT);
        cannot([ABILITY_ACTION.READ, ABILITY_ACTION.EDIT], ABILITY_SUBJECT.DOCUMENT_COMMENT, [
            DOCUMENT_COMMENT_THREAD.INTERNAL,
        ]);
        can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.STANDARD_TERMS_SENTENCES_DETAIL);
        can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.MANAGE_DEFECT);
        bindCanEditStatus(can, AUTH_ROLES.CONTRACTOR);
        bindCanEditShippingStatus(can, AUTH_ROLES.CONTRACTOR);
    },
    caddi: function (user, _a) {
        var can = _a.can, cannot = _a.cannot;
        // CADDI user can access to all routes except LIMITED_SUPER_ADMIN_ROUTES
        COMMON_ROUTES.map(function (route) { return can(ABILITY_ACTION.ACCESS, route); });
        ADMIN_ROUTES.map(function (route) { return can(ABILITY_ACTION.ACCESS, route); });
        LIMITED_SUPER_ADMIN_ROUTES.map(function (route) { return cannot(ABILITY_ACTION.ACCESS, route); });
        can(ABILITY_ACTION.READ, ABILITY_SUBJECT.INSPECTION);
        cannot(ABILITY_ACTION.READ, ABILITY_SUBJECT.INSPECTION, ['sku']);
        // can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.SKU);
        can(ABILITY_ACTION.READ, ABILITY_SUBJECT.SP_NAME);
        can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.SP_NAME);
        can(ABILITY_ACTION.READ, ABILITY_SUBJECT.INSPECTOR_COMPANY);
        can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.INSPECTOR_COMPANY);
        can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.EXPORT_DATA);
        can(ABILITY_ACTION.READ, ABILITY_SUBJECT.EXPORT_DATA);
        can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.STANDARD_TERMS_SENTENCES_DETAIL);
        can([ABILITY_ACTION.READ, ABILITY_ACTION.EDIT], ABILITY_SUBJECT.BUSINESS_SECTION);
        can([ABILITY_ACTION.READ, ABILITY_ACTION.EDIT], ABILITY_SUBJECT.DOCUMENT_COMMENT);
        can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.MANAGE_DEFECT);
        bindCanEditStatus(can, AUTH_ROLES.CADDI);
        bindCanEditShippingStatus(can, AUTH_ROLES.CADDI);
    },
    admin: function (user, _a) {
        var can = _a.can, cannot = _a.cannot;
        // Admin can access to all routes
        can(ABILITY_ACTION.ACCESS, 'all');
        can(ABILITY_ACTION.READ, ABILITY_SUBJECT.INSPECTION);
        cannot(ABILITY_ACTION.READ, ABILITY_SUBJECT.INSPECTION, ['sku']);
        // can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.SKU);
        can(ABILITY_ACTION.READ, ABILITY_SUBJECT.SP_NAME);
        can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.SP_NAME);
        can(ABILITY_ACTION.READ, ABILITY_SUBJECT.INSPECTOR_COMPANY);
        can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.INSPECTOR_COMPANY);
        can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.EXPORT_DATA);
        can(ABILITY_ACTION.READ, ABILITY_SUBJECT.EXPORT_DATA);
        can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.STANDARD_TERMS_SENTENCES_DETAIL);
        can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.STANDARD_TERMS_SENTENCES_KEYWORD);
        can([ABILITY_ACTION.READ, ABILITY_ACTION.EDIT], ABILITY_SUBJECT.BUSINESS_SECTION);
        can([ABILITY_ACTION.READ, ABILITY_ACTION.EDIT], ABILITY_SUBJECT.DOCUMENT_COMMENT);
        can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.TAG);
        can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.MANAGE_DEFECT);
        bindCanEditStatus(can, AUTH_ROLES.ADMIN);
        bindCanEditShippingStatus(can, AUTH_ROLES.ADMIN);
    },
    unauthorized: function (_, _a) {
        var cannot = _a.cannot;
        cannot(ABILITY_ACTION.ACCESS, 'all');
    },
    system: function (_, _a) {
        var cannot = _a.cannot;
        cannot(ABILITY_ACTION.ACCESS, 'all');
    },
};
var bindCanEditStatus = function (can, role) {
    var _a;
    var statusTransaction = (_a = {},
        _a[AUTH_ROLES.ADMIN] = INSPECTION_TRANSITION_STATUS.admin,
        _a[AUTH_ROLES.CADDI] = INSPECTION_TRANSITION_STATUS.caddi,
        _a[AUTH_ROLES.CONTRACTOR] = INSPECTION_TRANSITION_STATUS.contractor,
        _a[AUTH_ROLES.SUPPLIER] = INSPECTION_TRANSITION_STATUS.supplier,
        _a[AUTH_ROLES.UNAUTHORIZED] = {},
        _a[AUTH_ROLES.SYSTEM] = {},
        _a);
    var fields = [];
    Object.entries(statusTransaction[role]).forEach(function (_a) {
        var inspectionStyle = _a[0], fromStatues = _a[1];
        fields.push(inspectionStyle);
        Object.entries(fromStatues).forEach(function (_a) {
            var from = _a[0], toStatues = _a[1];
            fields.push("".concat(inspectionStyle, ".").concat(from));
            toStatues.forEach(function (to) {
                fields.push("".concat(inspectionStyle, ".").concat(from, ".").concat(to));
            });
        });
    });
    can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.STATUS_TRANSACTION, _uniq(fields));
};
var bindCanEditShippingStatus = function (can, role) {
    var _a;
    var statusTransaction = (_a = {},
        _a[AUTH_ROLES.ADMIN] = SHIPPING_TRANSITION_STATUS.ADMIN,
        _a[AUTH_ROLES.CADDI] = SHIPPING_TRANSITION_STATUS.CADDI,
        _a[AUTH_ROLES.CONTRACTOR] = SHIPPING_TRANSITION_STATUS.CONTRACTOR,
        _a[AUTH_ROLES.SUPPLIER] = SHIPPING_TRANSITION_STATUS.SP,
        _a[AUTH_ROLES.UNAUTHORIZED] = {},
        _a[AUTH_ROLES.SYSTEM] = {},
        _a);
    var fields = [];
    Object.entries(statusTransaction[role]).forEach(function (_a) {
        var from = _a[0], toStatuses = _a[1];
        fields.push(from.toUpperCase().replace(' ', '_'));
        Object.entries(toStatuses).forEach(function (_a) {
            var to = _a[0], _ = _a[1];
            fields.push("".concat(from.toUpperCase().replace(' ', '_'), ".").concat(to.toUpperCase().replace(' ', '_')));
        });
    });
    can(ABILITY_ACTION.EDIT, ABILITY_SUBJECT.SHIPPING_STATUS_TRANSACTION, _uniq(fields));
};
/**
 * Resolve user role
 * @param user User
 * @param baseOnAppMetadata: Default is True: use app_metadata to determine instead of connection
 *    Example: userClaim { sub: google-oauth2|60498088f158890071330e6f, app_metadata: { role: contractor }}
 *      When baseOnAppMetadata = true  => user's role is contractor
 *      When baseOnAppMetadata = false => user's role is caddi
 * @returns AuthUser
 */
export function resolveUserRole(user, baseOnAppMetadata) {
    var _a, _b, _c, _d;
    if (baseOnAppMetadata === void 0) { baseOnAppMetadata = true; }
    var userWithRole = _clone(user);
    userWithRole.role = AUTH_ROLES.UNAUTHORIZED;
    if (!user.email) {
        return userWithRole;
    }
    var isGoogleSocialConnection = REGEX_AUTH_GOOGLE_SOCIAL_CONNECTION.test(user.sub || '');
    userWithRole.isGoogleSocialConnection = isGoogleSocialConnection;
    if (checkDisableFeatures(DISABLED_FEATURES.SP_MANAGEMENT)) {
        // Allow all account from User/Pass access as Supplier when feature SP Management has been disabled
        userWithRole.role = isGoogleSocialConnection ? AUTH_ROLES.CADDI : AUTH_ROLES.SUPPLIER;
        return userWithRole;
    }
    // Collect profile's app_metadata
    var authRole = (_a = user["".concat(NAMESPACE_AUTH0, "/").concat(AUTH0_METADATA.ROLE)]) !== null && _a !== void 0 ? _a : null;
    var supplierId = (_b = user["".concat(NAMESPACE_AUTH0, "/").concat(AUTH0_METADATA.SUPPLIER_ID)]) !== null && _b !== void 0 ? _b : null;
    switch (authRole) {
        case USER_ACCOUNT_ROLES.SUPPLIER:
            if (supplierId) {
                userWithRole.role = AUTH_ROLES.SUPPLIER;
                userWithRole.supplierId = supplierId;
            }
            break;
        case USER_ACCOUNT_ROLES.CONTRACTOR:
            userWithRole.role = AUTH_ROLES.CONTRACTOR;
            break;
        case null:
            // All accounts from Google Social Connection are CADDI role.
            // The CADDI users in ADMIN_USERS are Admin role.
            if (isGoogleSocialConnection) {
                var isAdmin = ((_c = process.env.ADMIN_USERS) !== null && _c !== void 0 ? _c : '').indexOf(user.email) !== -1;
                userWithRole.role = isAdmin ? AUTH_ROLES.ADMIN : AUTH_ROLES.CADDI;
            }
            break;
    }
    if (!baseOnAppMetadata && isGoogleSocialConnection) {
        // Override role when not base on app_metadata
        var isAdmin = ((_d = process.env.ADMIN_USERS) !== null && _d !== void 0 ? _d : '').indexOf(user.email) !== -1;
        userWithRole.role = isAdmin ? AUTH_ROLES.ADMIN : AUTH_ROLES.CADDI;
    }
    return userWithRole;
}
export function defineAbilityFor(user) {
    var builder = new AbilityBuilder(PureAbility);
    if (typeof rolePermissions[user.role] === 'function') {
        rolePermissions[user.role](user, builder);
    }
    return builder.build({ fieldMatcher: fieldMatcher });
}
export var isSupplierUser = function (user) {
    return (user === null || user === void 0 ? void 0 : user.role) === USER_ACCOUNT_ROLES.SUPPLIER;
};
