var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable max-lines */
import { useState } from 'react';
import { date } from '../../shared/date';
import { useTranslation } from 'react-i18next';
import { Button, Col, Image, Modal, Row, Space } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Status } from '../commons/ImportCSV/Status';
import csvIcon from '../icons/csv-icon.svg';
import { ButtonDownload } from './ButtonDownload';
import { ButtonEdit } from './ButtonEdit';
var RowStyle = styled(Row)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 10px;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.12);\n"], ["\n  padding: 10px;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.12);\n"])));
var ListItem = function (props) {
    var item = props.item, resultImported = props.resultImported;
    var t = useTranslation('components', { keyPrefix: 'importCSV' }).t;
    var tCommon = useTranslation('components', { keyPrefix: 'commons' }).t;
    var _a = useState(false), openConfirmAction = _a[0], setOpenConfirmAction = _a[1];
    return (_jsxs(_Fragment, { children: [_jsxs("div", { "data-testid": "importCSV-item", style: {
                    fontSize: '16px',
                    background: '#fff',
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    borderRadius: '4px',
                }, children: [_jsx(RowStyle, { align: "middle", wrap: false, children: _jsx(Col, { span: 24, children: _jsxs(Space, { size: 12, children: [_jsx(Image, { src: csvIcon, preview: false, width: 30 }), item.originalFileName] }) }) }), _jsx(RowStyle, { align: "middle", wrap: false, children: _jsx(Col, { span: 24, children: _jsxs("div", { children: [t('listData.qboImportingId'), ": ", item.qboImportingId] }) }) }), _jsx(RowStyle, { align: "middle", wrap: false, children: _jsx(Col, { span: 24, children: _jsxs("div", { children: [t('listData.status'), ": ", _jsx(Status, { item: item })] }) }) }), _jsx(RowStyle, { align: "middle", wrap: false, children: _jsx(Col, { span: 24, children: _jsxs("div", { children: [t('listData.result'), ": ", resultImported] }) }) }), _jsx(RowStyle, { align: "middle", wrap: false, children: _jsx(Col, { span: 24, children: _jsxs("div", { children: [t('listData.date'), ": ", date.format(item.createdAt, tCommon('format.formatDate'))] }) }) }), _jsx(Row, { align: "middle", wrap: false, style: { padding: '10px' }, children: _jsx(Col, { span: 2, style: { textAlign: 'left' }, children: _jsx(Button, { type: "text", size: "large", icon: _jsx(MoreOutlined, {}), onClick: function () { return setOpenConfirmAction(true); } }) }) })] }), _jsxs(Modal, { open: openConfirmAction, centered: true, title: false, onOk: function () { return setOpenConfirmAction(false); }, onCancel: function () { return setOpenConfirmAction(false); }, footer: false, maskClosable: true, closeIcon: false, closable: false, width: 230, children: [_jsx("div", { style: { fontSize: '16px' }, children: _jsx(ButtonEdit, { onClick: function () {
                                if (props.onClickEditHandler)
                                    props.onClickEditHandler();
                                setOpenConfirmAction(false);
                            }, dataTestId: "btn-edit-importCSV-".concat(item.qboImportingId) }) }), _jsx("div", { style: { fontSize: '16px', paddingTop: '5px' }, children: _jsx(ButtonDownload, { item: item }) })] })] }));
};
export { ListItem };
var templateObject_1;
