var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from 'styled-components';
import { Button, Select as _Select, Row, Col, Modal, Typography } from 'antd';
var Text = Typography.Text;
import { useTranslation } from 'react-i18next';
import { useSelectLanguage, useSelectTimezone, useSelectUserInterface, useSave, } from '../../hooks/Settings';
import 'antd/dist/reset.css';
var StyledModal = styled(Modal)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-family: 'Roboto';\n  font-style: normal;\n  .title {\n    font-weight: bold;\n    font-size: 20px;\n    color: '#2F333F';\n    height: 32px;\n    font-style: normal;\n    line-height: 160%;\n    letter-spacing: 0.15px;\n  }\n  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {\n    width: 100%;\n    line-height: 50px;\n    height: 50px;\n    padding: 8px 11px;\n  }\n"], ["\n  font-family: 'Roboto';\n  font-style: normal;\n  .title {\n    font-weight: bold;\n    font-size: 20px;\n    color: '#2F333F';\n    height: 32px;\n    font-style: normal;\n    line-height: 160%;\n    letter-spacing: 0.15px;\n  }\n  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {\n    width: 100%;\n    line-height: 50px;\n    height: 50px;\n    padding: 8px 11px;\n  }\n"])));
var RowStyle = styled(Row)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 15px;\n"], ["\n  margin-top: 15px;\n"])));
var ButtonStyle = styled(Button)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: #2196f3;\n  &:hover {\n    color: #2196f3;\n  }\n  font-size: 14px;\n  line-height: 24px;\n  letter-spacing: 0.4px;\n  text-transform: uppercase;\n  font-weight: 700;\n"], ["\n  color: #2196f3;\n  &:hover {\n    color: #2196f3;\n  }\n  font-size: 14px;\n  line-height: 24px;\n  letter-spacing: 0.4px;\n  text-transform: uppercase;\n  font-weight: 700;\n"])));
var SelectStyle = styled(_Select)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 24px;\n  display: flex;\n  align-items: center;\n  letter-spacing: 0.15px;\n  color: #2f333f;\n  width: 340px;\n  height: 24px;\n  width: 100%;\n  text-align: left;\n"], ["\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 24px;\n  display: flex;\n  align-items: center;\n  letter-spacing: 0.15px;\n  color: #2f333f;\n  width: 340px;\n  height: 24px;\n  width: 100%;\n  text-align: left;\n"])));
var Field = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-bottom: 14px;\n  position: relative;\n  padding-top: 30px;\n"], ["\n  margin-bottom: 14px;\n  position: relative;\n  padding-top: 30px;\n"])));
var FieldLabel = styled.label(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 12px;\n  line-height: 12px;\n  position: absolute;\n  top: 12px;\n  left: 12px;\n  z-index: 999;\n  background-color: white;\n  padding: 0px 5px;\n"], ["\n  font-size: 12px;\n  line-height: 12px;\n  position: absolute;\n  top: 12px;\n  left: 12px;\n  z-index: 999;\n  background-color: white;\n  padding: 0px 5px;\n"])));
var ButtonWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  padding-top: 15px;\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  padding-top: 15px;\n"])));
var SettingModal = function (props) {
    var t = useTranslation('components', { keyPrefix: 'settings.Settings' }).t;
    var tCommon = useTranslation('components', { keyPrefix: 'commons' }).t;
    var _a = useSelectLanguage(), currentLanguage = _a.currentLanguage, language = _a.language, languageChangeHandler = _a.languageChangeHandler, languageOptions = _a.languageOptions;
    var _b = useSelectTimezone(), currentTimezone = _b.currentTimezone, timezone = _b.timezone, timezoneChangeHandler = _b.timezoneChangeHandler, timezoneOptions = _b.timezoneOptions;
    var _c = useSelectUserInterface(), currentUserInterface = _c.currentUserInterface, userInterface = _c.userInterface, userInterfaceChangeHandler = _c.userInterfaceChangeHandler, userInterfaceOptions = _c.userInterfaceOptions;
    var _d = useSave({
        currentLanguage: currentLanguage,
        currentTimezone: currentTimezone,
        currentUserInterface: currentUserInterface,
        language: language,
        timezone: timezone,
        userInterface: userInterface,
    }), canSave = _d.canSave, saveHandler = _d.saveHandler;
    return (_jsx(_Fragment, { children: _jsxs(StyledModal, { "data-testid": "styledModal", open: props.isOpenSetting, centered: true, width: 444, footer: null, closable: false, className: 'settingModal', children: [_jsx(Text, { className: "title", children: t('header') }), _jsx(RowStyle, { children: _jsxs(Col, { className: "gutter-row", span: 24, children: [_jsxs(Field, { children: [_jsx(FieldLabel, { htmlFor: "language", children: t('label.language') }), _jsx(SelectStyle, { id: "language", value: language, options: languageOptions, onChange: languageChangeHandler, "data-testid": "selectLanguage" })] }), _jsxs(Field, { children: [_jsx(FieldLabel, { htmlFor: "user-interface", children: t('label.user-interface') }), _jsx(SelectStyle, { id: "user-interface", value: userInterface, options: userInterfaceOptions, onChange: userInterfaceChangeHandler, "data-testid": "selectUserInterface" })] }), _jsxs(Field, { children: [_jsx(FieldLabel, { htmlFor: "timezone", children: t('label.timezone') }), _jsx(SelectStyle, { id: "timezone", value: timezone, options: timezoneOptions, onChange: timezoneChangeHandler, "data-testid": "selectTimezone" })] }), _jsxs(ButtonWrapper, { children: [_jsx(ButtonStyle, { type: "text", size: "middle", onClick: function () { return props.setIsOpenSetting(false); }, "data-testid": "btnCancelSetting", children: tCommon('buttons.cancel') }), _jsx(ButtonStyle, { type: "text", size: "middle", onClick: saveHandler, disabled: !canSave, "data-testid": "btnSaveSetting", children: tCommon('buttons.ok') })] })] }) })] }) }));
};
export { SettingModal };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
