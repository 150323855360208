import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Progress, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useMemo } from 'react';
var calcPercent = function (processed, total) {
    if (total === 0)
        return 0;
    return Math.floor((processed / total) * 100);
};
var UploadQueueProgress = function (props) {
    var _a = useState(0), total = _a[0], setTotal = _a[1];
    var _b = useState(0), processed = _b[0], setProcessed = _b[1];
    var t = useTranslation('components', { keyPrefix: 'commons' }).t;
    var percentage = useMemo(function () { return calcPercent(processed, total); }, [total, processed]);
    useEffect(function () { return setTotal(props.total); }, [props.total]);
    useEffect(function () { return setProcessed(props.processed); }, [props.processed]);
    useEffect(function () {
        var handleTabClose = function (event) {
            event.preventDefault();
            return (event.returnValue = '');
        };
        window.addEventListener('beforeunload', handleTabClose, { capture: true });
        return function () {
            window.removeEventListener('beforeunload', handleTabClose, { capture: true });
        };
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(Typography.Text, { children: processed === total
                    ? t('uploadFile.msgProcessSuccessfully', { total: total })
                    : t('uploadFile.msgProcessLoading', { processed: processed, total: total }) }), _jsx(Progress, { percent: percentage, size: "small", status: processed === total ? 'success' : 'active' })] }));
};
export { UploadQueueProgress };
