var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
var Spacer = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: ", "block;\n"], ["\n  display: ", "block;\n"])), function (_a) {
    var axis = _a.axis;
    return (axis === 'x' ? 'inline-' : '');
});
export { Spacer };
var templateObject_1;
