var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var ROUTE_HOME = '/';
export var ROUTE_SETTINGS = '/settings';
export var ROUTE_INSPECTION_RESULT_LIST = '/inspection-results';
export var ROUTE_INSPECTION_RESULT_DETAIL = '/inspection-result-detail/:inspectionId';
export var ROUTE_ON_SCREEN_INSPECTION_DRAWING = '/on-screen-inspection-drawing/:inspectionId';
export var ROUTE_ADMIN_DASHBOARD = '/admin';
export var ROUTE_USER_ACCOUNT_LIST = '/admin/user-accounts';
export var ROUTE_SUPPLIER_LIST = '/admin/suppliers';
export var ROUTE_ADMIN_INSPECTION_RESULT_LIST = "/admin/inspection-results";
export var ROUTE_LOGS_LIST = '/admin/logs';
export var ROUTE_LOGOUT = '/logout';
export var ROUTE_NOT_AUTHORIZED = '/not-authorized';
export var ROUTE_REFRESH_AUTHORIZED = '/refresh-authorized';
export var ROUTE_NOT_FOUND = '/page-not-found';
export var ROUTE_DEFECT_LIST = '/defects';
export var ROUTE_SHIPPING_LIST = '/shippings';
export var ROUTE_SHIPPING_DETAIL = '/shipping-detail/:shippingId';
export var ROUTE_ADMIN_SHIPPING_LIST = '/admin/shippings';
export var ROUTE_DOCUMENT_LIST = '/documents';
export var ROUTE_DOCUMENT_PERMALINK = '/document/permalink/:documentId';
export var ROUTE_ADMIN_DOCUMENT_LIST = '/admin/documents';
export var ROUTE_ADMIN_DOCUMENT_EDIT = '/admin/documents/edit/:documentId';
export var ROUTE_ADMIN_DOCUMENT_CREATE = '/admin/documents/create';
export var ROUTE_UNDER_MAINTENANCE = '/under-maintenance';
export var ROUTE_SERVICE_UNAVAILABLE = '/service-unavailable';
export var ROUTE_ADMIN_INSPECTOR_COMPANY_LIST = '/admin/inspector-company';
export var ROUTE_ADMIN_CREATE_MANUAL_ONSCREEN_INSPECTION = '/admin/create-manual-onscreen-inspection';
export var ROUTE_ADMIN_IMPORT_CSV = '/admin/import-csv';
export var ROUTE_ADMIN_EXPORT_DATA = '/admin/export-data';
export var ROUTE_ADMIN_MANAGER_TERMS_SENTENCES = '/admin/standard-terms-and-sentences';
export var ROUTE_ADMIN_INQUIRY = '/admin/inquiries';
export var ROUTE_DEFECT_DETAIL = '/defect-detail/:defectSyncId';
// Common routes for all users
export var COMMON_ROUTES = [
    ROUTE_HOME,
    ROUTE_SETTINGS,
    ROUTE_INSPECTION_RESULT_LIST,
    ROUTE_INSPECTION_RESULT_DETAIL,
    ROUTE_ON_SCREEN_INSPECTION_DRAWING,
    ROUTE_SHIPPING_LIST,
    ROUTE_SHIPPING_DETAIL,
    ROUTE_DEFECT_LIST,
    ROUTE_DEFECT_DETAIL,
    ROUTE_LOGOUT,
    ROUTE_NOT_AUTHORIZED,
    ROUTE_NOT_FOUND,
    ROUTE_DOCUMENT_LIST,
    ROUTE_DOCUMENT_PERMALINK,
    ROUTE_UNDER_MAINTENANCE,
    ROUTE_REFRESH_AUTHORIZED,
    ROUTE_SERVICE_UNAVAILABLE,
];
// Limited routes that only accessed by super user
export var LIMITED_SUPER_ADMIN_ROUTES = [ROUTE_ADMIN_SHIPPING_LIST];
// The list of routes for admin side
export var ADMIN_ROUTES = __spreadArray([
    ROUTE_ADMIN_DASHBOARD,
    ROUTE_USER_ACCOUNT_LIST,
    ROUTE_SUPPLIER_LIST,
    ROUTE_LOGS_LIST,
    ROUTE_ADMIN_DOCUMENT_LIST,
    ROUTE_ADMIN_DOCUMENT_CREATE,
    ROUTE_ADMIN_DOCUMENT_EDIT,
    ROUTE_ADMIN_INSPECTOR_COMPANY_LIST,
    ROUTE_ADMIN_CREATE_MANUAL_ONSCREEN_INSPECTION,
    ROUTE_ADMIN_IMPORT_CSV,
    ROUTE_ADMIN_EXPORT_DATA,
    ROUTE_ADMIN_INSPECTION_RESULT_LIST,
    ROUTE_ADMIN_MANAGER_TERMS_SENTENCES,
    ROUTE_ADMIN_INQUIRY
], LIMITED_SUPER_ADMIN_ROUTES, true);
export var NOT_AUTHORIZED_ROUTES = [
    ROUTE_LOGOUT,
    ROUTE_NOT_FOUND,
    ROUTE_NOT_AUTHORIZED,
    ROUTE_REFRESH_AUTHORIZED,
    ROUTE_UNDER_MAINTENANCE,
    ROUTE_SERVICE_UNAVAILABLE,
];
export var ALL_ROUTES = __spreadArray(__spreadArray([], ADMIN_ROUTES, true), COMMON_ROUTES, true);
