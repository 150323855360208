var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-lines */
import { useEffect, useMemo, useState } from 'react';
import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty';
import { Table } from '../commons/Table';
import { date } from '../../shared/date';
import { useTranslation } from 'react-i18next';
import { useAppI18n } from '../../contexts/AppI18nContext';
import { ListItem } from './ListItem';
import { Image, Modal, Space } from 'antd';
import { FormUpload } from './FormUpload';
import { Status } from '../commons/ImportCSV/Status';
import { CustomColumnHeader } from '../commons/CustomColumnHeader';
import { ButtonEdit } from './ButtonEdit';
import csvIcon from '../icons/csv-icon.svg';
import { ButtonDownload } from './ButtonDownload';
import { CaretRightOutlined } from '@ant-design/icons';
var breakWork = { wordWrap: 'break-word', wordBreak: 'break-word' };
var GridView = function (props) {
    var _a = props.editable, editable = _a === void 0 ? false : _a;
    var t = useTranslation('components', { keyPrefix: 'importCSV' }).t;
    var tCommon = useTranslation('components', { keyPrefix: 'commons' }).t;
    var layout = useAppI18n().layout;
    var isMobile = layout.isMobileLayoutOnPC || layout.isMobileScreenSize || layout.isMobileLayout;
    var _b = useState(false), isOpenForm = _b[0], setIsOpenForm = _b[1];
    var _c = useState(false), isShowErrorImport = _c[0], setIsShowErrorImport = _c[1];
    var _d = useState(null), contentShowErrorImport = _d[0], setContentShowErrorImport = _d[1];
    var _e = useState(), importCSVItem = _e[0], setImportCSVItem = _e[1];
    var openFormEditHandler = function (item) {
        setIsOpenForm(true);
        setImportCSVItem(item);
    };
    var renderImportedResult = function (fileName, meta) {
        if (!meta)
            return _jsx(_Fragment, {});
        var html = [];
        // Render successfull
        if (meta.successed !== undefined) {
            html.push(_jsx("div", { children: t('importProcessedCompletely.success', {
                    countSuccessed: meta.successed,
                }) }, "".concat(fileName, "-successed")));
        }
        // Render errors
        if (meta.errors !== undefined && meta.errors && meta.errors.length > 0) {
            html.push(_jsx("div", { style: { cursor: 'pointer', color: 'red' }, onClick: function () {
                    setIsShowErrorImport(true);
                    setContentShowErrorImport(_jsxs("div", { children: [_jsxs("div", { children: [_jsx(CaretRightOutlined, {}), t('importProcessedCompletely.errors.importedFile'), " ", fileName] }), _jsxs("div", { children: [_jsx(CaretRightOutlined, {}), t('importProcessedCompletely.errors.lines', { countError: meta.errors.length }), meta.errors.join(', ')] })] }));
                }, children: t('importProcessedCompletely.errors.hasError', {
                    countError: meta.errors.length,
                }) }, "".concat(fileName, "-errors")));
        }
        return html;
    };
    var renderWithDevice = function () {
        if (!isMobile) {
            var addColumnToTable = [
                {
                    title: _jsx(CustomColumnHeader, { text: t('listData.qboImportingId') }),
                    width: 300,
                    dataIndex: 'qboImportingId',
                    render: function (_, item) { return _jsx(_Fragment, { children: item.qboImportingId }); },
                },
                {
                    title: _jsx(CustomColumnHeader, { text: t('listData.fileName') }),
                    width: 300,
                    dataIndex: 'title',
                    render: function (_, item) {
                        return (_jsxs(Space, { size: 12, children: [_jsx(Image, { src: csvIcon, preview: false, width: 30 }), _jsx("div", { style: breakWork, children: item.originalFileName })] }));
                    },
                },
            ];
            addColumnToTable.push({
                title: _jsx(CustomColumnHeader, { text: t('listData.status') }),
                width: 100,
                dataIndex: 'status',
                render: function (_, item) { return _jsx(Status, { item: item }); },
            });
            addColumnToTable.push({
                title: _jsx(CustomColumnHeader, { text: t('listData.result') }),
                width: 400,
                dataIndex: 'result',
                render: function (_, item) {
                    return renderImportedResult(item.originalFileName, item.metaData);
                },
            });
            addColumnToTable.push({
                title: _jsx(CustomColumnHeader, { text: t('listData.date') }),
                dataIndex: 'createdAt',
                width: 200,
                render: function (_, item) { return (_jsx("div", { style: breakWork, children: date.format(item.createdAt, tCommon('format.formatDate')) })); },
            });
            addColumnToTable.push({
                title: '',
                dataIndex: 'detail',
                width: 200,
                render: function (_, item) {
                    return (_jsxs(Space, { children: [_jsx(ButtonEdit, { onClick: function () { return openFormEditHandler(item); }, dataTestId: "btn-edit-importCSV-".concat(item.qboImportingId) }), _jsx(ButtonDownload, { item: item })] }));
                },
            });
            return addColumnToTable;
        }
        else {
            return [
                {
                    title: '',
                    dataIndex: 'qboImportingId',
                    width: '100%',
                    render: function (_, item) { return (_jsx(ListItem, { onSuccessed: function () { return (props.onSuccessed ? props.onSuccessed() : undefined); }, onProgressing: function () { return (props.onProgressing ? props.onProgressing() : undefined); }, item: item, editable: editable, onClickEditHandler: function () { return openFormEditHandler(item); }, resultImported: renderImportedResult(item.originalFileName, item.metaData) })); },
                },
            ];
        }
    };
    var columns = useMemo(function () { return renderWithDevice(); }, [renderWithDevice, props]);
    var dataListWithKey = useMemo(function () {
        return props.dataList && !_isNil(props.dataList)
            ? props.dataList.map(function (f) { return (__assign(__assign({}, f), { key: f.qboImportingId })); })
            : [];
    }, [props.dataList]);
    useEffect(function () {
        if (!isShowErrorImport)
            return;
        Modal.error({
            title: t('importProcessedCompletely.errors.title'),
            centered: true,
            open: isShowErrorImport,
            closable: true,
            maskClosable: false,
            footer: false,
            onCancel: function () {
                setIsShowErrorImport(false);
                setContentShowErrorImport(null);
            },
            content: contentShowErrorImport,
        });
    }, [isShowErrorImport]);
    return (_jsxs("div", { "data-testid": "table-contain-list", style: { width: layout.isMobileScreenSize ? 'calc(100% - 20px)' : '100%' }, children: [columns !== undefined && (_jsx(Table, { locale: { emptyText: tCommon('message.emptyData') }, columns: columns, dataSource: dataListWithKey, pagination: isMobile ? (_isEmpty(dataListWithKey) ? false : props.pagination) : false, onChange: props.onChange, loading: props.loading, scroll: !isMobile ? { x: 'max-content' } : undefined, tableLayout: _isEmpty(dataListWithKey) ? 'fixed' : isMobile ? 'fixed' : 'auto' })), _jsx(FormUpload, { isOpenForm: isOpenForm, onSuccessed: function (doNothing) {
                    setIsOpenForm(false);
                    if (!doNothing) {
                        if (props.onSuccessed)
                            props.onSuccessed();
                    }
                }, onProgressing: function () {
                    if (props.onProgressing)
                        props.onProgressing();
                }, item: importCSVItem })] }));
};
export { GridView };
