import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-lines */
import { useState } from 'react';
import { Input, Space, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import icoSearch from '../icons/ico-search.svg';
import { showCommonErrorNotification } from '../../shared/logs';
export var SearchBox = function (props) {
    var _a = useState(undefined), keyword = _a[0], setKeyword = _a[1];
    var t = useTranslation('components', { keyPrefix: 'exportData' }).t;
    var handleSubmitSearch = function () {
        if (props.onProgressing)
            props.onProgressing();
        if (keyword === undefined || keyword.length <= 0) {
            showCommonErrorNotification({
                message: t('message.keyword_required'),
            });
            return;
        }
        if (props.onSearch)
            props.onSearch(keyword);
    };
    var handleAllowForceSearch = function () {
        if (props.onProgressing)
            props.onProgressing();
        if (props.onSearch)
            props.onSearch(undefined);
    };
    return (_jsxs("div", { style: {
            position: 'relative',
            border: '1px solid #d9d9d9',
            borderRadius: '5px',
            padding: '1px',
            background: 'white',
            display: 'inline-block',
            width: 380,
        }, children: [_jsx("h4", { style: {
                    position: 'absolute',
                    display: 'inline',
                    color: '#d9d9d9',
                    fontSize: '90%',
                    background: 'white',
                    padding: '0 5px',
                    left: '10px',
                    top: '-10px',
                }, children: t('form.search.title') }), _jsxs(Space.Compact, { style: { width: '100%' }, children: [_jsx(Input, { onChange: function (e) {
                            setKeyword(e.target.value);
                            if (e.target.value.length <= 0) {
                                handleAllowForceSearch();
                            }
                        }, onPressEnter: handleSubmitSearch, placeholder: t('form.search.placeholder'), allowClear: true, defaultValue: keyword, value: keyword, "data-testid": "txt-keyword-search-inspector-company", bordered: false }), _jsx(Button, { loading: props.isPageLoading, onClick: handleSubmitSearch, icon: _jsx("img", { src: icoSearch, width: 14, alt: t('search.title') }), "data-testid": "btn-search-inspector-company", style: { border: '0' } })] })] }));
};
