var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable max-lines */
import { fetchUploadUrl, usePostPhotoData, putOriginalFile, fetchReUploadUrl, usePostShippingPhotoData, fetchShippingUploadUrl, fetchShippingReUploadUrl, useFetchDocumentUploadUrl, useFetchDocumentReUploadUrl, } from '../../api';
import { useFetchInquiryUploadUrl, } from '../../api/inquiry/useFetchInquiryUploadUrl';
import { usePostInquiryPhotoData, } from '../../api/inquiry/usePostInquiryPhotoData';
import { sendError, showCommonErrorNotification } from '../../shared/logs';
import { UPLOAD_FEATURE, } from '../../shared/types';
import { useCallback } from 'react';
import { compressImage } from '../../shared/fileUtils';
export var useFileHandler = function () {
    var dispatchCreatePhoto = usePostPhotoData();
    var dispatchCreateShippingPhoto = usePostShippingPhotoData();
    var dispatchFetchUploadUrl = fetchUploadUrl();
    var dispatchFetchShippingUploadUrl = fetchShippingUploadUrl();
    var dispatchFetchReUploadUrl = fetchReUploadUrl();
    var dispatchFetchShippingReUploadUrl = fetchShippingReUploadUrl();
    var dispatchFetchDocumentUploadUrl = useFetchDocumentUploadUrl();
    var dispatchFetchDocumentReUploadUrl = useFetchDocumentReUploadUrl();
    var dispatchFetchInquiryUploadUrl = useFetchInquiryUploadUrl();
    var dispatchPostInquiryPhotoData = usePostInquiryPhotoData();
    var getUploadInquiryFileInfo = useCallback(function (uploadUrlArgs) { return __awaiter(void 0, void 0, void 0, function () {
        var result, message;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!dispatchFetchInquiryUploadUrl)
                        return [2 /*return*/];
                    return [4 /*yield*/, dispatchFetchInquiryUploadUrl({
                            query: uploadUrlArgs,
                        })];
                case 1:
                    result = _a.sent();
                    if (!result.isSuccess) {
                        message = "".concat(uploadUrlArgs.originalFileName, "::") + result.err.message;
                        showCommonErrorNotification({ message: message });
                        return [2 /*return*/];
                    }
                    return [2 /*return*/, result.body];
            }
        });
    }); }, [dispatchFetchInquiryUploadUrl]);
    var saveInquiryPhotoData = useCallback(function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var result, message, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!dispatchPostInquiryPhotoData)
                        return [2 /*return*/];
                    return [4 /*yield*/, dispatchPostInquiryPhotoData({
                            body: params,
                            stopSendingErr: true,
                            stopShowingErrNotification: true,
                        })];
                case 1:
                    result = _a.sent();
                    if (!result.isSuccess) {
                        message = "".concat(params.originalFileName, "::") + result.err.message;
                        showCommonErrorNotification({ message: message });
                    }
                    data = result.isSuccess ? result.body : null;
                    return [2 /*return*/, { isSuccess: result.isSuccess, data: data }];
            }
        });
    }); }, [dispatchPostInquiryPhotoData]);
    function handleUploadInquiryFile(inquiryCommentId, fileList) {
        return __awaiter(this, void 0, void 0, function () {
            var responseData, uploadProcesses, e_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        responseData = [];
                        if (fileList.length === 0)
                            return [2 /*return*/, []];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        uploadProcesses = fileList.map(function (file) { return __awaiter(_this, void 0, void 0, function () {
                            var uploadUrl, compressedFile, savePhotoObject, savedPhoto;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, getUploadInquiryFileInfo({
                                            originalFileName: file.name,
                                            inquiryCommentId: inquiryCommentId,
                                        })];
                                    case 1:
                                        uploadUrl = _a.sent();
                                        if (uploadUrl === undefined) {
                                            return [2 /*return*/, false];
                                        }
                                        return [4 /*yield*/, compressImage(file)];
                                    case 2:
                                        compressedFile = _a.sent();
                                        return [4 /*yield*/, putOriginalFile({ file: compressedFile, uploadUrl: uploadUrl.signedUrl })];
                                    case 3:
                                        _a.sent();
                                        savePhotoObject = {
                                            inquiryCommentId: inquiryCommentId,
                                            fileName: uploadUrl.fileName,
                                            originalFileName: uploadUrl.originalFileName,
                                        };
                                        return [4 /*yield*/, saveInquiryPhotoData(savePhotoObject)];
                                    case 4:
                                        savedPhoto = _a.sent();
                                        if (savedPhoto !== undefined && savedPhoto.isSuccess && savedPhoto.data !== null) {
                                            responseData.push(savedPhoto.data);
                                        }
                                        return [2 /*return*/];
                                }
                            });
                        }); });
                        return [4 /*yield*/, Promise.all(uploadProcesses)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        showCommonErrorNotification(e_1);
                        sendError(e_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, responseData];
                }
            });
        });
    }
    var handleUploadDocumentFile = function (fileList, onProgressing, onSuccessed) { return __awaiter(void 0, void 0, void 0, function () {
        var isSuccess;
        return __generator(this, function (_a) {
            isSuccess = false;
            if (fileList.length === 0)
                return [2 /*return*/, isSuccess];
            try {
                onProgressing && onProgressing;
                fileList.map(function (file) { return __awaiter(void 0, void 0, void 0, function () {
                    var uploadUrl, compressedFile;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, getDocumentUploadInfo({
                                    originalFileName: file.name,
                                })];
                            case 1:
                                uploadUrl = _a.sent();
                                if (uploadUrl === undefined) {
                                    return [2 /*return*/, false];
                                }
                                return [4 /*yield*/, compressImage(file)];
                            case 2:
                                compressedFile = _a.sent();
                                return [4 /*yield*/, putOriginalFile({ file: compressedFile, uploadUrl: uploadUrl.signedUrl })];
                            case 3:
                                _a.sent();
                                onSuccessed && onSuccessed(uploadUrl);
                                return [2 /*return*/];
                        }
                    });
                }); });
                isSuccess = true;
            }
            catch (e) {
                showCommonErrorNotification(e);
                sendError(e);
            }
            return [2 /*return*/, isSuccess];
        });
    }); };
    function handleUploadFile(photoType, belongsId, fileList, uploadFeature, sequence) {
        return __awaiter(this, void 0, void 0, function () {
            var responseData, uploadProcesses, e_2;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        responseData = [];
                        if (fileList.length === 0)
                            return [2 /*return*/, []];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        uploadProcesses = fileList.map(function (file) { return __awaiter(_this, void 0, void 0, function () {
                            var uploadUrl, compressedFile, savePhotoObject, savedPhoto, _a;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        if (!(uploadFeature === UPLOAD_FEATURE.INSPECTION)) return [3 /*break*/, 2];
                                        return [4 /*yield*/, getUploadInfo({
                                                originalFileName: file.name,
                                                inspectionId: belongsId,
                                            })];
                                    case 1:
                                        uploadUrl = _b.sent();
                                        return [3 /*break*/, 4];
                                    case 2:
                                        if (!(uploadFeature === UPLOAD_FEATURE.SHIPPING)) return [3 /*break*/, 4];
                                        return [4 /*yield*/, getShippingUploadInfo({
                                                originalFileName: file.name,
                                                shippingId: belongsId,
                                            })];
                                    case 3:
                                        uploadUrl = _b.sent();
                                        _b.label = 4;
                                    case 4:
                                        if (uploadUrl === undefined) {
                                            return [2 /*return*/, false];
                                        }
                                        return [4 /*yield*/, compressImage(file)];
                                    case 5:
                                        compressedFile = _b.sent();
                                        return [4 /*yield*/, putOriginalFile({ file: compressedFile, uploadUrl: uploadUrl.signedUrl })];
                                    case 6:
                                        _b.sent();
                                        savePhotoObject = __assign(__assign({}, (uploadFeature === UPLOAD_FEATURE.INSPECTION
                                            ? { inspectionId: belongsId, type: photoType }
                                            : {
                                                shippingId: belongsId,
                                                sectionType: photoType,
                                                sequence: sequence !== null && sequence !== void 0 ? sequence : 1,
                                            })), { fileName: uploadUrl.fileName, originalFileName: uploadUrl.originalFileName });
                                        if (!('inspectionId' in savePhotoObject)) return [3 /*break*/, 8];
                                        return [4 /*yield*/, savePhotoData(savePhotoObject)];
                                    case 7:
                                        _a = _b.sent();
                                        return [3 /*break*/, 10];
                                    case 8: return [4 /*yield*/, saveShippingPhotoData(savePhotoObject)];
                                    case 9:
                                        _a = _b.sent();
                                        _b.label = 10;
                                    case 10:
                                        savedPhoto = _a;
                                        if (savedPhoto !== undefined && savedPhoto.isSuccess && savedPhoto.data !== null) {
                                            responseData.push(savedPhoto.data);
                                        }
                                        return [2 /*return*/];
                                }
                            });
                        }); });
                        return [4 /*yield*/, Promise.all(uploadProcesses)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _a.sent();
                        showCommonErrorNotification(e_2);
                        sendError(e_2);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, responseData];
                }
            });
        });
    }
    var getUploadInfo = useCallback(function (uploadUrlArgs) { return __awaiter(void 0, void 0, void 0, function () {
        var result, message;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!dispatchFetchUploadUrl)
                        return [2 /*return*/];
                    return [4 /*yield*/, dispatchFetchUploadUrl({
                            query: uploadUrlArgs,
                        })];
                case 1:
                    result = _a.sent();
                    if (!result.isSuccess) {
                        message = "".concat(uploadUrlArgs.originalFileName, "::") + result.err.message;
                        showCommonErrorNotification({ message: message });
                        return [2 /*return*/];
                    }
                    return [2 /*return*/, result.body];
            }
        });
    }); }, [dispatchFetchUploadUrl]);
    var getShippingUploadInfo = useCallback(function (shippingUploadUrlArgs) { return __awaiter(void 0, void 0, void 0, function () {
        var result, message;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!dispatchFetchShippingUploadUrl)
                        return [2 /*return*/];
                    return [4 /*yield*/, dispatchFetchShippingUploadUrl({
                            query: shippingUploadUrlArgs,
                        })];
                case 1:
                    result = _a.sent();
                    if (!result.isSuccess) {
                        message = "".concat(shippingUploadUrlArgs.originalFileName, "::") + result.err.message;
                        showCommonErrorNotification({ message: message });
                        return [2 /*return*/];
                    }
                    return [2 /*return*/, result.body];
            }
        });
    }); }, [dispatchFetchShippingUploadUrl]);
    var getDocumentUploadInfo = useCallback(function (documentUploadUrlArgs) { return __awaiter(void 0, void 0, void 0, function () {
        var result, message;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!dispatchFetchDocumentUploadUrl)
                        return [2 /*return*/];
                    return [4 /*yield*/, dispatchFetchDocumentUploadUrl({
                            query: documentUploadUrlArgs,
                        })];
                case 1:
                    result = _a.sent();
                    if (!result.isSuccess) {
                        message = "".concat(documentUploadUrlArgs.originalFileName, "::") + result.err.message;
                        showCommonErrorNotification({ message: message });
                        return [2 /*return*/];
                    }
                    return [2 /*return*/, result.body];
            }
        });
    }); }, [dispatchFetchDocumentUploadUrl]);
    var savePhotoData = useCallback(function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var result, message, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!dispatchCreatePhoto)
                        return [2 /*return*/];
                    return [4 /*yield*/, dispatchCreatePhoto({
                            body: params,
                            stopSendingErr: true,
                            stopShowingErrNotification: true,
                        })];
                case 1:
                    result = _a.sent();
                    if (!result.isSuccess) {
                        message = "".concat(params.originalFileName, "::") + result.err.message;
                        showCommonErrorNotification({ message: message });
                    }
                    data = result.isSuccess ? result.body : null;
                    return [2 /*return*/, { isSuccess: result.isSuccess, data: data }];
            }
        });
    }); }, [dispatchCreatePhoto, dispatchCreateShippingPhoto]);
    var saveShippingPhotoData = useCallback(function (params) { return __awaiter(void 0, void 0, void 0, function () {
        var result, message, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!dispatchCreateShippingPhoto)
                        return [2 /*return*/];
                    return [4 /*yield*/, dispatchCreateShippingPhoto({
                            body: params,
                            stopSendingErr: true,
                            stopShowingErrNotification: true,
                        })];
                case 1:
                    result = _a.sent();
                    if (!result.isSuccess) {
                        message = "".concat(params.originalFileName, "::") + result.err.message;
                        showCommonErrorNotification({ message: message });
                    }
                    data = result.isSuccess ? result.body : null;
                    return [2 /*return*/, { isSuccess: result.isSuccess, data: data }];
            }
        });
    }); }, [dispatchCreateShippingPhoto]);
    var handleReUploadFile = function (photoId, file) { return __awaiter(void 0, void 0, void 0, function () {
        var newSignedUrl, uploadUrl, compressedFile, e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    newSignedUrl = false;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, , 6]);
                    return [4 /*yield*/, getReUploadInfo(photoId)];
                case 2:
                    uploadUrl = _a.sent();
                    if (uploadUrl === undefined) {
                        return [2 /*return*/, newSignedUrl];
                    }
                    return [4 /*yield*/, compressImage(file)];
                case 3:
                    compressedFile = _a.sent();
                    return [4 /*yield*/, putOriginalFile({ file: compressedFile, uploadUrl: uploadUrl.signedUrl })];
                case 4:
                    _a.sent();
                    newSignedUrl = true;
                    return [3 /*break*/, 6];
                case 5:
                    e_3 = _a.sent();
                    showCommonErrorNotification(e_3);
                    sendError(e_3);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/, newSignedUrl];
            }
        });
    }); };
    var handleShippingReUploadFile = function (photoId, file) { return __awaiter(void 0, void 0, void 0, function () {
        var newSignedUrl, uploadUrl, compressedFile, e_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    newSignedUrl = false;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, , 6]);
                    return [4 /*yield*/, getShippingReUploadInfo(photoId)];
                case 2:
                    uploadUrl = _a.sent();
                    if (uploadUrl === undefined) {
                        return [2 /*return*/, newSignedUrl];
                    }
                    return [4 /*yield*/, compressImage(file)];
                case 3:
                    compressedFile = _a.sent();
                    return [4 /*yield*/, putOriginalFile({ file: compressedFile, uploadUrl: uploadUrl.signedUrl })];
                case 4:
                    _a.sent();
                    newSignedUrl = true;
                    return [3 /*break*/, 6];
                case 5:
                    e_4 = _a.sent();
                    showCommonErrorNotification(e_4);
                    sendError(e_4);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/, newSignedUrl];
            }
        });
    }); };
    var handleReUploadDocumentFile = function (documentId, file) { return __awaiter(void 0, void 0, void 0, function () {
        var newSignedUrl, uploadUrl, compressedFile, e_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    newSignedUrl = false;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, , 6]);
                    return [4 /*yield*/, getDocumentReUploadInfo(documentId, file.name)];
                case 2:
                    uploadUrl = _a.sent();
                    if (uploadUrl === undefined) {
                        return [2 /*return*/, newSignedUrl];
                    }
                    return [4 /*yield*/, compressImage(file)];
                case 3:
                    compressedFile = _a.sent();
                    return [4 /*yield*/, putOriginalFile({ file: compressedFile, uploadUrl: uploadUrl.signedUrl })];
                case 4:
                    _a.sent();
                    newSignedUrl = true;
                    return [3 /*break*/, 6];
                case 5:
                    e_5 = _a.sent();
                    showCommonErrorNotification(e_5);
                    sendError(e_5);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/, newSignedUrl];
            }
        });
    }); };
    var getReUploadInfo = useCallback(function (photoID) { return __awaiter(void 0, void 0, void 0, function () {
        var result, message;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!dispatchFetchReUploadUrl)
                        return [2 /*return*/];
                    return [4 /*yield*/, dispatchFetchReUploadUrl({
                            params: [photoID, 'reupload-url'],
                        })];
                case 1:
                    result = _a.sent();
                    if (!result.isSuccess) {
                        message = result.err.message;
                        showCommonErrorNotification({ message: message });
                        return [2 /*return*/];
                    }
                    return [2 /*return*/, result.body];
            }
        });
    }); }, [dispatchFetchReUploadUrl]);
    var getShippingReUploadInfo = useCallback(function (photoID) { return __awaiter(void 0, void 0, void 0, function () {
        var result, message;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!dispatchFetchShippingReUploadUrl)
                        return [2 /*return*/];
                    return [4 /*yield*/, dispatchFetchShippingReUploadUrl({
                            params: [photoID, 'reupload-url'],
                        })];
                case 1:
                    result = _a.sent();
                    if (!result.isSuccess) {
                        message = result.err.message;
                        showCommonErrorNotification({ message: message });
                        return [2 /*return*/];
                    }
                    return [2 /*return*/, result.body];
            }
        });
    }); }, [dispatchFetchShippingReUploadUrl]);
    var getDocumentReUploadInfo = useCallback(function (documentId, originalFileName) { return __awaiter(void 0, void 0, void 0, function () {
        var result, message;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!dispatchFetchDocumentReUploadUrl)
                        return [2 /*return*/];
                    return [4 /*yield*/, dispatchFetchDocumentReUploadUrl({
                            params: [documentId, 'reupload-url'],
                            query: { originalFileName: originalFileName },
                        })];
                case 1:
                    result = _a.sent();
                    if (!result.isSuccess) {
                        message = result.err.message;
                        showCommonErrorNotification({ message: message });
                        return [2 /*return*/];
                    }
                    return [2 /*return*/, result.body];
            }
        });
    }); }, [dispatchFetchDocumentReUploadUrl]);
    return {
        handleUploadFile: handleUploadFile,
        handleUploadInquiryFile: handleUploadInquiryFile,
        handleReUploadFile: handleReUploadFile,
        handleShippingReUploadFile: handleShippingReUploadFile,
        handleUploadDocumentFile: handleUploadDocumentFile,
        handleReUploadDocumentFile: handleReUploadDocumentFile,
        getDocumentUploadInfo: getDocumentUploadInfo,
    };
};
