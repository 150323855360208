var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import _get from 'lodash/get';
import _set from 'lodash/set';
import _isNil from 'lodash/isNil';
import { LOCAL_STORAGE_KEYWORD, PIN_COLUMN } from '../../shared/constants';
import { Col, Dropdown, Row, Space } from 'antd';
import { MoreOutlined, PushpinFilled } from '@ant-design/icons';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
var CustomColumnHeader = function (_a) {
    var text = _a.text, _b = _a.columnIndex, columnIndex = _b === void 0 ? '' : _b, onPinChanged = _a.onPinChanged, _c = _a.localStorageKey, localStorageKey = _c === void 0 ? LOCAL_STORAGE_KEYWORD.INSPECTION_PIN_COLUMN : _c, _d = _a.isAllowPin, isAllowPin = _d === void 0 ? false : _d;
    var t = useTranslation('components', { keyPrefix: 'commons' }).t;
    // Auto set inspection pin to local storage
    useEffect(function () {
        if (!isAllowPin)
            return;
        var getInspectionLocalStorage = localStorage.getItem(localStorageKey);
        if (_isNil(getInspectionLocalStorage)) {
            var inspectionPin = {
                left: [],
                right: [],
            };
            localStorage.setItem(localStorageKey, JSON.stringify(inspectionPin));
        }
    }, []);
    var delay = function (delay) {
        return new Promise(function (res) { return setTimeout(res, delay); });
    };
    var onClickPinHandler = function (position, key) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, delay(100).then(function () {
                        var getInspectionLocalStorage = localStorage.getItem(localStorageKey);
                        if (_isNil(getInspectionLocalStorage))
                            return;
                        var inspectionPin = JSON.parse(getInspectionLocalStorage);
                        var storagePosition = _get(inspectionPin, position, []);
                        storagePosition.push(key);
                        inspectionPin = _set(inspectionPin, position, storagePosition);
                        localStorage.setItem(localStorageKey, JSON.stringify(inspectionPin));
                        if (onPinChanged)
                            onPinChanged(true, key);
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var onClickUnPinHandler = function (key) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, delay(100).then(function () {
                        var getInspectionLocalStorage = localStorage.getItem(localStorageKey);
                        if (_isNil(getInspectionLocalStorage))
                            return;
                        var inspectionPin = JSON.parse(getInspectionLocalStorage);
                        inspectionPin.left = inspectionPin.left.filter(function (item) { return item !== key; });
                        inspectionPin.right = inspectionPin.right.filter(function (item) { return item !== key; });
                        localStorage.setItem(localStorageKey, JSON.stringify(inspectionPin));
                        if (onPinChanged)
                            onPinChanged(false, key);
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var renderHeader = function (title, index) {
        var getInspectionLocalStorage = localStorage.getItem(localStorageKey);
        var items = [
            {
                label: (_jsxs(Space, { onClick: function () { return onClickPinHandler(PIN_COLUMN.LEFT, index); }, children: [_jsx(PushpinFilled, {}), " ", t('column.pinLeft')] })),
                key: '0',
            },
            {
                label: (_jsxs(Space, { onClick: function () { return onClickPinHandler(PIN_COLUMN.RIGHT, index); }, children: [_jsx(PushpinFilled, { style: { transform: 'rotate(290deg)' } }), " ", t('column.pinRight')] })),
                key: '1',
            },
        ];
        var pinnedInfo = {
            left: {
                isPinned: false,
                color: { color: 'unset' },
            },
            right: {
                isPinned: false,
                color: { color: 'unset' },
            },
        };
        if (getInspectionLocalStorage !== undefined && !_isNil(getInspectionLocalStorage)) {
            var inspectionPin = JSON.parse(getInspectionLocalStorage);
            if (inspectionPin.left.indexOf(index) >= 0) {
                pinnedInfo.left.color = { color: '#1677ff' };
                pinnedInfo.left.isPinned = true;
            }
            if (inspectionPin.right.indexOf(index) >= 0) {
                pinnedInfo.right.color = { color: '#1677ff' };
                pinnedInfo.right.isPinned = true;
            }
        }
        return (_jsxs(Row, { wrap: false, children: [_jsx(Col, { span: 22, children: title }), _jsxs(Col, { span: 2, style: { textAlign: 'right' }, children: [pinnedInfo.left.isPinned && (_jsx(PushpinFilled, { style: __assign(__assign({}, pinnedInfo.left.color), { cursor: 'pointer' }), onClick: function () {
                                onClickUnPinHandler(index);
                                if (!pinnedInfo.left.isPinned)
                                    onClickPinHandler(PIN_COLUMN.LEFT, index);
                            } })), pinnedInfo.right.isPinned && (_jsx(PushpinFilled, { style: __assign(__assign({}, pinnedInfo.right.color), { transform: 'rotate(270deg)', cursor: 'pointer' }), onClick: function () {
                                onClickUnPinHandler(index);
                                if (!pinnedInfo.right.isPinned)
                                    onClickPinHandler(PIN_COLUMN.RIGHT, index);
                            } })), !pinnedInfo.right.isPinned && !pinnedInfo.left.isPinned && (_jsx(Dropdown, { menu: { items: items }, trigger: ['click'], destroyPopupOnHide: true, children: _jsx(MoreOutlined, { onClick: function (e) { return e.preventDefault(); } }) }))] })] }));
    };
    return isAllowPin ? (renderHeader(text, columnIndex)) : (_jsx(Row, { wrap: false, children: _jsx(Col, { span: 24, children: text }) }));
};
export { CustomColumnHeader };
