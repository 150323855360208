var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-lines */
import { useMemo } from 'react';
import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty';
import { Table } from '../commons/Table';
import { date } from '../../shared/date';
import { useTranslation } from 'react-i18next';
import { useAppI18n } from '../../contexts/AppI18nContext';
import { Space } from 'antd';
import { CustomColumnHeader } from '../commons/CustomColumnHeader';
import { Status } from './Status';
import { ListItem } from './ListItem';
import { ButtonDownload } from './ButtonDownload';
import styled from 'styled-components';
var WrapableDiv = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  overflow-wrap: break-word;\n  word-break: break-word;\n  word-wrap: break-word;\n  white-space: pre-line;\n  max-height: 60px;\n  overflow: auto;\n"], ["\n  overflow-wrap: break-word;\n  word-break: break-word;\n  word-wrap: break-word;\n  white-space: pre-line;\n  max-height: 60px;\n  overflow: auto;\n"])));
var breakWork = { wordWrap: 'break-word', wordBreak: 'break-word' };
var GridView = function (props) {
    var t = useTranslation('components', { keyPrefix: 'exportData' }).t;
    var tCommon = useTranslation('components', { keyPrefix: 'commons' }).t;
    var layout = useAppI18n().layout;
    var isMobile = layout.isMobileLayoutOnPC || layout.isMobileScreenSize || layout.isMobileLayout;
    var renderWithDevice = function () {
        if (!isMobile) {
            var addColumnToTable = [
                {
                    title: _jsx(CustomColumnHeader, { text: t('dataList.exportedFileId') }),
                    width: 120,
                    dataIndex: 'exportedFileId',
                    render: function (_, item) { return _jsx(_Fragment, { children: item.exportedFileId }); },
                },
                {
                    title: _jsx(CustomColumnHeader, { text: t('dataList.filePath') }),
                    width: 150,
                    dataIndex: 'filePath',
                    render: function (_, item) {
                        return _jsx("div", { style: breakWork, children: item.filePath });
                    },
                },
                {
                    title: _jsx(CustomColumnHeader, { text: t('dataList.placingOrderNumber') }),
                    width: 200,
                    dataIndex: 'metaData',
                    render: function (_, item) {
                        var _a;
                        return (_jsx(WrapableDiv, { children: (_a = item.metaData.placingOrderNumbers) === null || _a === void 0 ? void 0 : _a.join(', ') }));
                    },
                },
                {
                    title: _jsx(CustomColumnHeader, { text: t('dataList.drawingNumber') }),
                    width: 200,
                    dataIndex: 'metaData',
                    render: function (_, item) {
                        var _a;
                        return (_jsx(WrapableDiv, { children: (_a = item.metaData.drawingNumbers) === null || _a === void 0 ? void 0 : _a.join(', ') }));
                    },
                },
                {
                    title: _jsx(CustomColumnHeader, { text: t('dataList.exportedBy') }),
                    width: 200,
                    dataIndex: 'exportedBy',
                    render: function (_, item) { return _jsx("div", { style: breakWork, children: item.exportBy }); },
                },
                {
                    title: _jsx(CustomColumnHeader, { text: t('dataList.exportedAt') }),
                    dataIndex: 'createdAt',
                    width: 100,
                    render: function (_, item) { return (_jsx("div", { style: breakWork, children: date.format(item.createdAt, tCommon('format.formatDate')) })); },
                },
                {
                    title: _jsx(CustomColumnHeader, { text: t('dataList.status') }),
                    width: 100,
                    dataIndex: 'status',
                    render: function (_, item) { return _jsx(Status, { status: item.status }); },
                },
                {
                    title: '',
                    width: 100,
                    render: function (_, item) {
                        return (_jsx(Space, { children: _jsx(ButtonDownload, { item: item }) }));
                    },
                },
            ];
            return addColumnToTable;
        }
        else {
            return [
                {
                    title: '',
                    dataIndex: 'exportData',
                    width: '100%',
                    render: function (_, item) { return (_jsx(ListItem, { onSuccessed: function () { return (props.onSuccessed ? props.onSuccessed() : undefined); }, onProgressing: function () { return (props.onProgressing ? props.onProgressing() : undefined); }, item: item })); },
                },
            ];
        }
    };
    var columns = useMemo(function () { return renderWithDevice(); }, [renderWithDevice, props]);
    var dataListWithKey = useMemo(function () {
        return props.dataList && !_isNil(props.dataList)
            ? props.dataList.map(function (f) { return (__assign(__assign({}, f), { key: f.exportedFileId })); })
            : [];
    }, [props.dataList]);
    return (_jsx("div", { "data-testid": "table-contain-list", style: { width: layout.isMobileScreenSize ? 'calc(100% - 20px)' : '100%' }, children: columns !== undefined && (_jsx(Table, { locale: { emptyText: tCommon('message.emptyData') }, columns: columns, dataSource: dataListWithKey, pagination: isMobile ? (_isEmpty(dataListWithKey) ? false : props.pagination) : false, onChange: props.onChange, loading: props.loading, scroll: !isMobile ? { x: 'max-content' } : undefined, tableLayout: _isEmpty(dataListWithKey) ? 'fixed' : isMobile ? 'fixed' : 'auto' })) }));
};
export { GridView };
var templateObject_1;
