var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint max-lines: [error, 400] */
import styled from 'styled-components';
import { Button, Form, Upload, Drawer } from 'antd';
import _last from 'lodash/last';
import _isNull from 'lodash/isNull';
import _isEmpty from 'lodash/isEmpty';
import { useRef, useState } from 'react';
import arrowLeft from '../icons/left-arrow-border.svg';
import { INSPECTION_FILE_ACCEPT, MAX_PREVIEW_UPLOAD_COUNT, MAX_UPLOAD_COUNT, getBase64, getMaxFileSizeMbByFileType, } from '../../shared/fileUtils';
import { useTranslation } from 'react-i18next';
import { UPLOAD_FEATURE, } from '../../shared/types';
import { validateFileSize } from '../../shared/validateFiles';
import { MOBILE_MAX_WIDTH } from '../../shared/constants';
import { showCommonErrorNotification } from '../../shared/logs';
import { useFileHandler, useDefectAttachmentFileHandler } from '../../hooks/upload';
import { WholePageLoading } from './Loading';
import PreviewFileLayout from './PreviewFile/PreviewFileLayout';
import { useAppI18n } from '../../contexts/AppI18nContext';
import { useBackgroundUpload } from '../../contexts/BackgroundUploadContext';
var PreviewActionWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  text-align: right;\n  float: right;\n  @media (max-width: ", "px) {\n    button {\n      font-size: 10px;\n    }\n  }\n"], ["\n  text-align: right;\n  float: right;\n  @media (max-width: ", "px) {\n    button {\n      font-size: 10px;\n    }\n  }\n"])), MOBILE_MAX_WIDTH);
var AddAnotherButton = styled(Button)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  &:focus {\n    border-color: #1890ff;\n    background: #1890ff;\n    outline: none;\n  }\n  &:hover {\n    border-color: #40a9ff;\n    background: #40a9ff;\n  }\n  @media (max-width: 1024px) {\n    &:hover {\n      border-color: #1890ff;\n      background: #1890ff;\n      outline: none;\n    }\n  }\n"], ["\n  &:focus {\n    border-color: #1890ff;\n    background: #1890ff;\n    outline: none;\n  }\n  &:hover {\n    border-color: #40a9ff;\n    background: #40a9ff;\n  }\n  @media (max-width: 1024px) {\n    &:hover {\n      border-color: #1890ff;\n      background: #1890ff;\n      outline: none;\n    }\n  }\n"])));
var DrawerStyle = styled(Drawer)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  .annotationLayer {\n    width: auto !important;\n    height: auto !important;\n  }\n  .ant-drawer-header {\n    position: fixed;\n    z-index: 9999;\n    width: 100%;\n  }\n"], ["\n  .annotationLayer {\n    width: auto !important;\n    height: auto !important;\n  }\n  .ant-drawer-header {\n    position: fixed;\n    z-index: 9999;\n    width: 100%;\n  }\n"])));
function DrawerPreView(props) {
    var _this = this;
    var uploadFeature = props.uploadFeature, _a = props.fileAccept, fileAccept = _a === void 0 ? INSPECTION_FILE_ACCEPT : _a;
    var handleUploadFile = useFileHandler().handleUploadFile;
    var handleUploadDefectAttachmentFile = useDefectAttachmentFileHandler().handleUploadDefectAttachmentFile;
    var t = useTranslation('components', { keyPrefix: 'commons' }).t;
    var formPreview = Form.useForm()[0];
    var _b = useState(null), fileUploads = _b[0], setFileUploads = _b[1];
    var _c = useState(null), filePaths = _c[0], setFilePaths = _c[1];
    var _d = useState(false), loading = _d[0], setLoading = _d[1];
    var _e = useState(false), isProcessing = _e[0], setIsProcessing = _e[1];
    var btnSaveAndQuitRef = useRef(null);
    var addQueue = useBackgroundUpload().addQueue;
    var layout = useAppI18n().layout;
    var isMobile = layout.isMobileLayoutOnPC || layout.isMobileScreenSize || layout.isMobileLayout;
    var saveFileChange = function () { return __awaiter(_this, void 0, void 0, function () {
        var _this = this;
        return __generator(this, function (_a) {
            // prevent multiple click at the same time.
            if (isProcessing === true) {
                return [2 /*return*/, false];
            }
            setLoading(true);
            setIsProcessing(true);
            if (props.onProgressing)
                props.onProgressing();
            if (!fileUploads || fileUploads.length === 0) {
                setIsProcessing(false);
                setLoading(false);
                return [2 /*return*/, false];
            }
            fileUploads.map(function (file) {
                var handle = function () { return __awaiter(_this, void 0, void 0, function () {
                    var _a;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0: return [4 /*yield*/, (uploadFeature === UPLOAD_FEATURE.SHIPPING
                                    ? handleUploadFile(props.photoType, props.belongsId, [file], uploadFeature, (_a = props.sequence) !== null && _a !== void 0 ? _a : 1)
                                    : uploadFeature === UPLOAD_FEATURE.DEFECT
                                        ? handleUploadDefectAttachmentFile(props.belongsId, [file])
                                        : handleUploadFile(props.photoType, props.belongsId, [file], uploadFeature)).then(function (photoItems) {
                                    if (props.onSuccessed)
                                        props.onSuccessed(photoItems);
                                    if (props.setHasUploadSuccess)
                                        props.setHasUploadSuccess(true);
                                    return photoItems.length > 0;
                                })];
                            case 1:
                                _b.sent();
                                return [2 /*return*/];
                        }
                    });
                }); };
                addQueue(handle);
            });
            setIsProcessing(false);
            setLoading(false);
            return [2 /*return*/, true];
        });
    }); };
    var listUpload = [];
    var handleChange = function (info) {
        var _a;
        var fileUpload = info.file;
        var maxFileSizeMb = getMaxFileSizeMbByFileType(fileUpload.type);
        var isValid = validateFileSize(fileUpload.size, maxFileSizeMb);
        if (!isValid) {
            showCommonErrorNotification(new Error("".concat(fileUpload.name, "::") + t('uploadFile.exceed-message', { maxSize: maxFileSizeMb })));
        }
        else {
            listUpload.push(fileUpload);
        }
        // event onchange will run from first file to last file.
        // if run to the end of list files, we will process for next step
        if (info.file.uid === ((_a = _last(info.fileList)) === null || _a === void 0 ? void 0 : _a.uid) && listUpload.length) {
            if (listUpload.length > MAX_UPLOAD_COUNT) {
                showCommonErrorNotification(new Error(t('uploadFile.max-count-message', { maxCount: MAX_UPLOAD_COUNT })));
            }
            else {
                // save list previous files if have at least one file valid
                (function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, saveFileChange()];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); })();
                setFileUploads(listUpload);
                (function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, convertListFileToListFilePath(listUpload)];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); })();
                props.setPreviewFiles(null);
            }
            listUpload = [];
            // reset form
            formPreview.resetFields();
            return;
        }
    };
    var afterOpenChangeDrawer = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(fileUploads === null && props.previewFiles)) return [3 /*break*/, 2];
                    setFileUploads(props.previewFiles);
                    return [4 /*yield*/, convertListFileToListFilePath(props.previewFiles)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var convertListFileToListFilePath = function (files) { return __awaiter(_this, void 0, void 0, function () {
        var listFilePath, isPreviewContent, _i, files_1, file, _a, _b, _c;
        var _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    listFilePath = [];
                    isPreviewContent = files.length <= MAX_PREVIEW_UPLOAD_COUNT;
                    _i = 0, files_1 = files;
                    _e.label = 1;
                case 1:
                    if (!(_i < files_1.length)) return [3 /*break*/, 6];
                    file = files_1[_i];
                    if (!(!_isEmpty(file) && file.type !== undefined)) return [3 /*break*/, 5];
                    _b = (_a = listFilePath).push;
                    _d = {
                        uid: file.uid,
                        name: file.name
                    };
                    if (!isPreviewContent) return [3 /*break*/, 3];
                    return [4 /*yield*/, getBase64(file)];
                case 2:
                    _c = _e.sent();
                    return [3 /*break*/, 4];
                case 3:
                    _c = '';
                    _e.label = 4;
                case 4:
                    _b.apply(_a, [(_d.path = _c,
                            _d.type = file.type,
                            _d)]);
                    _e.label = 5;
                case 5:
                    _i++;
                    return [3 /*break*/, 1];
                case 6:
                    // disable button save and quit if remove all images in preview screen
                    if (btnSaveAndQuitRef.current) {
                        btnSaveAndQuitRef.current.disabled = listFilePath.length === 0; // set enable / disable depends listFilePath.length
                    }
                    setFilePaths(listFilePath);
                    return [2 /*return*/];
            }
        });
    }); };
    var removeFile = function (uid) { return __awaiter(_this, void 0, void 0, function () {
        var fileUploadsTmp, fileUploadsTmp_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(!_isNull(fileUploads) && (fileUploads === null || fileUploads === void 0 ? void 0 : fileUploads.length))) return [3 /*break*/, 4];
                    fileUploadsTmp = fileUploads.filter(function (file) { return file.uid !== uid; });
                    setFileUploads(fileUploadsTmp);
                    if (props.onProgressing)
                        props.onProgressing();
                    if (!(fileUploadsTmp.length === 0)) return [3 /*break*/, 1];
                    onCloseDrawer();
                    return [3 /*break*/, 4];
                case 1:
                    if (!(fileUploadsTmp.length === 1)) return [3 /*break*/, 3];
                    return [4 /*yield*/, convertListFileToListFilePath(fileUploadsTmp).then(function (res) {
                            if (props.onSuccessed)
                                props.onSuccessed([]);
                            return res;
                        })];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    fileUploadsTmp_1 = (filePaths || []).filter(function (file) { return file.uid !== uid; });
                    setFilePaths(fileUploadsTmp_1);
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var onCloseDrawer = function () {
        listUpload = [];
        setFilePaths(null);
        setFileUploads(null);
        props.setPreviewFiles(null);
        props.setPreviewOpen(false);
        formPreview.resetFields();
        if (btnSaveAndQuitRef.current) {
            btnSaveAndQuitRef.current.disabled = false;
        }
    };
    var onSubmitSaveAndQuit = function () {
        (function () { return __awaiter(_this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, saveFileChange()];
                    case 1:
                        result = _a.sent();
                        if (result) {
                            onCloseDrawer();
                        }
                        return [2 /*return*/];
                }
            });
        }); })();
    };
    var normFile = function () {
        return;
    };
    var renderPreviewButton = function () {
        return (_jsx(PreviewActionWrapper, { "data-testid": "previewActionWrapper", children: _jsxs(Form, { "data-testid": "actionDrawerPreviewForm", layout: "inline", form: formPreview, onFinish: onSubmitSaveAndQuit, children: [_jsx(Form.Item, { name: "dragger", label: "", valuePropName: "fileList", getValueFromEvent: normFile, children: _jsx(Upload.Dragger, { name: "anotherFiles", listType: "picture", multiple: true, beforeUpload: function () { return false; }, accept: fileAccept, showUploadList: false, onChange: handleChange, style: { border: 'none', background: 'none' }, "data-testid": "anotherFiles", children: _jsx(AddAnotherButton, { type: "primary", disabled: isProcessing, children: t('drawerPreView.addAnother') }) }) }), _jsx(Form.Item, { label: "", style: { paddingTop: '16px' }, children: _jsx(Button, { type: "primary", htmlType: "submit", disabled: isProcessing, ref: btnSaveAndQuitRef, children: t('drawerPreView.saveAndQuit') }) })] }) }));
    };
    return (_jsxs(DrawerStyle, { open: props.previewOpen, onClose: onCloseDrawer, width: "100%", style: { height: 'calc(100vh + 100px)' }, closeIcon: _jsx("span", { children: _jsx("img", { "data-testid": "closeDrawer", src: arrowLeft, alt: "", style: { width: '40px', background: 'white' } }) }), styles: { header: { border: 'none' }, body: { paddingTop: isMobile ? '90px' : '120px' } }, afterOpenChange: afterOpenChangeDrawer, extra: renderPreviewButton(), children: [loading && _jsx(WholePageLoading, {}), _jsx(PreviewFileLayout, { filePaths: filePaths, removeFile: removeFile })] }));
}
export default DrawerPreView;
var templateObject_1, templateObject_2, templateObject_3;
