import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import _get from 'lodash/get';
import { Button, Result } from 'antd';
import { generatePath, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTE_LOGOUT } from '../../shared/urls';
var PageRefreshAuthorized = function (props) {
    var t = useTranslation('components', { keyPrefix: 'commons' }).t;
    var title = _get(props, 'title', t('pageRefreshAuthorized.title'));
    var message = _get(props, 'subTitle', t('pageRefreshAuthorized.msg'));
    return (_jsx(Result, { status: 403, title: title, subTitle: message, extra: _jsx(_Fragment, { children: _jsx(Link, { to: generatePath(ROUTE_LOGOUT), style: { textDecoration: 'underline' }, children: _jsx(Button, { type: "primary", style: { borderColor: 'none' }, children: t('menubar.logout') }) }) }), style: { height: '100vh' } }));
};
export { PageRefreshAuthorized };
