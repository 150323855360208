var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { notification } from 'antd';
import { createT } from '../i18n/utils';
import _orderBy from 'lodash/orderBy';
var t = createT({ namespace: 'shared' });
var sendError = function (error) {
    if (process.env.ENVIRONMENT !== 'birdie-prod') {
        // eslint-disable-next-line no-console
        console.error({ error: error });
    }
};
var showCommonErrorNotification = function (e) {
    notification.open({
        type: 'error',
        message: t('logs.showCommonErrorNotification.message'),
        description: e.message,
        duration: 5, // Appear time in seconds before Notification is closed.
    });
};
var showCommonSuccessNotification = function (e) {
    notification.open({
        type: 'success',
        message: e.message,
    });
};
var appendLogsToList = function (currItems, newItems) {
    var newItemList = Array.from(__spreadArray(__spreadArray([], currItems, true), newItems, true).reduce(function (r, o) { return r.set(o.logId, o); }, new Map()).values());
    return _orderBy(newItemList, ['logId'], ['desc']);
};
export { sendError, showCommonErrorNotification, showCommonSuccessNotification, appendLogsToList };
