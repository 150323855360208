var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { Table as AntdTable } from 'antd';
import { useAppI18n } from '../../contexts/AppI18nContext';
import { MOBILE_MAX_WIDTH, IPAD_MAX_WIDTH } from '../../shared/constants';
import { useLayoutEffect, useRef, useState } from 'react';
import useResizeObserver from 'use-resize-observer';
var StyledTable = styled(AntdTable)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .ant-pagination-simple-pager {\n    min-width: 120px;\n    text-align: center;\n  }\n  @media (min-width: ", "px) and (max-width: ", "px) {\n    .ant-pagination .ant-pagination-options {\n      display: none;\n    }\n  }\n  .paginationEndScrollPage {\n    margin-bottom: 100px !important;\n  }\n  .ant-pagination.ant-pagination-simple.ant-table-pagination.ant-table-pagination-center,\n  .ant-pagination.ant-pagination-simple.ant-table-pagination.ant-table-pagination-right,\n  .ant-pagination.ant-pagination-simple.ant-table-pagination.ant-table-pagination-left {\n    margin-bottom: ", "px;\n  }\n"], ["\n  .ant-pagination-simple-pager {\n    min-width: 120px;\n    text-align: center;\n  }\n  @media (min-width: ", "px) and (max-width: ", "px) {\n    .ant-pagination .ant-pagination-options {\n      display: none;\n    }\n  }\n  .paginationEndScrollPage {\n    margin-bottom: 100px !important;\n  }\n  .ant-pagination.ant-pagination-simple.ant-table-pagination.ant-table-pagination-center,\n  .ant-pagination.ant-pagination-simple.ant-table-pagination.ant-table-pagination-right,\n  .ant-pagination.ant-pagination-simple.ant-table-pagination.ant-table-pagination-left {\n    margin-bottom: ", "px;\n  }\n"])), MOBILE_MAX_WIDTH, IPAD_MAX_WIDTH, function (props) { return props.theme.footerHeight; });
var StyledDivWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"])));
var Table = function (props) {
    var _a = props.showHeader, showHeader = _a === void 0 ? true : _a, _b = props.isFrozenHeader, isFrozenHeader = _b === void 0 ? true : _b;
    var layout = useAppI18n().layout;
    var isMobile = layout.isMobileLayoutOnPC || layout.isMobileScreenSize;
    var isAdminScreen = layout.isLayoutSiteAdmin;
    var tableRef = useRef(null);
    var refResize = useResizeObserver({
        onResize: function (_) {
            generateTableHeight(tableRef);
        },
    }).ref;
    var paginationPaddingBottom = 10;
    var paginationBoxHeight = 30;
    var tableFooterHeight = paginationBoxHeight + paginationPaddingBottom;
    var _c = useState(0), tableScrollLength = _c[0], setTableScrollLength = _c[1];
    var generateTableHeight = function (tableRef) {
        if (!tableRef.current)
            return;
        var top = tableRef.current.nativeElement.getBoundingClientRect().top;
        var tableHeaderHeight = showHeader ? (isAdminScreen ? 90 : 60) : 0;
        var customHeight = window.innerHeight - top - tableHeaderHeight;
        if (!isMobile) {
            customHeight -= tableFooterHeight;
        }
        if (customHeight < 250)
            customHeight = 250;
        setTableScrollLength(customHeight);
    };
    useLayoutEffect(function () {
        generateTableHeight(tableRef);
    }, [tableRef, props.isFreshHeight]);
    return (_jsx(StyledDivWrapper, { ref: refResize, className: isMobile ? 'wrap-table-mobile' : 'wrap-table-desktop', children: _jsx(StyledTable, { theme: { footerHeight: tableFooterHeight }, ref: tableRef, onRow: props.onRow, showHeader: props.showHeader, bordered: props.bordered, locale: props.locale, columns: props.columns, dataSource: props.dataSource, rowKey: props.rowKey, pagination: isMobile && props.pagination !== false
                ? __assign(__assign({}, props.pagination), { simple: true }) : props.pagination, onChange: props.onChange, tableLayout: props.tableLayout ? props.tableLayout : 'auto', loading: props.loading, scroll: __assign({ y: isFrozenHeader ? tableScrollLength : undefined }, props.scroll), expandable: props.expandable, rowSelection: props.rowSelection }) }));
};
export { Table };
var templateObject_1, templateObject_2;
