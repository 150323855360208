export var SUPPORTED_LANGUAGES = ['ja', 'en', 'es', 'vi'];
export var SUPPORTED_TIMEZONES = [
    'Asia/Tokyo',
    'Asia/Ho_Chi_Minh',
    'America/Mexico_City',
];
export var SUPPORTED_USER_INTERFACE = { NORMAL: 'PC/Tablet', MOBILE: 'Mobile' };
export var SUPPORTED_LANGUAGES_INCLUDE_KEY = { JA: 'ja', EN: 'en', VI: 'vi' };
export var DEFAULT_LANGUAGE = 'ja';
export var DEFAULT_TIMEZONE = 'Asia/Tokyo';
export var DEFAULT_NAMESPACE = 'common';
export var TIMEZONE_OPTIONS = [
    {
        value: SUPPORTED_TIMEZONES[0],
        label: '(GMT+09:00) 日本基準時/Japan Standard Time',
    },
    {
        value: SUPPORTED_TIMEZONES[1],
        label: '(GMT+07:00) Indochina Time/Ho Chi Minh City',
    },
    {
        value: SUPPORTED_TIMEZONES[2],
        label: '(GMT-06:00) CST - Mexico City',
    },
];
