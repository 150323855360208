// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-pagination li {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  position: relative;
  margin: 0;
}
.ant-pagination li.ant-pagination-item-active,
.ant-pagination li.ant-pagination-item,
.ant-pagination li.ant-pagination-item a {
  border: 0;
  color: #2F333F;
}
.ant-pagination li a {
  width: 35px;
  padding: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.ant-pagination li.ant-pagination-item-active,
.ant-pagination li.ant-pagination-item:hover {
  background-color: rgba(0, 0, 0, 0.12);
}
.ant-pagination .ant-pagination-item-link {
  border: 0;
  border-radius: 50%;
}
.ant-pagination .ant-pagination-item-link:hover {
  background-color: rgba(0, 0, 0, 0.12);
  color: #2F333F;
}
.ant-pagination .ant-pagination-disabled:hover button {
  background-color: inherit;
}
.ant-pagination .ant-pagination-jump-prev a,
.ant-pagination .ant-pagination-jump-next a {
  left: 0;
}
.ant-pagination .ant-pagination-jump-prev a:hover,
.ant-pagination .ant-pagination-jump-next a:hover {
  background-color: inherit;
  color: #2F333F;
}
`, "",{"version":3,"sources":["webpack://./src/components/InspectionResult/Pagination.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,SAAS;AACX;AACA;;;EAGE,SAAS;EACT,cAAc;AAChB;AACA;EACE,WAAW;EACX,UAAU;EACV,kBAAkB;EAClB,QAAQ;EACR,+BAA+B;EAC/B,2BAA2B;AAC7B;AACA;;EAEE,qCAAqC;AACvC;AACA;EACE,SAAS;EACT,kBAAkB;AACpB;AACA;EACE,qCAAqC;EACrC,cAAc;AAChB;AACA;EACE,yBAAyB;AAC3B;AACA;;EAEE,OAAO;AACT;AACA;;EAEE,yBAAyB;EACzB,cAAc;AAChB","sourcesContent":[".ant-pagination li {\n  border-radius: 50%;\n  width: 35px;\n  height: 35px;\n  position: relative;\n  margin: 0;\n}\n.ant-pagination li.ant-pagination-item-active,\n.ant-pagination li.ant-pagination-item,\n.ant-pagination li.ant-pagination-item a {\n  border: 0;\n  color: #2F333F;\n}\n.ant-pagination li a {\n  width: 35px;\n  padding: 0;\n  position: absolute;\n  top: 50%;\n  -ms-transform: translateY(-50%);\n  transform: translateY(-50%);\n}\n.ant-pagination li.ant-pagination-item-active,\n.ant-pagination li.ant-pagination-item:hover {\n  background-color: rgba(0, 0, 0, 0.12);\n}\n.ant-pagination .ant-pagination-item-link {\n  border: 0;\n  border-radius: 50%;\n}\n.ant-pagination .ant-pagination-item-link:hover {\n  background-color: rgba(0, 0, 0, 0.12);\n  color: #2F333F;\n}\n.ant-pagination .ant-pagination-disabled:hover button {\n  background-color: inherit;\n}\n.ant-pagination .ant-pagination-jump-prev a,\n.ant-pagination .ant-pagination-jump-next a {\n  left: 0;\n}\n.ant-pagination .ant-pagination-jump-prev a:hover,\n.ant-pagination .ant-pagination-jump-next a:hover {\n  background-color: inherit;\n  color: #2F333F;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
