import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
/**
 * References https://day.js.org/docs/en/plugin/timezone
 */
dayjs.extend(utc);
dayjs.extend(timezone);
var setGlobalTimezone = function (nextTimezone) {
    dayjs.tz.setDefault(nextTimezone);
};
var date = {
    dayjs: dayjs,
    format: function (value, format) {
        return value ? dayjs(value).tz().format(format) : '';
    },
};
export { date, setGlobalTimezone };
