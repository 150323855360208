import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { VerticalAlignBottomOutlined } from '@ant-design/icons';
import { ExportedFileStatus } from '../../shared/exportData';
import { ROUTE_NOT_FOUND } from '../../shared/urls';
var ButtonDownload = function (props) {
    var t = useTranslation('components', { keyPrefix: 'commons' }).t;
    var item = props.item, _a = props.type, type = _a === void 0 ? 'default' : _a;
    var handleDownload = function () {
        var openUrl = (item === null || item === void 0 ? void 0 : item.signedUrl) || ROUTE_NOT_FOUND;
        window.open(openUrl, '_blank');
    };
    return (_jsx(_Fragment, { children: _jsxs(Button, { type: type, disabled: item.status !== ExportedFileStatus.Finished, "data-testid": "btn-download-importCSV-".concat(item.exportedFileId), style: { textTransform: 'uppercase' }, onClick: function () { return handleDownload(); }, children: [_jsx(VerticalAlignBottomOutlined, {}), " ", t('buttons.download')] }) }));
};
export { ButtonDownload };
