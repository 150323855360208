import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Col, Row } from 'antd';
import { useAppI18n } from '../../contexts/AppI18nContext';
var CustomPageHeader = function (_a) {
    var adminText = _a.adminText, text = _a.text, children = _a.children, className = _a.className, _b = _a.isAdminSide, isAdminSide = _b === void 0 ? false : _b, _c = _a.columnQuantity, columnQuantity = _c === void 0 ? 2 : _c;
    var layout = useAppI18n().layout;
    var isMobile = layout.isMobileLayoutOnPC || layout.isMobileScreenSize || layout.isMobileLayout;
    return (_jsx(Row, { className: className, style: { width: layout.isMobileScreenSize ? 'calc(100% - 20px)' : '100%' }, children: columnQuantity === 1 ? (_jsx(Col, { span: 24, children: isAdminSide ? (_jsx("h1", { style: isMobile ? { fontSize: '18px', marginBottom: '0' } : {}, children: adminText })) : (text) })) : (_jsxs(_Fragment, { children: [_jsx(Col, { span: isMobile ? 15 : 18, style: { textTransform: 'capitalize' }, children: isAdminSide ? (_jsx("h1", { style: isMobile ? { fontSize: '18px', marginBottom: '0' } : {}, children: adminText })) : (text) }), _jsx(Col, { span: isMobile ? 9 : 6, style: { textAlign: 'right', paddingRight: '10px' }, children: children })] })) }));
};
export { CustomPageHeader };
