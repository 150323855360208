var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Card, Col, Result, Row, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { date } from '../../shared/date';
import { WholePageLoading } from './Loading';
import { Link, generatePath } from 'react-router-dom';
import { ROUTE_HOME } from '../../shared/urls';
import { useMaintenanceDetailHooks } from '../../hooks/Maintenance/useMaintenanceDetailHooks';
import { useEffect, useState } from 'react';
var Text = Typography.Text;
var UnderMaintenance = function () {
    var _a = useState(true), loading = _a[0], setLoading = _a[1];
    var _b = useState(), maintenance = _b[0], setMaintenance = _b[1];
    var t = useTranslation('components', { keyPrefix: 'commons' }).t;
    var timmer;
    var maintenanceDetailHookHandler = useMaintenanceDetailHooks().maintenanceDetailHookHandler;
    var fetchMaintenance = function () { return __awaiter(void 0, void 0, void 0, function () {
        var maintenance;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, maintenanceDetailHookHandler()];
                case 1:
                    maintenance = _a.sent();
                    if (maintenance === null || maintenance === void 0 ? void 0 : maintenance.isOnMaintenance) {
                        timmer = setTimeout(fetchMaintenance, 5 * 60 * 1000);
                    }
                    else {
                        if (timmer)
                            clearTimeout(timmer);
                    }
                    setMaintenance(maintenance);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        // Fetch data with whole page loading at first time
        setLoading(true);
        fetchMaintenance().finally(function () { return setLoading(false); });
    }, [maintenanceDetailHookHandler]);
    return (_jsx(_Fragment, { children: loading ? (_jsx(WholePageLoading, {})) : (_jsx(Result, { status: 403, title: (maintenance === null || maintenance === void 0 ? void 0 : maintenance.isOnMaintenance) ? t('underMaintenance.title') : '', subTitle: _jsx(Row, { gutter: 16, justify: "center", align: "middle", children: _jsx(Col, { span: 8, children: _jsx(Text, { type: "secondary", children: (maintenance === null || maintenance === void 0 ? void 0 : maintenance.isOnMaintenance)
                            ? t('underMaintenance.msgUnderMaintenance')
                            : t('underMaintenance.msgMaintenanceFinished') }) }) }), extra: _jsx(_Fragment, { children: _jsxs(Space, { direction: "vertical", size: "middle", style: { display: 'flex' }, children: [(maintenance === null || maintenance === void 0 ? void 0 : maintenance.isOnMaintenance) && (maintenance === null || maintenance === void 0 ? void 0 : maintenance.to) && (_jsx(Row, { gutter: 16, justify: "center", align: "middle", children: _jsx(Col, { children: _jsx(Card, { title: t('underMaintenance.scheduleTitle'), bordered: false, children: _jsx(Text, { style: { fontSize: 18 }, children: date.format(maintenance === null || maintenance === void 0 ? void 0 : maintenance.to, t('format.YYYYMMDDHHmm')) }) }) }) })), _jsx(Row, { justify: "center", align: "middle", children: _jsx(Link, { to: generatePath(ROUTE_HOME), children: _jsx(Button, { type: "primary", loading: loading, disabled: maintenance === null || maintenance === void 0 ? void 0 : maintenance.isOnMaintenance, children: t('buttons.backToHome') }) }) })] }) }), style: { height: '100vh' } })) }));
};
export { UnderMaintenance };
