import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect, createContext, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { defineAbilityFor, resolveUserRole } from '../auth/abilities';
import { matchRoutes, useLocation } from 'react-router-dom';
import { ALL_ROUTES } from '../shared/urls';
import { isSiteAdmin } from '../shared/helpers';
var initialState = {
    isReady: false,
    user: undefined,
    currentRoute: null,
    isAdminSite: false,
};
var AppContext = createContext(initialState);
export var useApp = function () { return useContext(AppContext); };
var getCurrentRoutePattern = function (location) {
    var routes = ALL_ROUTES.map(function (route) { return ({ path: route }); });
    var matchedRoutes = matchRoutes(routes, location);
    if (!matchedRoutes) {
        return null;
    }
    var route = matchedRoutes[0].route;
    return route.path;
};
export var AppProvider = function (props) {
    var user = useAuth0().user;
    var _a = useState(initialState), state = _a[0], setState = _a[1];
    var location = useLocation();
    useEffect(function () {
        var userEmail = user === null || user === void 0 ? void 0 : user.email;
        if (!userEmail)
            return;
        var currentRoute = getCurrentRoutePattern(location);
        // Resolve role and define ability for user
        var isAdminSite = isSiteAdmin(currentRoute !== null && currentRoute !== void 0 ? currentRoute : '');
        var isBaseOnMetadata = !isAdminSite;
        var resolvedUser = resolveUserRole(user, isBaseOnMetadata);
        var ability = defineAbilityFor(resolvedUser);
        resolvedUser.ability = ability;
        setState({
            isReady: true,
            user: resolvedUser,
            currentRoute: currentRoute,
            isAdminSite: isAdminSite,
        });
    }, [user === null || user === void 0 ? void 0 : user.email, location.pathname]);
    return _jsx(AppContext.Provider, { value: state, children: props.children });
};
