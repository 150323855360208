var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable max-lines */
import { date } from '../../shared/date';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { Status } from './Status';
import { ButtonDownload } from './ButtonDownload';
var RowStyle = styled(Row)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 10px;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.12);\n"], ["\n  padding: 10px;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.12);\n"])));
var ListItem = function (props) {
    var _a, _b;
    var item = props.item;
    var t = useTranslation('components', { keyPrefix: 'exportData' }).t;
    var tCommon = useTranslation('components', { keyPrefix: 'commons' }).t;
    return (_jsx(_Fragment, { children: _jsxs("div", { "data-testid": "export-data-item", style: {
                fontSize: '16px',
                background: '#fff',
                border: '1px solid rgba(0, 0, 0, 0.12)',
                borderRadius: '4px',
            }, children: [_jsxs(RowStyle, { align: "middle", wrap: false, children: [_jsx(Col, { span: 16, children: item.exportedFileId }), _jsxs(Col, { span: 8, children: [_jsx(Status, { status: item.status }), ' '] })] }), _jsx(RowStyle, { align: "middle", wrap: false, children: _jsxs(Col, { span: 24, children: [t('dataList.filePath'), ": ", item.filePath] }) }), _jsx(RowStyle, { align: "middle", wrap: false, children: _jsxs(Col, { span: 24, children: [t('dataList.placingOrderNumber'), ": ", (_a = item.metaData.placingOrderNumbers) === null || _a === void 0 ? void 0 : _a.join(', ')] }) }), _jsx(RowStyle, { align: "middle", wrap: false, children: _jsxs(Col, { span: 24, children: [t('dataList.drawingNumber'), ": ", (_b = item.metaData.drawingNumbers) === null || _b === void 0 ? void 0 : _b.join(', ')] }) }), _jsx(RowStyle, { align: "middle", wrap: false, children: _jsxs(Col, { span: 24, children: [t('dataList.exportedBy'), ": ", item.exportBy] }) }), _jsx(RowStyle, { align: "middle", wrap: false, children: _jsxs(Col, { span: 24, children: [t('dataList.exportedAt'), ": ", date.format(item.createdAt, tCommon('format.formatDate'))] }) }), _jsx(Row, { align: "middle", wrap: false, style: { padding: '10px' }, children: _jsx(Col, { span: 24, style: { textAlign: 'center' }, children: _jsx(ButtonDownload, { item: item }) }) })] }) }));
};
export { ListItem };
var templateObject_1;
