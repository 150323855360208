var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-lines */
import { useState } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { UserOutlined, TeamOutlined, HistoryOutlined, MenuOutlined, HomeOutlined, AppstoreAddOutlined, SendOutlined, FilePdfOutlined, GroupOutlined, ExceptionOutlined, ImportOutlined, } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import colors from '../../shared/colors';
import { useAppI18n } from '../../contexts/AppI18nContext';
import { SettingModal } from './SettingModal';
import { ABILITY_ACTION, DISABLED_FEATURES, MOBILE_NORMAL_WIDTH, PAGINATION_DEFAULT_PAGE, } from '../../shared/constants';
import { ROUTE_LOGS_LIST, ROUTE_USER_ACCOUNT_LIST, ROUTE_SUPPLIER_LIST, ROUTE_ADMIN_DASHBOARD, ROUTE_ADMIN_INSPECTION_RESULT_LIST, ROUTE_ADMIN_SHIPPING_LIST, ROUTE_ADMIN_DOCUMENT_LIST, ROUTE_ADMIN_INSPECTOR_COMPANY_LIST, ROUTE_ADMIN_CREATE_MANUAL_ONSCREEN_INSPECTION, ROUTE_ADMIN_MANAGER_TERMS_SENTENCES, ROUTE_ADMIN_INQUIRY, ROUTE_ADMIN_IMPORT_CSV, ROUTE_ADMIN_EXPORT_DATA, } from '../../shared/urls';
import { checkDisableFeatures } from '../../shared/helpers';
import { Menu as OriginMenu, Row } from 'antd';
import { useApp } from '../../contexts/AppContext';
import { useAppState, useInspectionInitAdvancedSearchFilter } from '../../contexts/AppStateContext';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 40px;\n  background: ", ";\n  position: relative;\n"], ["\n  width: 100%;\n  height: 40px;\n  background: ", ";\n  position: relative;\n"])), colors.darkNavy);
var Menu = styled(OriginMenu)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: white;\n  line-height: 38px;\n  border-bottom: 0;\n  background: none;\n  width: ", ";\n\n  .ant-menu-item:hover,\n  .ant-menu-submenu-title:hover,\n  .ant-menu-item {\n    color: white !important;\n  }\n  .ant-menu-item-selected,\n  .ant-menu-submenu-active,\n  .ant-menu-submenu-open,\n  .ant-menu-submenu-title,\n  .ant-menu-item-selected span,\n  .ant-menu-item-selected span a,\n  .ant-menu-item-selected span a:hover {\n    color: black !important;\n  }\n"], ["\n  color: white;\n  line-height: 38px;\n  border-bottom: 0;\n  background: none;\n  width: ", ";\n\n  .ant-menu-item:hover,\n  .ant-menu-submenu-title:hover,\n  .ant-menu-item {\n    color: white !important;\n  }\n  .ant-menu-item-selected,\n  .ant-menu-submenu-active,\n  .ant-menu-submenu-open,\n  .ant-menu-submenu-title,\n  .ant-menu-item-selected span,\n  .ant-menu-item-selected span a,\n  .ant-menu-item-selected span a:hover {\n    color: black !important;\n  }\n"])), function (props) { return props.width; });
var MenuSidebar = function () {
    var location = useLocation();
    var _a = useApp(), isAppReady = _a.isReady, user = _a.user;
    var _b = useAppI18n(), isI18nReady = _b.isI18nReady, layout = _b.layout;
    var _c = useState(false), isOpenSetting = _c[0], setIsOpenSetting = _c[1];
    var t = useTranslation('components', { keyPrefix: 'commons.menubar' }).t;
    var canDisplay = isAppReady && isI18nReady;
    var isMobile = layout.isMobileLayoutOnPC || layout.isMobileScreenSize;
    var _d = useAppState(), filterInspection = _d.filterInspection, setFilterInspection = _d.setFilterInspection;
    var items = [];
    if (user && user.ability.can(ABILITY_ACTION.ACCESS, ROUTE_ADMIN_DASHBOARD)) {
        items.push({
            label: (_jsx(Link, { to: ROUTE_ADMIN_DASHBOARD, title: t('adminDashboard'), children: t('adminDashboard') })),
            icon: _jsx(HomeOutlined, {}),
            key: ROUTE_ADMIN_DASHBOARD,
        });
    }
    if (user && user.ability.can(ABILITY_ACTION.ACCESS, ROUTE_ADMIN_INSPECTION_RESULT_LIST)) {
        items.push({
            label: (_jsx(Link, { to: ROUTE_ADMIN_INSPECTION_RESULT_LIST, title: t('adminInspection'), onClick: function () {
                    setFilterInspection(function (prev) { return (__assign(__assign(__assign({}, prev), { supplierIds: [], pagination: __assign(__assign({}, filterInspection.pagination), { page: PAGINATION_DEFAULT_PAGE }) }), useInspectionInitAdvancedSearchFilter(user))); });
                }, children: t('adminInspection') })),
            icon: _jsx(AppstoreAddOutlined, {}),
            key: ROUTE_ADMIN_INSPECTION_RESULT_LIST,
        });
    }
    if (user && user.ability.can(ABILITY_ACTION.ACCESS, ROUTE_ADMIN_SHIPPING_LIST)) {
        items.push({
            label: (_jsx(Link, { to: ROUTE_ADMIN_SHIPPING_LIST, title: t('adminShippingManagement'), children: t('adminShippingManagement') })),
            icon: _jsx(SendOutlined, {}),
            key: ROUTE_ADMIN_SHIPPING_LIST,
        });
    }
    if (user && user.ability.can(ABILITY_ACTION.ACCESS, ROUTE_ADMIN_DOCUMENT_LIST)) {
        items.push({
            label: (_jsx(Link, { to: ROUTE_ADMIN_DOCUMENT_LIST, title: t('adminDocumentManagement'), children: t('adminDocumentManagement') })),
            icon: _jsx(FilePdfOutlined, {}),
            key: ROUTE_ADMIN_DOCUMENT_LIST,
        });
    }
    if (!checkDisableFeatures(DISABLED_FEATURES.SP_MANAGEMENT)) {
        if (user && user.ability.can(ABILITY_ACTION.ACCESS, ROUTE_USER_ACCOUNT_LIST)) {
            items.push({
                label: (_jsx(Link, { to: ROUTE_USER_ACCOUNT_LIST, title: t('userAccount'), children: t('userAccount') })),
                icon: _jsx(UserOutlined, {}),
                key: ROUTE_USER_ACCOUNT_LIST,
            });
        }
    }
    if (!checkDisableFeatures(DISABLED_FEATURES.SP_MANAGEMENT)) {
        if (user && user.ability.can(ABILITY_ACTION.ACCESS, ROUTE_SUPPLIER_LIST)) {
            items.push({
                label: (_jsx(Link, { to: ROUTE_SUPPLIER_LIST, title: t('supplierStyle'), children: t('supplierStyle') })),
                icon: _jsx(TeamOutlined, {}),
                key: ROUTE_SUPPLIER_LIST,
            });
        }
    }
    if (user && user.ability.can(ABILITY_ACTION.ACCESS, ROUTE_ADMIN_INSPECTOR_COMPANY_LIST)) {
        items.push({
            label: (_jsx(Link, { to: ROUTE_ADMIN_INSPECTOR_COMPANY_LIST, title: t('adminInspectorCompanyManagement'), children: t('adminInspectorCompanyManagement') })),
            icon: _jsx(GroupOutlined, {}),
            key: ROUTE_ADMIN_INSPECTOR_COMPANY_LIST,
        });
    }
    if (user && user.ability.can(ABILITY_ACTION.ACCESS, ROUTE_ADMIN_IMPORT_CSV)) {
        items.push({
            label: (_jsx(Link, { to: ROUTE_ADMIN_IMPORT_CSV, title: t('adminImportCSV'), children: t('adminImportCSV') })),
            icon: _jsx(ImportOutlined, {}),
            key: ROUTE_ADMIN_IMPORT_CSV,
        });
    }
    if (user && user.ability.can(ABILITY_ACTION.ACCESS, ROUTE_ADMIN_EXPORT_DATA)) {
        items.push({
            label: (_jsx(Link, { to: ROUTE_ADMIN_EXPORT_DATA, title: t('adminExportedFile'), children: t('adminExportedFile') })),
            icon: _jsx(ImportOutlined, {}),
            key: ROUTE_ADMIN_EXPORT_DATA,
        });
    }
    if (user && user.ability.can(ABILITY_ACTION.ACCESS, ROUTE_ADMIN_MANAGER_TERMS_SENTENCES)) {
        items.push({
            label: (_jsx(Link, { to: ROUTE_ADMIN_MANAGER_TERMS_SENTENCES, title: t('AdminStandardTermsSentences'), children: t('AdminStandardTermsSentences') })),
            icon: _jsx(ExceptionOutlined, {}),
            key: ROUTE_ADMIN_MANAGER_TERMS_SENTENCES,
        });
    }
    if (user &&
        user.ability.can(ABILITY_ACTION.ACCESS, ROUTE_ADMIN_CREATE_MANUAL_ONSCREEN_INSPECTION)) {
        items.push({
            label: (_jsx(Link, { to: ROUTE_ADMIN_CREATE_MANUAL_ONSCREEN_INSPECTION, title: t('adminCreateManualOnscreenInspection'), children: t('adminCreateManualOnscreenInspection') })),
            icon: _jsx(ExceptionOutlined, {}),
            key: ROUTE_ADMIN_CREATE_MANUAL_ONSCREEN_INSPECTION,
        });
    }
    if (user && user.ability.can(ABILITY_ACTION.ACCESS, ROUTE_ADMIN_INQUIRY)) {
        items.push({
            label: (_jsx(Link, { to: ROUTE_ADMIN_INQUIRY, title: t('adminInquiry'), children: t('adminInquiry') })),
            icon: _jsx(ExceptionOutlined, {}),
            key: ROUTE_ADMIN_INQUIRY,
        });
    }
    if (user && user.ability.can(ABILITY_ACTION.ACCESS, ROUTE_LOGS_LIST)) {
        items.push({
            label: (_jsx(Link, { to: ROUTE_LOGS_LIST, title: t('adminLogs'), children: t('adminLogs') })),
            icon: _jsx(HistoryOutlined, {}),
            key: ROUTE_LOGS_LIST,
        });
    }
    if (!canDisplay)
        return null;
    return (_jsxs(_Fragment, { children: [_jsx(Wrapper, { style: {
                    borderTop: '2px solid white',
                    paddingTop: '10px',
                    maxWidth: isMobile ? "".concat(MOBILE_NORMAL_WIDTH, "px") : '100%',
                }, children: _jsx(Row, { wrap: false, align: "middle", children: _jsx(Menu, { mode: "vertical", selectedKeys: [location.pathname], items: items, width: isMobile ? '10%' : '100%', overflowedIndicator: _jsx(MenuOutlined, {}) }) }) }), _jsx(SettingModal, { isOpenSetting: isOpenSetting, setIsOpenSetting: setIsOpenSetting })] }));
};
export { MenuSidebar };
var templateObject_1, templateObject_2;
