import { useState } from 'react';
import { useAppI18n } from '../../contexts/AppI18nContext';
var languageOptions = [
    {
        value: 'ja',
        label: '日本語/Japanese/Tiếng Nhật',
    },
    {
        value: 'en',
        label: '英語/English/Tiếng Anh',
    },
    {
        value: 'es',
        label: 'スペイン語/Spanish/Tiếng Tây Ban Nha',
    },
    {
        value: 'vi',
        label: 'ベトナム語/Vietnamese/Tiếng Việt',
    },
];
export var useSelectLanguage = function () {
    var currentLanguage = useAppI18n().language;
    var _a = useState(currentLanguage), language = _a[0], setLanguage = _a[1];
    var languageChangeHandler = function (value) {
        setLanguage(value);
    };
    return {
        currentLanguage: currentLanguage,
        language: language,
        languageChangeHandler: languageChangeHandler,
        languageOptions: languageOptions,
    };
};
