var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import loadingIcon from '../icons/loading.svg';
import spinIcon from '../icons/loading-spin-orange.svg';
var WholePageLoadingWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100% !important;\n  height: auto;\n  background-color: rgba(0, 0, 0, 0.3);\n  img {\n    position: absolute;\n    top: calc(50% - 50px);\n    left: calc(50% - 50px);\n    width: 100px;\n    animation: loadingCircle 1s linear infinite;\n  }\n  @keyframes loadingCircle {\n    100% {\n      -webkit-transform: rotate(360deg);\n      transform: rotate(360deg);\n    }\n  }\n  z-index: 9999;\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100% !important;\n  height: auto;\n  background-color: rgba(0, 0, 0, 0.3);\n  img {\n    position: absolute;\n    top: calc(50% - 50px);\n    left: calc(50% - 50px);\n    width: 100px;\n    animation: loadingCircle 1s linear infinite;\n  }\n  @keyframes loadingCircle {\n    100% {\n      -webkit-transform: rotate(360deg);\n      transform: rotate(360deg);\n    }\n  }\n  z-index: 9999;\n"])));
var PageLoadingWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  img {\n    width: 75px;\n    animation: loadingCircle 1s linear infinite;\n  }\n  @keyframes loadingCircle {\n    100% {\n      -webkit-transform: rotate(360deg);\n      transform: rotate(360deg);\n    }\n  }\n"], ["\n  img {\n    width: 75px;\n    animation: loadingCircle 1s linear infinite;\n  }\n  @keyframes loadingCircle {\n    100% {\n      -webkit-transform: rotate(360deg);\n      transform: rotate(360deg);\n    }\n  }\n"])));
var ComponentLoadingWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  top: 10%;\n  left: 50%;\n  z-index: 999;\n\n  img {\n    animation: loadingCircle 1s linear infinite;\n  }\n\n  @keyframes loadingCircle {\n    100% {\n      -webkit-transform: rotate(360deg);\n      transform: rotate(360deg);\n    }\n  }\n"], ["\n  position: absolute;\n  top: 10%;\n  left: 50%;\n  z-index: 999;\n\n  img {\n    animation: loadingCircle 1s linear infinite;\n  }\n\n  @keyframes loadingCircle {\n    100% {\n      -webkit-transform: rotate(360deg);\n      transform: rotate(360deg);\n    }\n  }\n"])));
var SmallLoadingWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: absolute;\n  top: 10%;\n  left: 50%;\n  z-index: 999;\n\n  img {\n    animation: loadingCircle 1s linear infinite;\n  }\n\n  @keyframes loadingCircle {\n    100% {\n      -webkit-transform: rotate(360deg);\n      transform: rotate(360deg);\n    }\n  }\n"], ["\n  position: absolute;\n  top: 10%;\n  left: 50%;\n  z-index: 999;\n\n  img {\n    animation: loadingCircle 1s linear infinite;\n  }\n\n  @keyframes loadingCircle {\n    100% {\n      -webkit-transform: rotate(360deg);\n      transform: rotate(360deg);\n    }\n  }\n"])));
var WholePageLoading = function () {
    return (_jsx(WholePageLoadingWrapper, { children: _jsx("img", { src: loadingIcon, alt: "Loading..", width: "24px" }) }));
};
var PageLoading = function () {
    return (_jsx(PageLoadingWrapper, { children: _jsx("img", { src: loadingIcon, alt: "Loading..", width: "24px" }) }));
};
var ComponentLoading = function () {
    return (_jsx(ComponentLoadingWrapper, { children: _jsx("img", { src: spinIcon, alt: "Loading..", width: "75px" }) }));
};
var SmallLoading = function () {
    return (_jsx(SmallLoadingWrapper, { className: "loadingIcon", children: _jsx("img", { src: spinIcon, alt: "Loading..", width: "24px" }) }));
};
export { WholePageLoading, PageLoading, ComponentLoading, SmallLoading };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
