var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from 'styled-components';
import { Header } from '../commons';
import { useTranslation } from 'react-i18next';
import '../../assets/Pagination.css';
import { CustomPageHeader } from '../commons/CustomPageHeader';
import { useAppI18n } from '../../contexts/AppI18nContext';
import { Radio, Space } from 'antd';
import { GridView } from './GridView';
import { PaginationCustom } from '../commons/PaginationCustom';
import { useCallback, useEffect, useState } from 'react';
import { ORDER, ORDER_BY, PAGINATION_DEFAULT_PAGE, PAGINATION_DEFAULT_SIZE, } from '../../shared/constants';
import { ExportedFileStatus } from '../../shared/exportData';
import { useListExportedFileHooks } from '../../hooks/exportData';
import { useApp } from '../../contexts/AppContext';
import { SearchBox } from './SearchBox';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: ", ";\n  padding-top: 20px;\n  .ant-table-pagination {\n    display: flex;\n  }\n"], ["\n  height: ", ";\n  padding-top: 20px;\n  .ant-table-pagination {\n    display: flex;\n  }\n"])), function (props) { return (props.$isMobile ? 'unset' : 'calc(100vh - 150px)'); });
var defaultListParams = {
    page: PAGINATION_DEFAULT_PAGE,
    size: PAGINATION_DEFAULT_SIZE,
    orderBy: ORDER_BY.CREATED_AT,
    order: ORDER.DESC,
};
var defaultPagination = {
    page: PAGINATION_DEFAULT_PAGE,
    size: PAGINATION_DEFAULT_SIZE,
    total: 0,
};
var REFETCH_INTERVAL_IN_MILLISEC = 5000;
var FilterExportedByMode;
(function (FilterExportedByMode) {
    FilterExportedByMode["ALL"] = "all";
    FilterExportedByMode["ME"] = "me";
})(FilterExportedByMode || (FilterExportedByMode = {}));
var ExportedFileList = function () {
    var t = useTranslation('components', { keyPrefix: 'exportData' }).t;
    var layout = useAppI18n().layout;
    var _a = useApp(), isReady = _a.isReady, user = _a.user;
    var isMobile = layout.isMobileLayoutOnPC || layout.isMobileScreenSize;
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    var _c = useState(defaultListParams), fetchListParams = _c[0], setFetchListParams = _c[1];
    var _d = useState([]), exportedFileList = _d[0], setExportedFileList = _d[1];
    var _e = useState(defaultPagination), pagination = _e[0], setPagination = _e[1];
    var listExportedFileHookHandler = useListExportedFileHooks().listExportedFileHookHandler;
    useEffect(function () {
        if (!isReady)
            return;
        setFetchListParams(function (value) { return (__assign(__assign({}, value), { exportBy: user === null || user === void 0 ? void 0 : user.email })); });
        setLoading(false);
    }, [isReady]);
    useEffect(function () {
        getExportedFileList(fetchListParams);
    }, [fetchListParams, listExportedFileHookHandler]);
    var handleTableChange = useCallback(function (tablePagination) {
        var pagination = __assign(__assign({}, defaultListParams), { page: tablePagination.current, size: tablePagination.pageSize });
        setFetchListParams(function (params) {
            if (params.size !== pagination.size) {
                // Reset to first page when changed size
                pagination.page = PAGINATION_DEFAULT_PAGE;
            }
            return __assign(__assign({}, params), pagination);
        });
        return function () {
            setFetchListParams(defaultListParams);
            setExportedFileList([]);
            setPagination(defaultPagination);
        };
    }, []);
    var handleChangeExportedByMode = useCallback(function (mode) {
        var exportBy = mode === FilterExportedByMode.ALL ? undefined : user === null || user === void 0 ? void 0 : user.email;
        setFetchListParams(function (params) { return (__assign(__assign(__assign({}, params), defaultListParams), { exportBy: exportBy })); });
    }, [user === null || user === void 0 ? void 0 : user.email]);
    var getExportedFileList = useCallback(function (params) {
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            var body;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!listExportedFileHookHandler)
                            return [2 /*return*/];
                        return [4 /*yield*/, listExportedFileHookHandler({
                                query: __assign(__assign({}, fetchListParams), params),
                                onProgressing: function () { return setLoading(true); },
                                onSuccessed: function () { return setLoading(false); },
                            })];
                    case 1:
                        body = _a.sent();
                        if (!body)
                            return [2 /*return*/];
                        setExportedFileList(body.data);
                        setPagination(body.pagination);
                        return [2 /*return*/];
                }
            });
        }); })();
    }, [listExportedFileHookHandler]);
    useEffect(function () {
        var index = exportedFileList.findIndex(function (e) { return e.status === ExportedFileStatus.Exporting; });
        if (index === -1) {
            return;
        }
        else {
            var timer_1 = setTimeout(getExportedFileList, REFETCH_INTERVAL_IN_MILLISEC);
            return function () { return clearTimeout(timer_1); };
        }
    }, [exportedFileList]);
    return (_jsxs(_Fragment, { children: [_jsx(Header, { children: _jsx(CustomPageHeader, { text: t('header'), adminText: t('header'), isAdminSide: true }) }), _jsx(Container, { "$isMobile": isMobile, style: { paddingLeft: 10 }, children: _jsxs(Space, { direction: 'vertical', style: { width: '100%' }, children: [_jsxs(Space, { size: "small", direction: !isMobile ? 'horizontal' : 'vertical', children: [_jsx(SearchBox, { onSearch: function (keyword) {
                                        return setFetchListParams(function (params) { return (__assign(__assign({}, params), { keyword: keyword, page: PAGINATION_DEFAULT_PAGE })); });
                                    } }), _jsxs(Radio.Group, { onChange: function (e) { return handleChangeExportedByMode(e.target.value); }, defaultValue: FilterExportedByMode.ME, children: [_jsx(Radio.Button, { value: FilterExportedByMode.ALL, children: t("exportByOption.".concat(FilterExportedByMode.ALL)) }), _jsx(Radio.Button, { value: FilterExportedByMode.ME, children: t("exportByOption.".concat(FilterExportedByMode.ME)) })] })] }), _jsxs("div", { "data-testid": "table_wrapper", children: [_jsx(GridView, { dataList: exportedFileList, pagination: {
                                        position: ['bottomCenter'],
                                        current: pagination.page,
                                        pageSize: pagination.size,
                                        total: pagination.total,
                                        showSizeChanger: false,
                                    }, onChange: handleTableChange, loading: loading, onSuccessed: function () {
                                        getExportedFileList();
                                    } }), !isMobile && (_jsx(PaginationCustom, { pagination: pagination, sizeChange: function (size) { return setFetchListParams(function (cond) { return (__assign(__assign({}, cond), { size: size })); }); }, nextPage: function (page) { return setFetchListParams(function (cond) { return (__assign(__assign({}, cond), { page: page })); }); }, previousPage: function (page) { return setFetchListParams(function (cond) { return (__assign(__assign({}, cond), { page: page })); }); } }))] })] }) })] }));
};
export default ExportedFileList;
var templateObject_1;
