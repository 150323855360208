var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import _get from 'lodash/get';
import { useCallback } from 'react';
import { compressNotEncoded } from './compress';
import { useAccessToken } from '../hooks/commons/useAccessToken';
import { sendError, showCommonErrorNotification } from './logs';
import { generateTraceParent } from './helpers';
import { ROUTE_NOT_AUTHORIZED, ROUTE_SERVICE_UNAVAILABLE, ROUTE_UNDER_MAINTENANCE } from './urls';
import { HTTP_STATUS } from './constants';
export var createURL = function (_a) {
    var _b;
    var path = _a.path, query = _a.query, params = _a.params, skipCompressArray = _a.skipCompressArray;
    var lastPathCharacter = path.slice(-1);
    var paramString = params ? "".concat(lastPathCharacter === '/' ? '' : '/').concat(params === null || params === void 0 ? void 0 : params.join('/')) : '';
    var url = "".concat(((_b = process.env.BASE_URL) !== null && _b !== void 0 ? _b : '').replace(/\/$/, ''), "/").concat(path);
    if (!query)
        return "".concat(url).concat(paramString);
    var searchParams = new URLSearchParams();
    Object.entries(query).forEach(function (_a) {
        var k = _a[0], v = _a[1];
        if (Array.isArray(v) && skipCompressArray) {
            v.forEach(function (val) { return searchParams.append("".concat(k, "[]"), val); });
        }
        else {
            searchParams.set(k, Array.isArray(v) ? compressNotEncoded(v.join(',')) : "".concat(v));
        }
    });
    return "".concat(url).concat(paramString, "?").concat(searchParams.toString());
};
export var createFetchOptions = function (options) {
    var traceparent = options.traceparent;
    if (!traceparent) {
        traceparent = generateTraceParent();
    }
    return {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            Accept: 'application/json, text/plain, */*',
            Authorization: "Bearer ".concat(options.accessToken),
            Traceparent: traceparent !== null && traceparent !== void 0 ? traceparent : '',
        },
        method: options.method,
        body: options.body && (options.body instanceof File ? options.body : JSON.stringify(options.body)),
    };
};
var createResData = function (content) { return (content.length > 0 ? JSON.parse(content) : null); };
var callErrActionsIfNeeds = function (options, err) {
    var statusCode = _get(err, 'statusCode');
    if (statusCode === HTTP_STATUS.UNDER_MAINTENANCE) {
        window.location.replace(ROUTE_UNDER_MAINTENANCE);
    }
    else if (statusCode === HTTP_STATUS.SERVICE_UNAVAILABLE) {
        window.location.replace(ROUTE_SERVICE_UNAVAILABLE);
    }
    else {
        if (!options.stopShowingErrNotification)
            showCommonErrorNotification(err);
        if (!options.stopSendingErr)
            sendError(err);
        if (statusCode === HTTP_STATUS.PERMISSION_DENIED && !options.stopUnauthorizedRedirectPage) {
            window.location.replace(ROUTE_NOT_AUTHORIZED);
        }
    }
};
function callAPI(path, options) {
    return __awaiter(this, void 0, void 0, function () {
        var res, content, data, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, fetch(createURL({
                            path: path,
                            query: options.query,
                            params: options.params,
                            skipCompressArray: options.skipCompressArray,
                        }), createFetchOptions(options))];
                case 1:
                    res = _a.sent();
                    return [4 /*yield*/, res.text()];
                case 2:
                    content = _a.sent();
                    data = createResData(content);
                    if (res.ok) {
                        return [2 /*return*/, {
                                isSuccess: true,
                                body: data,
                            }];
                    }
                    else {
                        callErrActionsIfNeeds(options, data);
                        return [2 /*return*/, {
                                isSuccess: false,
                                isNetworkErr: false,
                                status: res.status,
                                err: data,
                            }];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    callErrActionsIfNeeds(options, err_1);
                    return [2 /*return*/, {
                            isSuccess: false,
                            isNetworkErr: true,
                            err: err_1,
                        }];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function createUseClient(path, method) {
    if (method === void 0) { method = 'GET'; }
    return function () {
        var accessToken = useAccessToken();
        var client = useCallback(function (params) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            return callAPI(path, __assign({ method: method, accessToken: accessToken }, params));
        }, [accessToken]);
        return accessToken ? client : null;
    };
}
